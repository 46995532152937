<div class="mb-2">
  <span nz-typography>
    {{ 'page.projects.status' | translate }}
  </span>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'page.projects.status' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
  >
    <nz-option
      *ngFor="let status of options$ | async"
      [nzLabel]="'project.status.' + status.name | translate"
      [nzValue]="status.id"
    ></nz-option>
  </nz-select>
</div>
