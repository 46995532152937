import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageFrameComponent } from './page-frame/page-frame.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PageFrameComponent],
  exports: [PageFrameComponent],
})
export class PageFrameModule {}
