import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BillingRuleTimeFrameMinimumRate } from '../../interfaces/time-frame-minimum-rate';
import {
  PricePerUnitComponent,
  UpdatePricePerUnit,
} from '../partials/price-per-unit/price-per-unit.component';
import { FillUpToComponent } from '../partials/fill-up-to/fill-up-to.component';
import { RuleLabelComponent } from '../partials/rule-label/rule-label.component';
import { RuleDescriptionComponent } from '../partials/rule-description/rule-description.component';
import { RoundUpAfterComponent } from '../partials/round-up-after/round-up-after.component';
import { MinimumAmountComponent } from '../partials/minimum-amount/minimum-amount.component';
import { ActivityCategoriesComponent } from '../partials/activity-categories/activity-categories.component';
import { ExcludeHolidaysComponent } from '../partials/exclude-holidays/exclude-holidays.component';
import { FrequencyComponent } from '../partials/frequency/frequency.component';

@Component({
  selector: 'wilson-time-frame-minimum-rate',
  standalone: true,
  imports: [
    CommonModule,
    PricePerUnitComponent,
    FillUpToComponent,
    RuleLabelComponent,
    RuleDescriptionComponent,
    ActivityCategoriesComponent,
    RoundUpAfterComponent,
    MinimumAmountComponent,
    ExcludeHolidaysComponent,
    FrequencyComponent,
  ],
  templateUrl: './time-frame-minimum-rate.component.html',
  styleUrl: './time-frame-minimum-rate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFrameMinimumRateComponent {
  @Input() rule: BillingRuleTimeFrameMinimumRate;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleTimeFrameMinimumRate;
    index: number;
  }>();

  setPricePerUnit(update: UpdatePricePerUnit) {
    if (update.index) {
      this.rule.params.activityCategoryOptions[update.index].pricePerUnit =
        update.pricePerUnit;
    } else {
      this.rule.params.pricePerUnit = update.pricePerUnit;
    }
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }
}
