<ng-container *ngIf="rule">
  <wilson-price-per-unit
    (updatePricePerUnit)="setPricePerUnit($event)"
    [pricePerUnit]="rule.params.pricePerUnit"
    customDescription="agreement.rules.ShiftMinimumRate.price_per_unit.description"
    customLabel="agreement.rules.ShiftMinimumRate.price_per_unit.label"
  ></wilson-price-per-unit>
  <wilson-fill-up-to
    [fillUpTo]="rule.params.fillUpTo"
    customDescription="agreement.rules.ShiftMinimumRate.fill_up_to.description"
    customLabel="agreement.rules.ShiftMinimumRate.fill_up_to.label"
  ></wilson-fill-up-to>
  <ng-container *ngIf="rule.params.activityCategoryOptions?.length">
    <div class="category_options">
      <wilson-rule-label
        customLabel="agreement.rules.ShiftMinimumRate.activity_category_options.label"
      ></wilson-rule-label>
      <wilson-rule-description
        customDescription="agreement.rules.ShiftMinimumRate.activity_category_options.description"
      ></wilson-rule-description>
      <div class="category_options__entries">
        <ng-container
          *ngFor="let option of rule.params.activityCategoryOptions"
        >
          <div class="category_options__entries__option">
            <wilson-activity-categories
              [activityCategories]="option.activityCategories"
              customDescription="agreement.rules.ShiftMinimumRate.activity_category_options.activity_categories.description"
              customLabel="agreement.rules.ShiftMinimumRate.activity_category_options.activity_categories.label"
            ></wilson-activity-categories>
            <wilson-round-up-after
              [roundUpAfter]="option.roundUpAfter"
              customDescription="agreement.rules.ShiftMinimumRate.activity_category_options.round_up_after.description"
              customLabel="agreement.rules.ShiftMinimumRate.activity_category_options.round_up_after.label"
            ></wilson-round-up-after>
            <wilson-minimum-amount
              [minimumAmount]="option.minimumAmount"
              customDescription="agreement.rules.ShiftMinimumRate.activity_category_options.minimum_amount.description"
              customLabel="agreement.rules.ShiftMinimumRate.activity_category_options.minimum_amount.label"
            ></wilson-minimum-amount>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
