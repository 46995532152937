<ng-container *ngIf="rule">
  <div class="rule">
    <div class="rule__header">
      {{ 'agreement.rules.' + rule.calculatorName + '.header' | translate }}
    </div>
    <div class="rule__description">
      {{
        'agreement.rules.' + rule.calculatorName + '.description' | translate
      }}
    </div>
    <div class="rule__details">
      <div>
        <wilson-rule-label
          customLabel="agreement.rules.details.invoice_rule_name.label"
        ></wilson-rule-label>
        <wilson-rule-description
          customDescription="agreement.rules.details.invoice_rule_name.description"
        ></wilson-rule-description>
        <input
          nz-input
          class="rule_details__invoice-rule-name-input"
          [value]="rule.ruleName"
          (change)="setRuleName($event)"
          type="text"
        />
      </div>
      <div class="rule__details__directive_wrapper">
        <ng-template wilsonAgreementRuleDirective></ng-template>
      </div>
    </div>
  </div>
  <hr />
</ng-container>
