import { Pipe, PipeTransform } from '@angular/core';
import { UserQualificationV2 } from '@wilson/interfaces';

@Pipe({
  name: 'userQualificationV2IsConfirmed',
  standalone: true,
})
export class UserQualificationV2IsConfirmedPipe implements PipeTransform {
  transform(
    userQualification: Pick<UserQualificationV2, 'confirmedByUser'>,
  ): boolean {
    return userQualification.confirmedByUser;
  }
}
