import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { ProviderShareUserListService } from '../provider-share-user-list-service/provider-share-user-list-service';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-set-share-renderer',
  template: `
    @let hasSharedUserUpdatePermission = !!(RoleAction.Update | ablePure :
    RolePermissionSubject.SharedUser | async);
    <nz-switch
      #switch="nzSwitch"
      nz-switch
      (click)="setShareStatus()"
      [nzDisabled]="!hasSharedUserUpdatePermission"
      nz-tooltip
      [nzTooltipTitle]="
        !hasSharedUserUpdatePermission
          ? ('general.forbidden_action' | translate)
          : ''
      "
    ></nz-switch>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetShareRendererComponent
  implements ICellRendererAngularComp, AfterViewInit
{
  @ViewChild('switch') switch: NzSwitchComponent;
  loading = false;
  userShared: boolean;
  params: ICellRendererParams;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly providerShareUserListService: ProviderShareUserListService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  setShareStatus(): void {
    this.userShared = !this.userShared;
    if (this.userShared) {
      this.providerShareUserListService.createSharedUser(
        this.params.data,
        this.params,
      );
    } else {
      this.providerShareUserListService.deleteSharedUser(
        this.params.data,
        this.params,
      );
    }
  }

  ngAfterViewInit(): void {
    this.switch.writeValue(this.userShared);
    this.cdr.detectChanges();
  }

  agInit(params: ICellRendererParams): void {
    this.userShared = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.userShared = this.getValueToDisplay(params);
    this.switch.writeValue(this.userShared);
    this.cdr.detectChanges();
    return true;
  }

  getValueToDisplay(params: ICellRendererParams): boolean {
    this.params = params;
    return !!params.data?.sharedUsers?.length;
  }
}
