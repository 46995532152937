<ng-container
  *ngIf="
    client.clientPartnership?.partnershipId &&
    (FeatureName.WilsonShare | featurePure | async) &&
    ('portal-user-work-approval-and-sharing-of-qualifications-wilp-4769'
      | featureFlagPure
      | async) &&
    (users$ | async) as users
  "
>
  <div class="row mt-4" *ngIf="users.length">
    <div class="col">
      <label class="form-label fw-normal d-flex align-items-center">
        {{
          'page.settings.client.table.title'
            | translate : { client: client.name }
        }}
      </label>
    </div>
  </div>
  <div class="row mt-1" *ngIf="users.length">
    <ag-grid-angular
      class="ag-theme-wilson"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [rowData]="users"
      [components]="components"
      [columnDefs]="columnDefs"
      (gridReady)="gridService.onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</ng-container>
