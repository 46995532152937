@let hasClientCreatePermission = !!(RoleAction.Create | ablePure :
RolePermissionSubject.Billing | async);

<div class="row">
  <div class="col">
    <label class="form-label fw-normal d-flex align-items-center">
      {{ 'agreements.page.title' | translate }}
      <i
        nz-tooltip
        [nzTooltipTitle]="tooltipGen"
        nzTooltipOverlayClassName="dark"
        nzTooltipPlacement="right"
        [nzTooltipOverlayStyle]="{ 'min-width': '400px' }"
        class="fas fa-info-circle ms-1 text-secondary"
      ></i>

      <ng-template #tooltipGen>
        <wilson-tooltip
          [header]="'agreements.tooltip.title' | translate"
          [details]="'agreements.tooltip.description' | translate"
        >
        </wilson-tooltip>
      </ng-template>
    </label>
  </div>
  <div class="col-1 d-flex justify-content-end">
    <button
      nz-button
      nzType="default"
      class="h-100"
      (click)="addAgreement()"
      [disabled]="!hasClientCreatePermission"
      nz-tooltip
      [nzTooltipTitle]="
        !hasClientCreatePermission
          ? ('general.forbidden_action' | translate)
          : ''
      "
    >
      {{ 'agreements.modal.add.title' | translate }}
    </button>
  </div>
</div>
<div class="row mt-1">
  <ag-grid-angular
    class="ag-theme-wilson"
    [gridOptions]="gridOptions"
    [defaultColDef]="defaultColDef"
    [rowData]="agreements$ | async"
    [components]="components"
    [columnDefs]="columnDefs"
    (gridReady)="gridService.onGridReady($event)"
  ></ag-grid-angular>
</div>
<ng-template #customEditAgreementFooter>
  <div class="d-flex justify-content-between footer">
    <span
      class="cursor-pointer"
      nz-button
      (click)="closeModal()"
      nzType="default"
    >
      {{ 'general.cancel' | translate }}
    </span>
    <span>
      <button nz-button (click)="applyToOtherAgreements()" nzType="default">
        {{ 'agreements.modal.apply_to_other_agreements' | translate }}
      </button>
      <button nz-button (click)="saveAgreement()" nzType="primary">
        {{ 'general.save' | translate }}
      </button>
    </span>
  </div>
</ng-template>
