import { isSameDay, set } from 'date-fns';

export function getInfiniteValidQualificationDate(): Date {
  const date = new Date('2099-12-31');
  const infiniteValidDate = set(date, {
    hours: 12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return infiniteValidDate;
}

export function isInfiniteValidQualificationDate(date: Date): boolean {
  return isSameDay(date, getInfiniteValidQualificationDate());
}

export function isInfiniteValidQualificationDateString(
  dateString: string,
): boolean {
  return isSameDay(new Date(dateString), getInfiniteValidQualificationDate());
}
