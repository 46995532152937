<div>
  <span nz-typography *ngIf="showLabel">
    {{ 'general.users' | translate }}
  </span>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="placeholder || 'general.users' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="users-list-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name"
      [nzValue]="item.id"
      [attr.data-test-id]="'users-list-filter:select:option:' + item.id"
    ></nz-option>
  </nz-select>
</div>
