<wilson-filter-label
  [label]="showLabel ? ('page.service.activity_type' | translate) : ''"
>
  <div>
    <nz-select
      nzMode="multiple"
      [nzPlaceHolder]="'page.service.activity_type' | translate"
      [nzMaxTagCount]="1"
      nzAllowClear
      [nzShowArrow]="true"
      (ngModelChange)="saveSelected($event)"
      [ngModel]="selected$ | async"
    >
      <nz-option
        *ngFor="let item of options$ | async"
        [nzLabel]="item.nameTranslationKey | translate"
        [nzValue]="item.id"
        nzCustomContent
      >
        <span
          nz-tooltip
          [nzTooltipTitle]="item.tooltip"
          [nzTooltipColor]="'#fff'"
        >
          {{ item.nameTranslationKey | translate }}
        </span>
      </nz-option>
    </nz-select>
  </div>
</wilson-filter-label>
