<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="placeholder || ('account.roles' | translate)"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="user-role-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="'roles.name.' + item.name | translate"
      [nzValue]="item.id"
      [attr.data-test-id]="'user-role-filter:select:option:' + item.id"
    ></nz-option>
  </nz-select>
</div>
