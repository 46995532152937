/* eslint-disable @nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExternalUser } from '../../../../../services/src/lib/shared-users-with-you.service';

@Component({
  selector: 'wilson-user-note-renderer',
  template: `<wilson-user-note [user]="cellValue"></wilson-user-note>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNoteRendererRendererComponent
  implements ICellRendererAngularComp
{
  public cellValue!: ExternalUser;

  async agInit(params: ICellRendererParams) {
    this.cellValue = params.data;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.data;
    return true;
  }
}
