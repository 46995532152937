import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IRowNode } from 'ag-grid-community';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

interface CurrentPageNodesStatus {
  nodesOnCurrentPage: IRowNode<any>[];
  nodesOnCurrentPageCount: number;
  selectedNodesCount: number;
  unselectedNodesCount: number;
}

/**
 * Infinite row model does not officially support select all on infinite row models (header checkbox selection):
 * https://www.ag-grid.com/angular-data-grid/row-models/#row-model-comparisons
 *
 * Mimics header checkbox "SelectAllMode" -> currentPage (https://www.ag-grid.com/javascript-data-grid/grid-options/#reference-selection-rowSelection)
 * ag-grid example: https://www.ag-grid.com/javascript-data-grid/row-selection-multi-row/#example-header-checkbox
 *
 * @export
 * @class CustomInfiniteRowModelSelectAllHeaderComponent
 * @typedef {CustomInfiniteRowModelSelectAllHeaderComponent}
 * @implements {IHeaderAngularComp}
 */
@Component({
  selector: 'wilson-select-all-header',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, FormsModule, NzCheckboxModule],
  template: `
    <div
      role="presentation"
      class="ag-labeled ag-label-align-right ag-checkbox ag-input-field"
    >
      <div
        class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
        role="presentation"
      ></div>
      <div
        class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
        [class.ag-checked]="isCheckedStyle"
        [class.ag-indeterminate]="isIndeterminateStyle"
        role="presentation"
      >
        <input
          class="ag-input-field-input ag-checkbox-input"
          type="checkbox"
          aria-label="Press Space to toggle row selection (unchecked)"
          tabindex="-1"
          (change)="updateAllChecked()"
        />
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomInfiniteRowModelSelectAllHeaderComponent
  implements IHeaderAngularComp
{
  isCheckedStyle = false;
  isIndeterminateStyle = false;
  isChecked = false;

  params!: IHeaderParams;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  agInit(params: IHeaderParams): void {
    this.setup(params);
  }

  refresh(params: IHeaderParams) {
    this.setup(params);
    return true;
  }

  private setup(params: IHeaderParams) {
    this.params = params;

    if (this.params.api) {
      this.params.api.addEventListener('paginationChanged', (event) => {
        if (event.newPage || event.newPageSize) {
          const status: CurrentPageNodesStatus =
            this.getNodesStatusOfCurrentPage();
          this.updateCheckBoxInputOnPageChange(status);
          this.setCheckboxStyle(status);
        }
        if (
          event.newData ||
          (!event.newPage &&
            !event.newPageSize &&
            !event.newData &&
            this.params.api.getSelectedNodes().length === 0)
        ) {
          this.unselectAndResetOnDataChange();
        }
      });

      this.params.api.addEventListener('selectionChanged', (event) => {
        const status: CurrentPageNodesStatus =
          this.getNodesStatusOfCurrentPage();
        this.updateCheckBoxInputOnPageChange(status);
        this.setCheckboxStyle(status);
      });
    }
  }

  /**
   * Mimics header checkbox "SelectAllMode" -> currentPage (https://www.ag-grid.com/javascript-data-grid/grid-options/#reference-selection-rowSelection)
   *
   *
   * @public
   */
  public updateAllChecked() {
    if (this.params.api) {
      this.isChecked = !this.isChecked;
      if (this.isChecked) {
        this.getNodesOfCurrentPage().forEach((node) => {
          node.setSelected(true);
        });
        const status: CurrentPageNodesStatus =
          this.getNodesStatusOfCurrentPage();
        this.setCheckboxStyle(status);
      } else {
        this.getNodesOfCurrentPage().forEach((node) => {
          node.setSelected(false);
        });
        this.resetCheckBoxStyle();
      }
    }
  }

  private setCheckboxStyle(status: CurrentPageNodesStatus): void {
    this.isIndeterminateStyle = false;
    this.isCheckedStyle =
      status.selectedNodesCount === status.nodesOnCurrentPageCount;
    this.isIndeterminateStyle =
      status.selectedNodesCount > 0 && !this.isCheckedStyle;
    this.cdr.detectChanges();
  }

  private resetCheckBoxStyle() {
    this.isCheckedStyle = false;
    this.isIndeterminateStyle = false;
  }

  /**
   * Should only be called when pagination has changed (pageSize or switched page)
   * updates the input value to ensure that next click on checkbox results in the desired selection logic being applied
   *
   * @private
   */
  private updateCheckBoxInputOnPageChange(status: CurrentPageNodesStatus) {
    if (status.selectedNodesCount < status.nodesOnCurrentPageCount) {
      if (this.isChecked) {
        this.isChecked = false;
      }
    } else {
      if (!this.isChecked) {
        this.isChecked = true;
      }
    }
  }

  /**
   * Should only be called if data has changed (e.g. dataSource has changed or dataSource return value has changed)
   *
   * @private
   */
  private unselectAndResetOnDataChange() {
    this.isChecked = false;
    this.resetCheckBoxStyle();
    this.cdr.detectChanges();
  }

  private getNodesOfCurrentPage(): IRowNode<any>[] {
    let pageData: IRowNode<any>[] = [];
    const pageSize = this.params.api.paginationGetPageSize();
    const currentPage = this.params.api.paginationGetCurrentPage();
    const filteredData: IRowNode[] = [];
    this.params.api?.forEachNode((n) => {
      filteredData.push(n);
    });
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    pageData = filteredData.slice(startIndex, endIndex);
    return pageData;
  }

  private getNodesStatusOfCurrentPage(): CurrentPageNodesStatus {
    const nodesOnCurrentPage = this.getNodesOfCurrentPage();
    const nodesOnCurrentPageCount = nodesOnCurrentPage.length;
    let selectedNodesCount = 0;
    let unselectedNodesCount = 0;
    nodesOnCurrentPage.forEach((node) => {
      if (node.isSelected()) {
        ++selectedNodesCount;
      } else {
        ++unselectedNodesCount;
      }
    });
    return {
      nodesOnCurrentPage,
      nodesOnCurrentPageCount,
      selectedNodesCount,
      unselectedNodesCount,
    };
  }
}
