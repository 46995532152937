@let hasPayrollReadPermission = hasPayrollReadPermission$ | async; @let
forbiddenMessage = ('general.forbidden_action' | translate); @if
(hasPayrollReadPermission && ('portal-manual-payroll-transactions' |
featureFlagPure | async)) { @let hasPayrollUpdatePermission = RoleAction.Update
| ablePure : RolePermissionSubject.Payroll | async;
<nz-collapse
  [nzBordered]="false"
  nzGhost
  *ngIf="{
    payrollTransactions: payrollTransactions
  } as data"
>
  <nz-collapse-panel
    [nzHeader]="'work_time_management.drawer.payroll_transactions' | translate"
    [nzActive]="isExpanded"
    class="collapse-panel"
  >
    <div class="pb-3">
      <nz-alert
        nzType="info"
        class="azm-warning-alert mb-3"
        [nzMessage]="'general.warning' | translate"
        [nzDescription]="
          'work_time_management.drawer.payroll_transactions.warning' | translate
        "
        nzShowIcon
      ></nz-alert>

      @if (isLoadingTransactions) {
      <nz-skeleton [nzActive]="true"></nz-skeleton>
      } @else {
      <wilson-payroll-transaction-list
        [payrollTransactions]="data.payrollTransactions || []"
        [payrollCategories]="filteredPayrollCategories"
        [isAddingNewTransaction]="isAddingNewTransaction"
        [isUpdatingTransaction]="isUpdatingTransaction"
        [resetEditTransaction]="resetEditTransaction"
        [deletingId]="deletingId"
        (setCreateMode)="toggleCreateMode($event)"
        (create)="createPayrollTransaction($event)"
        (update)="updatePayrollTransaction($event)"
        (delete)="deletePayrollTransaction($event)"
      ></wilson-payroll-transaction-list>
      }

      <div class="payroll-hint">
        {{
          'work_time_management.drawer.payroll_transactions.note' | translate
        }}
      </div>

      <button
        class="w-100 fw-normal"
        nz-button
        nzType="default"
        [disabled]="!hasPayrollUpdatePermission || isAddingNewTransaction"
        nz-tooltip
        [nzTooltipTitle]="!hasPayrollUpdatePermission ? forbiddenMessage : ''"
        (click)="toggleCreateMode(true)"
        data-test-id="wilson-payroll-transactions:add-transaction-button"
      >
        {{ 'work_time_management.drawer.payroll_transactions.add' | translate }}
      </button>
    </div>
  </nz-collapse-panel>
</nz-collapse>
}
