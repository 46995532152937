import { BillingRule } from './billing-rules';

export interface BillingRuleActivityBonusByHourShiftAndActivityCategory
  extends BillingRule {
  calculatorName: 'ActivityBonusByHourShiftAndActivityCategory';
  params: {
    shiftCategories: string[];
    activityCategories: string[];
    pricePerUnit: number;
    countAfterMinutes: number;
    roundUpAfterMinutes: number;
    roundUpAfter: number;
  };
}

export function isActivityBonusByHourShiftAndActivityCategory(
  obj: BillingRule,
): obj is BillingRuleActivityBonusByHourShiftAndActivityCategory {
  return obj.calculatorName === 'ActivityBonusByHourShiftAndActivityCategory';
}
