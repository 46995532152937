import { DevStateLogEntry } from './dev.state';

export class LiveUpdatesEnable {
  static readonly type = '[Dev] LiveUpdatesEnable';
}

export class LiveUpdatesDisableAndReset {
  static readonly type = '[Dev] LiveUpdatesDisableAndReset';
}

export class DevStateAddLog {
  static readonly type = '[Dev] AddLog';
  constructor(
    public newEntry: Partial<Pick<DevStateLogEntry, 'timestamp'>> &
      Pick<DevStateLogEntry, 'level' | 'message' | 'extra'>,
  ) {}
}
