import { Injectable } from '@angular/core';
import { ParserService } from './parser.service';

@Injectable({
  providedIn: 'root',
})
export class StringParserService extends ParserService<string> {
  public parse(value: string): string {
    return value;
  }

  public unparse(value: string): string {
    return value;
  }
}
