<ng-container *ngIf="rule">
  <wilson-minimum-amount
    [minimumAmount]="rule.params.minimumAmount"
    customDescription="agreement.rules.ActivityByTonnage.minimum_amount.description"
    customLabel="agreement.rules.ActivityByTonnage.minimum_amount.label"
  ></wilson-minimum-amount>
  <wilson-price-per-unit
    (updatePricePerUnit)="setPricePerUnit($event)"
    [pricePerUnit]="rule.params.pricePerUnit"
    customDescription="agreement.rules.ActivityByTonnage.price_per_unit.description"
    customLabel="agreement.rules.ActivityByTonnage.price_per_unit.label"
  ></wilson-price-per-unit>
</ng-container>
