import { UserSharedByMe } from '@wilson/clients/services';

export const setShareComparator = (
  valueA: UserSharedByMe,
  valueB: UserSharedByMe,
) => {
  const hasSharedUsersA =
    valueA.sharedUsers && valueA.sharedUsers.length > 0 ? 1 : 0;
  const hasSharedUsersB =
    valueB.sharedUsers && valueB.sharedUsers.length > 0 ? 1 : 0;

  return hasSharedUsersA - hasSharedUsersB;
};
