import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {
  RoleAction,
  RolePermissionSubject,
  Shift,
  ShiftTemplate,
} from '@wilson/interfaces';
import { Store } from '@ngxs/store';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { MatomoTracker } from 'ngx-matomo-client';
import { ShiftsService } from '@wilson/shifts';
import { SaveAsTemplateDialogComponent } from '@wilson/non-domain-specific/shift-helpers/core';
import { FormGroup } from '@angular/forms';
import { EventCategory } from '@wilson/matomo';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ColorPickerModule } from 'ngx-color-picker';
import { AuthorizationModule } from '@wilson/authorization';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { captureException } from '@sentry/capacitor';

@Component({
  selector: 'wilson-save-as-template-action',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzDropDownModule,
    ColorPickerModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './save-as-template-action.component.html',
  styleUrl: './save-as-template-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveAsTemplateActionComponent {
  @Input()
  disabled = false;

  @Input({ required: true }) shift!: Shift;
  submitted = false;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    private readonly store: Store,
    private readonly nzModalService: NzModalService,
    private readonly translate: TranslateService,
    private readonly tracker: MatomoTracker,
    private readonly shiftsService: ShiftsService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  protected saveAsTemplate(isDisabled: boolean): Promise<void> | void {
    if (!isDisabled) {
      const userId = this.store.selectSnapshot((state) => state.auth.userId);
      const modal: NzModalRef = this.nzModalService.create({
        nzTitle: this.translate.instant('shift.page.save_as_template'),
        nzContent: SaveAsTemplateDialogComponent,
        nzWidth: '500px',
        nzFooter: [
          {
            label: this.translate.instant('general.cancel'),
            type: 'default',
            onClick: (): void => {
              modal.destroy();
            },
          },
          {
            label: this.translate.instant('general.accept'),
            type: 'primary',
            disabled: (componentInstance): boolean => {
              return (
                this.submitted ||
                (componentInstance as SaveAsTemplateDialogComponent).form
                  ?.invalid
              );
            },
            onClick: async (componentInstance): Promise<void> => {
              await this.accept(
                (componentInstance as SaveAsTemplateDialogComponent).form,
                userId,
              );
              modal.destroy();
            },
          },
        ],
      });

      if (modal.componentInstance) {
        modal.componentInstance.shift = this.shift;
        modal.componentInstance.userId = userId;
      }

      return modal.result;
    }
  }

  private async accept(form: FormGroup, userId: string): Promise<void> {
    this.submitted = true;
    const shiftTemplate: ShiftTemplate = form.value;
    delete shiftTemplate.organizationalUnit;
    shiftTemplate.shiftCategoryId = this.shift?.shiftCategoryId;
    shiftTemplate.createdById = userId;
    shiftTemplate.activityTemplates = [];
    try {
      await this.shiftsService.saveShiftAsTemplate(
        this.shift.id as string,
        shiftTemplate,
      );

      this.nzMessageService.success(
        this.translate.instant('shift.template.save_success', {
          name: shiftTemplate.name,
        }),
      );
    } catch (error) {
      captureException(error);
      this.submitted = false;
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
    this.tracker.trackEvent(EventCategory.Shift, 'save_shift_template');
  }
}
