import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCopy, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FormGroup, ControlsOf } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  IconActionRendererComponent,
  IconActionRendererParams,
} from '@wilson/ag-grid/renderers';
import { GridService } from '@wilson/ag-grid/service';
import { ManyEntity } from '@wilson/base';
import { Agreement, Client } from '@wilson/clients/interfaces';
import { AgreementsService } from '@wilson/clients/services';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';
import {
  AgreementsModalComponent,
  AgreementsModalInput,
} from '../agreements-modal/agreements-modal.component';
import {
  ApplyToOtherAgreementsModalComponent,
  ApplyToOtherAgreementsModalInput,
} from '../apply-to-other-agreements-modal/apply-to-other-agreements-modal.component';
import { ClientAgreementsService } from '../client-agreements.service';
import {
  CopyAgreementsModalComponent,
  CopyAgreementsModalInput,
} from '../copy-agreements-modal/copy-agreements-modal.component';
import { ColDef } from 'ag-grid-community';
import { captureException } from '@sentry/angular';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';
import { AblePurePipe } from '@casl/angular';
import { AnyAbility } from '@casl/ability';

@Component({
  selector: 'wilson-agreements-grid',
  templateUrl: './agreements-grid.component.html',
  styleUrls: ['./agreements-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
})
export class AgreementsGridComponent implements OnInit, OnChanges {
  @Input() searchString: string;
  public clients$: Observable<ManyEntity<Client>>;

  modalRef: NzModalRef;

  @ViewChild('customEditAgreementFooter', { static: false })
  templateFooter!: TemplateRef<Element>;

  public agreements$: Observable<Agreement[]>;
  public editAgreementId!: string;
  private readonly refresh$ = new BehaviorSubject<Date>(new Date());
  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = {
    ...defaultGridOptionsFactory(),
    paginationAutoPageSize: false,
    paginationPageSize: 10,
  };
  public readonly components = {
    IconActionRendererComponent: IconActionRendererComponent,
  };
  public readonly actionParams: IconActionRendererParams<Agreement> = {
    icons: [
      {
        faIcon: faCopy,
        iconSize: 'lg',
        action: (agreement) => this.copyAgreement(agreement),
        hasPermission$: this.ablePure.transform(
          RoleAction.Create,
          RolePermissionSubject.Client,
        ),
      },
      {
        faIcon: faTrash,
        iconSize: 'lg',
        nzDanger: true,
        action: (agreement) => this.confirmDeleteAgreement(agreement),
        hasPermission$: this.ablePure.transform(
          RoleAction.Delete,
          RolePermissionSubject.Client,
        ),
      },
      {
        faIcon: faEdit,
        iconSize: 'lg',
        action: (agreement) => this.editAgreement(agreement),
        hasPermission$: this.ablePure.transform(
          RoleAction.Update,
          RolePermissionSubject.Client,
        ),
      },
    ],
    isOnlyVisibleOnHover: false,
    buttonType: 'text',
  };
  selectedAgreements: Agreement[] = [];

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      sort: 'asc',
      width: 300,
      sortable: true,
      headerName: this.translate.instant('general.name'),
    },
    {
      field: 'companyCode',
      flex: 1,
      sortable: true,
      headerName: this.translate.instant('page.invoicing.company_code'),
    },
    {
      field: 'purchaseOrder',
      flex: 1,
      sortable: true,
      headerName: this.translate.instant('page.invoicing.purchase_order'),
    },
    {
      cellRenderer: 'IconActionRendererComponent',
      cellRendererParams: this.actionParams,
      cellStyle: { textAlign: 'center' },
      width: 140,
      resizable: false,
      sortable: false,
    },
  ];

  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    public readonly gridService: GridService<Agreement>,
    private readonly agreementsService: AgreementsService,
    private readonly toastrService: ToastrService,
    private readonly translate: TranslateService,
    private readonly modal: NzModalService,
    private readonly route: ActivatedRoute,
    private readonly clientAgreementsService: ClientAgreementsService,
    private readonly ablePure: AblePurePipe<AnyAbility>,
  ) {}

  async ngOnInit(): Promise<void> {
    const clientId = this.route.snapshot.params['clientId'];
    this.agreements$ = this.refresh$.pipe(
      tap(() => this.gridService.startLoad()),
      switchMap(() =>
        this.agreementsService.getMany({
          limit: 0,
          order: { createdAt: 'ASC' },
          where: { clientId },
        }),
      ),
      this.gridService.catchErrorContinueWithManyEntity(),
      map((res) => {
        this.gridService.endLoad();
        return res.data;
      }),
    );

    this.clients$ = await this.clientAgreementsService.getClients();
  }

  ngOnChanges(): void {
    this.gridService.gridApi?.setGridOption(
      'quickFilterText',
      this.searchString,
    );
  }

  private confirmDeleteAgreement(agreement: Agreement): void {
    this.modal.confirm({
      nzTitle: this.translate.instant('agreements.modal.delete.title'),
      nzContent: this.translate.instant('agreements.modal.delete.description'),
      nzOkDanger: true,
      nzCancelText: this.translate.instant('general.cancel'),
      nzOkText: this.translate.instant('general.delete'),
      nzOnOk: () => this.deleteAgreement(agreement),
    });
  }

  private async deleteAgreement(agreement: Agreement): Promise<void> {
    if (!agreement.id) return;

    try {
      await this.agreementsService.remove(agreement.id);
      this.refresh$.next(new Date());
    } catch (e) {
      this.toastrService.error(this.translate.instant('general.error'));
      captureException(e);
    }
  }

  private editAgreement(agreement: Agreement): void {
    if (agreement.id) {
      this.editAgreementId = agreement.id;
    }
    this.modalRef = this.modal.create<
      AgreementsModalComponent,
      AgreementsModalInput
    >({
      nzTitle: this.translate.instant('agreements.modal.edit.title'),
      nzClassName: 'agreements_modal',
      nzContent: AgreementsModalComponent,
      nzData: {
        agreement,
        clientId: this.route.snapshot.params['clientId'],
      },
      nzWidth: '900px',
      nzFooter: this.templateFooter,
    });
  }

  closeModal(): void {
    this.refresh$.next(new Date());
    this.modalRef.destroy();
  }

  applyToOtherAgreements(): void {
    this.openApplyToOtherAgreements();
  }

  async saveAgreement(): Promise<void> {
    const modalInstance = this.modalRef.componentInstance;

    this.patchFormRules(modalInstance?.agreementsFormService.form);
    this.convertVatAndAdvancePaymentToString(
      modalInstance?.agreementsFormService.form,
    );

    if (
      modalInstance?.agreementsFormService.form &&
      this.selectedAgreements.length
    ) {
      const agreementUpdate = this.createAgreementBulkUpdateObject(
        modalInstance?.agreementsFormService.form,
      );

      try {
        await this.agreementsService.bulkUpdateAgreements(agreementUpdate);
        this.refresh$.next(new Date());
        this.modalRef.destroy();
      } catch (e) {
        this.toastrService.error(this.translate.instant('general.error'));
        captureException(e);
      }
    }

    try {
      await modalInstance?.agreementsFormService.submit();
      this.refresh$.next(new Date());
    } catch (e) {
      this.patchFormRulesOnError(modalInstance?.agreementsFormService.form);
      this.toastrService.error(this.translate.instant('general.error'));
      captureException(e);
    }
    this.modalRef.destroy();
  }

  createAgreementBulkUpdateObject(
    formValues: FormGroup<ControlsOf<Agreement>>,
  ): Partial<Agreement[]> {
    return this.selectedAgreements.map(
      (item) =>
        ({
          advancePayment: formValues.value.advancePayment,
          companyCode: formValues.value.companyCode,
          currency: formValues.value.currency,
          description: formValues.value.description,
          id: item.id,
          name: formValues.value.name,
          purchaseOrder: formValues.value.purchaseOrder,
          rules: formValues.value.rules,
          vat: formValues.value.vat,
          costCenter: formValues.value.costCenter,
        } as Agreement),
    );
  }

  private openApplyToOtherAgreements(): void {
    const modalApplyToOther: NzModalRef = this.modal.create<
      ApplyToOtherAgreementsModalComponent,
      ApplyToOtherAgreementsModalInput
    >({
      nzTitle: this.translate.instant(
        'agreements.modal.edit.apply_changes_title',
      ),
      nzContent: ApplyToOtherAgreementsModalComponent,
      nzData: {
        editAgreementId: this.editAgreementId,
      },
      nzWidth: '900px',
      nzFooter: [
        {
          label: this.translate.instant('general.back'),
          type: 'default',
          onClick: async (): Promise<void> => {
            modalApplyToOther.destroy();
          },
        },
        {
          label: this.translate.instant('agreements.modal.save_and_apply'),
          type: 'primary',
          disabled: false,
          onClick: async (comp): Promise<void> => {
            this.selectedAgreements =
              comp?.getSelectedAgreements() as Agreement[];
            modalApplyToOther.destroy();
          },
        },
      ],
    });
  }

  public addAgreement(): void {
    const modal: NzModalRef = this.modal.create<
      AgreementsModalComponent,
      AgreementsModalInput
    >({
      nzTitle: this.translate.instant('agreements.modal.add.title'),
      nzContent: AgreementsModalComponent,
      nzClassName: 'agreements_modal',
      nzData: {
        clientId: this.route.snapshot.params['clientId'],
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: () => modal.destroy(),
        },
        {
          label: this.translate.instant('general.save'),
          type: 'primary',
          disabled: (comp) => comp?.agreementsFormService.form.invalid || false,
          onClick: async (comp): Promise<void> => {
            try {
              this.patchFormRules(comp?.agreementsFormService.form);
              this.convertVatAndAdvancePaymentToString(
                comp?.agreementsFormService.form,
              );
              await comp?.agreementsFormService.submit();
              this.refresh$.next(new Date());
            } catch (e) {
              this.patchFormRulesOnError(comp?.agreementsFormService.form);
              this.toastrService.error(this.translate.instant('general.error'));
              captureException(e);
            }
            modal.destroy();
          },
        },
      ],
    });
  }

  private copyAgreement(agreement: Agreement): void {
    const modal: NzModalRef = this.modal.create<
      CopyAgreementsModalComponent,
      CopyAgreementsModalInput
    >({
      nzTitle: this.translate.instant('agreements.modal.copy.title'),
      nzContent: CopyAgreementsModalComponent,
      nzData: {
        agreement,
        clients: this.clients$,
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: () => modal.destroy(),
        },
        {
          label: this.translate.instant('general.submit'),
          type: 'primary',
          disabled: (comp) => !comp?.getSelectedClients()?.length || false,
          onClick: async (comp): Promise<void> => {
            try {
              const selectedClients: Client[] | undefined =
                comp?.getSelectedClients();

              const clientsAndSelectedAgreement =
                selectedClients?.map((selectedClient) => {
                  return {
                    clientId: selectedClient.id,
                    name: agreement.name,
                    advancePayment: agreement.advancePayment,
                    companyCode: agreement.companyCode,
                    currency: agreement.currency,
                    description: agreement.description,
                    purchaseOrder: agreement.purchaseOrder,
                    rules: agreement.rules,
                    vat: agreement.vat,
                  } as Agreement;
                }) ?? [];

              this.agreementsService.addAgreementsToMultipleClients(
                clientsAndSelectedAgreement,
              );

              this.toastrService.success(
                this.translate.instant('page.copy-agreement-modal.success'),
              );
            } catch (e) {
              this.toastrService.error(this.translate.instant('general.error'));
              captureException(e);
            }
            modal.destroy();
          },
        },
      ],
    });
  }

  private patchFormRules(
    form: FormGroup<ControlsOf<Agreement>> | undefined,
  ): void {
    if (!form) {
      return;
    }

    const rulesControl = form.get('rules');

    if (rulesControl) {
      const value = rulesControl.value;
      const rulesValue = value ? JSON.parse(value as string) : [];
      rulesControl.patchValue(rulesValue);
    }

    this.updateVatAndAdvancePaymentFormControls(form);
  }

  private patchFormRulesOnError(
    form: FormGroup<ControlsOf<Agreement>> | undefined,
  ): void {
    if (!form) {
      return;
    }

    const rulesControl = form.get('rules');
    if (rulesControl) {
      rulesControl.patchValue(JSON.stringify(rulesControl.value));
    }
    this.updateVatAndAdvancePaymentFormControls(form);
  }

  private updateVatAndAdvancePaymentFormControls(
    form: FormGroup<ControlsOf<Agreement>>,
  ): void {
    const vatControl = form.get('vat');
    if (vatControl) {
      vatControl.patchValue(Number(vatControl.value));
    }

    const advancePaymentControl = form.get('advancePayment');
    if (advancePaymentControl) {
      advancePaymentControl.patchValue(Number(advancePaymentControl.value));
    }
  }

  private convertVatAndAdvancePaymentToString(
    form: FormGroup<ControlsOf<Agreement>> | undefined,
  ): void {
    if (!form) {
      return;
    }

    const vatControl = form.get('vat');
    if (vatControl) {
      vatControl.patchValue(String(vatControl.value));
    }

    const advancePaymentControl = form.get('advancePayment');
    if (advancePaymentControl) {
      advancePaymentControl.patchValue(String(advancePaymentControl.value));
    }
  }
}
