import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PayrollService } from '@wilson/api/gateway';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import {
  CorePayrollCategory,
  PayrollTransaction,
  RoleAction,
  RolePermissionSubject,
  ShiftState,
} from '@wilson/interfaces';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { firstValueFrom } from 'rxjs';
import {
  CreatePayrollTransaction,
  PayrollTransactionListComponent,
  UpdatePayrollTransaction,
} from '../payroll-transaction-list/payroll-transaction-list.component';
import { PayrollTransactionsHelperService } from './payroll-transactions-helper.service';
import { AuthorizationModule } from '@wilson/authorization';
import { AblePurePipe } from '@casl/angular';
import { AnyAbility } from '@casl/ability';

@Component({
  selector: 'wilson-payroll-transactions',
  templateUrl: './payroll-transactions.component.html',
  styleUrls: ['./payroll-transactions.component.scss'],
  providers: [PayrollTransactionsHelperService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    NzDrawerModule,
    NzButtonModule,
    NzCollapseModule,
    NzDividerModule,
    RouterModule,
    NzAlertModule,
    NzSkeletonModule,
    NzToolTipModule,
    PayrollTransactionListComponent,
    FeatureFlagsModule,
    AuthorizationModule,
  ],
})
export class PayrollTransactionsComponent implements OnInit {
  @Input() shiftId?: string;
  @Input() userId?: string;
  @Input() shiftDate!: string;
  @Input() shiftState!: ShiftState;
  @Input() isExpanded = true;

  @Output() refresh = new EventEmitter();

  public isLoadingTransactions = true;
  public isUpdatingTransaction = false;
  public deletingId: string | null = null;
  public isAddingNewTransaction = false;
  public resetEditTransaction!: Date;

  public allPayrollCategories!: CorePayrollCategory[];
  public filteredPayrollCategories!: CorePayrollCategory[];
  public payrollTransactions: PayrollTransaction[] = [];

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  protected hasPayrollReadPermission$ = this.ablePurePipe.transform(
    RoleAction.Update,
    RolePermissionSubject.Billing,
  );

  constructor(
    private readonly payrollService: PayrollService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly payrollTransactionsHelperService: PayrollTransactionsHelperService,
    private readonly ablePurePipe: AblePurePipe<AnyAbility>,
  ) {}

  async ngOnInit(): Promise<void> {
    const hasPayrollReadPermission = await firstValueFrom(
      this.hasPayrollReadPermission$,
    );
    if (hasPayrollReadPermission) {
      this.allPayrollCategories = await firstValueFrom(
        this.payrollService.getPayrollCategories(),
      );
      await this.loadPayrollTransactions();
      this.cdRef.detectChanges();
    }
  }

  async loadPayrollTransactions(): Promise<void> {
    this.payrollTransactions = await firstValueFrom(
      this.payrollService.getPayrollTransactions({
        userId: this.userId,
        shiftId: this.shiftId,
      }),
    );
    this.payrollTransactions.map((transaction) => {
      transaction.payrollCategory = this.allPayrollCategories.find(
        (category) => category.id === transaction.payrollCategoryId,
      );
    });
    this.resetFormSettings();
    this.cdRef.detectChanges();
  }

  filterPayrollCategories(): void {
    this.filteredPayrollCategories = this.allPayrollCategories.filter(
      (category) =>
        !this.payrollTransactions.some(
          (transaction) => transaction.payrollCategoryId === category.id,
        ),
    );
  }

  async createPayrollTransaction(
    payload: CreatePayrollTransaction,
  ): Promise<void> {
    if (this.userId && this.shiftId) {
      this.isUpdatingTransaction = true;
      this.cdRef.detectChanges();
      const result =
        await this.payrollTransactionsHelperService.createPayrollTransaction(
          payload,
          this.userId,
          this.shiftId,
          this.shiftDate,
          this.payrollTransactions,
          this.allPayrollCategories,
        );
      this.payrollTransactions = result.payrollTransactions;
      if (result.isSuccessfullyCreated) {
        this.resetFormSettings();
      } else {
        this.isUpdatingTransaction = false;
      }
      this.refresh.emit();
      this.cdRef.detectChanges();
    }
  }

  async updatePayrollTransaction(
    payload: UpdatePayrollTransaction,
  ): Promise<void> {
    this.isUpdatingTransaction = true;
    this.cdRef.detectChanges();
    const result =
      await this.payrollTransactionsHelperService.updatePayrollTransaction(
        payload,
        this.payrollTransactions,
        this.allPayrollCategories,
      );
    this.payrollTransactions = result.payrollTransactions;
    if (result.isSuccessfullyUpdated) {
      this.resetFormSettings();
      this.resetEditTransaction = new Date();
    } else {
      this.isUpdatingTransaction = false;
    }
    this.refresh.emit();
    this.cdRef.detectChanges();
  }

  async deletePayrollTransaction(transactionId: string): Promise<void> {
    this.deletingId = transactionId;
    this.cdRef.detectChanges();
    this.payrollTransactions =
      await this.payrollTransactionsHelperService.deletePayrollTransaction(
        transactionId,
        this.payrollTransactions,
      );
    this.resetFormSettings();
    this.refresh.emit();
    this.cdRef.detectChanges();
  }

  toggleCreateMode(isEnable: boolean): void {
    this.isAddingNewTransaction = isEnable;
    this.cdRef.detectChanges();
  }

  resetFormSettings(): void {
    this.filterPayrollCategories();
    this.deletingId = null;
    this.isAddingNewTransaction = false;
    this.isLoadingTransactions = false;
    this.isUpdatingTransaction = false;
  }

  compareFn = (selectedCategory: string, category: string): boolean =>
    selectedCategory === category;
}
