import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LabelsGateway } from '@wilson/labels';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterKey } from '../../filter.keys.type';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-labels-filter',
  templateUrl: './labels-filter.component.html',
  styleUrls: ['./labels-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showLabel = false;
  @Input() hideNoLabelOption = false;
  @Input() placeholder!: string;
  @Input()
  set filterKey(value: FilterKey) {
    this.filterKeySubject.next(value);
  }
  private filterKeySubject!: BehaviorSubject<FilterKey>;
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly labelsGateway: LabelsGateway,
  ) {
    const subject = new BehaviorSubject<FilterKey>('labels');
    super(subject.asObservable());
    this.filterKeySubject = subject;
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.labelsGateway.getLabels().pipe(
      map((label) => {
        return label.map((label) => {
          return {
            id: label.id.toString(),
            name: label.name.toString(),
            colorCode: label.colorCode,
          };
        });
      }),
    );
  }
}
