import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { InvoiceStatus } from '@wilson/interfaces';

@Component({
  selector: 'wilson-invoice-status-filter',
  templateUrl: './invoice-status-filter.component.html',
  styleUrls: ['./invoice-status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceStatusFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() projectStatus = null;
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
  ) {
    super('invoiceStatus');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    const options: FilterOptions[] = Object.keys(InvoiceStatus).map((key) => ({
      id: key.toLowerCase(),
      name: key,
    }));
    return of(options);
  }
}
