<li
  *ngIf="
    (RoleAction.Read | ablePure : RolePermissionSubject.Shift | async) &&
    ('portal-export-shift' | featureFlagPure | async)
  "
  (click)="exportShift()"
  nz-menu-item
>
  {{ 'page.shift_details.export_shift' | translate }}
</li>
