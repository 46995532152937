import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Params,
  Router,
  Scroll,
} from '@angular/router';
import { firstValueFrom, Observable, filter, of, race } from 'rxjs';
import {
  delay,
  switchMap,
  map,
  shareReplay,
  distinctUntilChanged,
} from 'rxjs/operators';
import { FilterKey } from './filter.keys.type';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
  ) {}

  //eslint-disable-next-line
  private async waitForNavigationToComplete(): Promise<any> {
    const NO_EVENT = 'NO_EVENT';
    const timeoutObservable = of(NO_EVENT).pipe(delay(100));
    const routerEventTracker = this.router.events.pipe(shareReplay(1));

    const combinedObservable = race(routerEventTracker, timeoutObservable);

    return firstValueFrom(
      combinedObservable.pipe(
        switchMap((event) => {
          if (event === NO_EVENT) {
            return of(null); // we don't wait anymore and resolve the promise
          } else {
            return routerEventTracker.pipe(
              filter(
                (event: any) =>
                  event instanceof NavigationEnd || event instanceof Scroll,
              ),
            );
          }
        }),
      ),
    );
  }

  public async saveValueInQueryParam(
    field: string,
    value: string,
  ): Promise<boolean> {
    await this.waitForNavigationToComplete();
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [field]: value ? value : null },
      queryParamsHandling: 'merge',
    });
  }

  public getValueFromQueryParam(field: string): Observable<string | null> {
    return this.route.queryParamMap.pipe(
      map((params: ParamMap) => params.get(field)),
      distinctUntilChanged(),
    );
  }

  public get isFiltered(): Observable<boolean> {
    return this.route.queryParams.pipe(
      map(
        (params: Params) =>
          !(
            params &&
            Object.keys(params).length === 0 &&
            Object.getPrototypeOf(params) === Object.prototype
          ),
      ),
    );
  }

  public resetAllQueryParams(): Promise<boolean> {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }

  public async setFiltersInQueryParams(
    filterCriteria: Record<FilterKey, string>,
  ): Promise<boolean> {
    await this.waitForNavigationToComplete();
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: filterCriteria,
    });
  }
}
