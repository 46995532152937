import { BillingRule } from './billing-rules';

export interface BillingRuleAdHocShiftRate extends BillingRule {
  calculatorName: 'AdHocShiftRate';
  params: {
    pricePerUnit: number;
  };
}

export function isAdHocShiftRate(
  obj: BillingRule,
): obj is BillingRuleAdHocShiftRate {
  return obj.calculatorName === 'AdHocShiftRate';
}
