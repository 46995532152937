import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, patch } from '@ngxs/store/operators';
import { CapacitorLiveUpdateService } from '../../capacitor/live-updates/capacitor-live-update.service';
import {
  DevStateAddLog,
  LiveUpdatesDisableAndReset,
  LiveUpdatesEnable,
} from './dev.action';

export const DEV_STATE_NAME = 'wilsondev';

export interface DevStateModel {
  liveUpdatesActive: boolean;
  logs: DevStateLogEntry[];
}

export interface DevStateLogEntry {
  timestamp: number;
  level: 'info' | 'warn' | 'error';
  message: string;
  extra?: any;
}

const devStateDefaults: DevStateModel = {
  liveUpdatesActive: true,
  logs: [],
};

@State<DevStateModel>({
  name: DEV_STATE_NAME,
  defaults: devStateDefaults,
})
@Injectable()
export class DevState {
  constructor(
    private readonly capacitorLiveUpdateService: CapacitorLiveUpdateService,
    private readonly storage: Storage,
  ) {}

  @Selector()
  static liveUpdatesActive(
    state: DevStateModel,
  ): DevStateModel['liveUpdatesActive'] {
    return state.liveUpdatesActive;
  }

  @Selector()
  static logs(state: DevStateModel): DevStateModel['logs'] {
    return state.logs;
  }

  async ngxsAfterBootstrap(ctx: StateContext<DevStateModel>): Promise<void> {
    const storageDevState: DevStateModel = await this.storage.get(
      DEV_STATE_NAME,
    );
    if (storageDevState) {
      ctx.setState(storageDevState);
    }
  }

  @Action(LiveUpdatesEnable)
  liveUpdatesEnable(ctx: StateContext<DevStateModel>) {
    ctx.patchState({
      liveUpdatesActive: true,
    });
    this.storage.set(DEV_STATE_NAME, ctx.getState());
  }

  @Action(LiveUpdatesDisableAndReset)
  liveUpdatesDisableAndReset(ctx: StateContext<DevStateModel>) {
    ctx.patchState({
      liveUpdatesActive: false,
    });
    this.storage.set(DEV_STATE_NAME, ctx.getState());
    this.capacitorLiveUpdateService.resetLiveUpdates();
  }

  @Action(DevStateAddLog)
  addLog(ctx: StateContext<DevStateModel>, action: DevStateAddLog) {
    const payload: DevStateLogEntry = {
      ...action.newEntry,
      timestamp: action.newEntry.timestamp ?? Date.now(),
    };
    this.appendToLog(ctx, payload);
    this.sortLogDesc(ctx);
    this.cleanupLog(ctx);
  }

  private appendToLog(
    ctx: StateContext<DevStateModel>,
    payload: DevStateLogEntry,
  ) {
    ctx.setState(
      patch<DevStateModel>({
        logs: append<DevStateLogEntry>([payload]),
      }),
    );
  }

  private sortLogDesc(ctx: StateContext<DevStateModel>) {
    ctx.setState(
      patch<DevStateModel>({
        logs: (logs) => [...logs].sort((a, b) => b.timestamp - a.timestamp),
      }),
    );
  }

  private cleanupLog(ctx: StateContext<DevStateModel>) {
    ctx.setState(
      patch<DevStateModel>({
        logs: iif<DevStateLogEntry[]>(
          (logs) => logs.length > 1000,
          (logs) => logs.slice(-1000),
        ),
      }),
    );
  }
}
