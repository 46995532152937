import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ExternalUser,
  SharedUserApprovalStatus,
} from '@wilson/clients/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-set-approval-renderer',
  template: `
    <wilson-set-shared-user-approval
      *ngIf="approvalStatus && user && params; else noApprovalStatus"
      [approvalStatus]="approvalStatus"
      [user]="user"
      [params]="params"
    ></wilson-set-shared-user-approval>
    <ng-template #noApprovalStatus>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetApprovalRendererComponent implements ICellRendererAngularComp {
  approvalStatus: SharedUserApprovalStatus;
  user: ExternalUser;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.approvalStatus = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.approvalStatus = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams): SharedUserApprovalStatus {
    this.params = params;
    this.user = params?.data;
    return params?.data?.approvalStatus;
  }
}
