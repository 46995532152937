<ng-container *ngIf="ruleName">
  <wilson-rule-label [customLabel]="customLabel"></wilson-rule-label>
  <wilson-rule-description
    [customDescription]="customDescription"
  ></wilson-rule-description>
  <input
    nz-input
    [value]="ruleName"
    (change)="setRuleName($event)"
    type="text"
  />
</ng-container>
