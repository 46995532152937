import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountValueGetterService } from '@wilson/account/ag-grid';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  LinkRendererComponent,
  UserAvatarRendererComponent,
} from '@wilson/ag-grid/renderers';
import { GridService } from '@wilson/ag-grid/service';
import {
  ClientPartnerShipGateway,
  ClientsV2Service,
  UserSharedByMe,
} from '@wilson/clients/services';
import {
  FeatureName,
  LinkRendererParams,
  RoleAction,
  RolePermissionSubject,
  User,
} from '@wilson/interfaces';
import {
  ColDef,
  GridApi,
  GridOptions,
  ValueGetterParams,
} from 'ag-grid-community';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  Observable,
  Subscription,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { approvalStatusComparator } from '../shared-user-approval/shared-user-approval-comparator/shared-user-approval-comparator';
import { ProviderShareUserListService } from './provider-share-user-list-service/provider-share-user-list-service';
import { setShareComparator } from './set-share-renderer/set-share-comparator';
import { SetShareRendererComponent } from './set-share-renderer/set-share-renderer.component';
import { UserApprovalStatusRendererComponent } from './user-approval-status-renderer/user-approval-status.renderer.component';
import { approvedAtComparator } from './user-approved-at-renderer/user-approved-at-comparator';
import { UserApprovedAtRendererComponent } from './user-approved-at-renderer/user-approved-at-renderer.component';
import { sharedAtComparator } from './user-shared-at-renderer/user-shared-at-comparator';
import { UserSharedAtRendererComponent } from './user-shared-at-renderer/user-shared-at-renderer.component';
import { ViewAssignedProfessionsRendererComponent } from './view-profession-renderer/view-profession-renderer.component';

@Component({
  selector: 'wilson-provider-share-users-list',
  templateUrl: './provider-share-users-list.component.html',
  styleUrls: ['./provider-share-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderShareUsersListComponent implements OnInit, OnDestroy {
  private updateSuccessSubscription: Subscription;
  public gridApi?: GridApi;
  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions: GridOptions = {
    ...defaultGridOptionsFactory(),
    onGridReady: (params) => {
      this.gridApi = params.api;
    },
    // faster transaction executions - mapping UserSharedByMe id to row id
    getRowId: (params) => params.data.id,
    // uninterupted UX experience (no sorting, filtering or aggregation on update)
    suppressModelUpdateAfterUpdateTransaction: true,
  };
  private readonly userNameRendererParams: LinkRendererParams<User> = {
    link: {
      link: (entity) => (entity.id ? `/employees/${entity.id}` : null),
    },
  };
  protected partnerName: string;
  protected users$: Observable<UserSharedByMe[]>;
  protected readonly components = {
    userAvatarRenderer: UserAvatarRendererComponent,
    linkRendererComponent: LinkRendererComponent,
    viewAssignedProfessionsRendererComponent:
      ViewAssignedProfessionsRendererComponent,
    setShareRendererComponent: SetShareRendererComponent,
    userSharedAtRendererComponent: UserSharedAtRendererComponent,
    userApprovalStatusRendererComponent: UserApprovalStatusRendererComponent,
    userApprovedAtRendererComponent: UserApprovedAtRendererComponent,
  };
  private readonly comparators = {
    approvalStatusComparator,
    approvedAtComparator,
    setShareComparator,
    sharedAtComparator,
  };
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  protected columnDefs: ColDef[] = [
    {
      field: 'profileImageUrl',
      headerName: '',
      width: 75,
      resizable: false,
      sortable: false,
      cellRenderer: 'userAvatarRenderer',
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.employee.header',
      ),
      flex: 1,
      resizable: true,
      sortIndex: 2,
      valueGetter: this.accountValueGetterService.userNameValueGetter,
      cellRenderer: 'linkRendererComponent',
      filter: 'agTextColumnFilter',
      cellRendererParams: this.userNameRendererParams,
      sort: 'asc',
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.professions_set_by_partner.header',
      ),
      flex: 2,
      resizable: true,
      sortable: false,
      wrapText: true,
      cellRenderer: 'viewAssignedProfessionsRendererComponent',
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.share_toggle.header',
      ),
      flex: 1,
      resizable: true,
      sortable: true,
      sortIndex: 1,
      valueGetter: this.userSharedByMeValueGetter,
      cellRenderer: 'setShareRendererComponent',
      colId: 'setShareToggle',
      sort: 'desc',
      comparator: this.comparators.setShareComparator,
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.shared_at.header',
      ),
      flex: 1,
      resizable: true,
      sortable: true,
      sortIndex: 3,
      valueGetter: this.userSharedByMeValueGetter,
      cellRenderer: 'userSharedAtRendererComponent',
      sort: 'desc',
      comparator: this.comparators.sharedAtComparator,
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.approval_status.header',
      ),
      flex: 1,
      resizable: true,
      valueGetter: this.userSharedByMeValueGetter,
      cellRenderer: 'userApprovalStatusRendererComponent',
      comparator: this.comparators.approvalStatusComparator,
    },
    {
      field: '',
      headerName: this.translateService.instant(
        'client.shared_by_me.approved_on.header',
      ),
      flex: 1,
      resizable: true,
      valueGetter: this.userSharedByMeValueGetter,
      cellRenderer: 'userApprovedAtRendererComponent',
      comparator: this.comparators.approvedAtComparator,
    },
  ];

  constructor(
    public readonly accountValueGetterService: AccountValueGetterService,
    public readonly gridService: GridService<User>,
    private readonly clientPartnerShipGateway: ClientPartnerShipGateway,
    private readonly providerShareUserListService: ProviderShareUserListService,
    private readonly nzNotificationService: NzNotificationService,
    private readonly translateService: TranslateService,
    private readonly clientService: ClientsV2Service,
    private readonly route: ActivatedRoute,
  ) {}

  userSharedByMeValueGetter(params: ValueGetterParams) {
    return params.data;
  }

  ngOnInit(): void {
    this.users$ = this.route.paramMap.pipe(
      map((params) => params.get('clientId')),
      switchMap((clientId) => {
        return this.clientService
          .findOne(clientId as string, { relations: ['clientPartnership'] })
          .pipe(
            take(1),
            filter((partner) =>
              Boolean(partner.clientPartnership?.partnershipId),
            ),
            tap((partner) => {
              this.partnerName = partner.name;
              this.providerShareUserListService.setPartnerShipId(
                partner.clientPartnership?.partnershipId as string,
              );
            }),
            switchMap((partner) => {
              return this.clientPartnerShipGateway
                .get(`${partner.clientPartnership?.partnershipId}/users`)
                .pipe(
                  take(1),
                  map(
                    (sharedByMe) => sharedByMe as unknown as UserSharedByMe[],
                  ),
                );
            }),
          );
      }),
    );

    this.updateSuccessSubscription =
      this.providerShareUserListService.updateSuccess$.subscribe(
        ({ result, cellRendererParams, success }) => {
          if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(
              cellRendererParams.node.id as string,
            );
            if (rowNode) {
              const transaction = { update: [result] };
              this.gridApi.applyTransaction(transaction);
              // force redraw - because we disabled sorting, filtering & aggregation on update transactions in gridOptions
              this.gridApi.redrawRows({ rowNodes: [rowNode] });
            }
          }
          if (!success) {
            this.nzNotificationService.error(
              this.translateService.instant('general.error'),
              this.translateService.instant('general.something_went_wrong'),
            );
          }
        },
      );
  }

  ngOnDestroy() {
    this.updateSuccessSubscription.unsubscribe();
  }
}
