import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canDeactivateGuardFn } from '@wilson/shared-components';
import { ClientsDetailComponent } from './clients-detail/clients-detail.component';
import { ClientsOverviewComponent } from './clients-overview/clients-overview.component';
import { NewClientsComponent } from './new-clients/new-clients.component';

const routes: Routes = [
  { path: '', component: ClientsOverviewComponent },
  {
    path: 'new',
    component: NewClientsComponent,
    data: {
      breadcrumb: 'page.settings.clients.new',
    },
  },
  {
    path: ':clientId',
    data: { breadcrumb: 'page.settings.clients.detail' },
    children: [
      {
        path: '',
        canDeactivate: [canDeactivateGuardFn],
        component: ClientsDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientsCoreRoutingModule {}
