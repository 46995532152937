import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Agreement } from '@wilson/clients/interfaces';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { AgreementsFormService } from '../agreements-form.service';
import { BillingRules } from '../rules/interfaces/billing-rules';

export interface AgreementsModalInput {
  agreement?: Agreement;
  clientId: string;
}

@Component({
  selector: 'wilson-agreements-modal',
  templateUrl: './agreements-modal.component.html',
  styleUrls: ['./agreements-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AgreementsFormService],
})
export class AgreementsModalComponent implements OnInit, OnDestroy {
  @Input() agreement?: Omit<Agreement, 'rules'> & {
    rules: BillingRules[];
  };
  @Input() clientId: string;
  destroyed$ = new Subject<void>();

  constructor(
    public readonly agreementsFormService: AgreementsFormService,
    @Inject(NZ_MODAL_DATA) private data: AgreementsModalInput,
  ) {}

  ngOnInit(): void {
    this.agreement = this.data.agreement as Omit<Agreement, 'rules'> & {
      rules: BillingRules[];
    };
    this.clientId = this.data.clientId;

    this.agreementsFormService.form.patchValue({ clientId: this.clientId });
    if (this.agreement?.id) {
      this.agreementsFormService.initializeWithData({
        ...this.agreement,
        rules: JSON.stringify(this.agreement.rules),
      });
    }

    this.agreementsFormService.form.controls['rules']?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        if (val && typeof val === 'string') {
          const rules = JSON.parse(val as string);
          if (
            rules &&
            this.agreement?.rules &&
            rules.length !== this.agreement?.rules.length
          ) {
            this.agreement.rules = rules;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
