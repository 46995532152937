<div
  class="header mb-3"
  [class.sticky-header]="stickyHeader"
  *ngIf="renderHeader"
>
  <ng-content select="[headerContent]"></ng-content>
</div>

<div class="content">
  <ng-content select="[mainContent]"></ng-content>
</div>
