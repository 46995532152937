import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { RuleLabelComponent } from '../rule-label/rule-label.component';

@Component({
  selector: 'wilson-time-bonus-calculation-method',
  standalone: true,
  imports: [
    CommonModule,
    NzTagModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './time-bonus-calculation-method.component.html',
  styleUrl: './time-bonus-calculation-method.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBonusCalculationMethodComponent {
  @Input() timeBonusCalculationMethod: string;
  @Input() customLabel!: string;
  @Input() customDescription!: string;
}
