import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Activity,
  OperationStatus,
  PREPARED_SHIFT,
  PreparedShift,
  Shift,
} from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DuplicateShiftActionService {
  constructor(private router: Router) {}

  prepareDuplicateShiftDataAndRedirect(
    shift: Shift & { activities: Activity[] },
    openInNewTab: boolean,
  ): void {
    const preparedShift: PreparedShift = {
      name: shift.name,
      startDate: shift.startDate,
      comment: shift.comment,
      organizationalUnit: shift.organizationalUnit,
      organizationalUnitId: shift.organizationalUnitId,
      shiftCategory: shift.shiftCategory,
      shiftCategoryId: shift.shiftCategoryId,
      projectId: shift.projectId,
      labels: shift.labels,
      activities:
        shift.activities.map(({ ...activity }) => ({
          ...activity,
          operationalStatus: OperationStatus.NotStarted,
          activityComments: null,
          activityReports: null,
          numberOfWagons: null,
          organizationalUnitTransportGoodsId: null,
          service: null,
          serviceId: null,
          tonnage: null,
          travelDirection: null,
          vehicleEmissionClassId: null,
        })) || [],
    };

    if (openInNewTab) {
      localStorage.setItem(PREPARED_SHIFT, JSON.stringify(preparedShift));
      const urlTree = this.router.createUrlTree(['shifts', 'new']);
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigate(['shifts', 'new'], {
        state: {
          [PREPARED_SHIFT]: preparedShift,
        },
      });
    }
  }
}
