<ng-container *ngIf="timeFrame && timeFrame.start && timeFrame.end">
  <wilson-rule-label [customLabel]="customLabel"></wilson-rule-label>
  <wilson-rule-description
    [customDescription]="customDescription"
  ></wilson-rule-description>
  <nz-tag>{{
    'agreement.rules.TimeFrameBonus.bonuses.time_frame.start'
      | translate : { start: timeFrame.start }
  }}</nz-tag>
  <nz-tag>{{
    'agreement.rules.TimeFrameBonus.bonuses.time_frame.end'
      | translate : { end: timeFrame.end }
  }}</nz-tag>
</ng-container>
