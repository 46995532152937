import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { LocationSuggestionAutocompleteService } from '@wilson/locations-v2/services';
import { LocationNamePipe } from '@wilson/pipes';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  finalize,
  first,
  map,
  of,
  switchMap,
} from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterKey } from '../../filter.keys.type';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { FilterLabelComponent } from '../filter-label/filter-label.component';

@Component({
  selector: 'wilson-activity-location-filter',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    NzSelectModule,
    FormsModule,
    FilterLabelComponent,
    NzIconModule,
  ],
  templateUrl: './activity-location-filter.component.html',
  styleUrls: ['./activity-location-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLocationFilterComponent
  extends BaseSelectFilterDirective<FilterOptions, string[]>
  implements OnDestroy, OnInit
{
  @Input()
  label!: string;

  @Input() showLabel = true;

  @Input()
  set filterKey(value: FilterKey) {
    this.filterKeySubject.next(value);
  }

  @Input()
  showRandomSuggestions = true;

  filteredOptions$!: Observable<FilterOptions[]>;
  isLoading: WritableSignal<boolean> = signal(false);

  private searchResultsSubject = new BehaviorSubject<FilterOptions[]>([]);
  private searchSubscription!: Subscription;
  private filterKeySubject!: BehaviorSubject<FilterKey>;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly locationsService: LocationsService,
    protected readonly locationSuggestionAutocompleteService: LocationSuggestionAutocompleteService,
    private readonly locationNamePipe: LocationNamePipe,
    private readonly cdr: ChangeDetectorRef,
  ) {
    const subject = new BehaviorSubject<FilterKey>('activityStartLocation');
    super(subject.asObservable());
    this.filterKeySubject = subject;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filteredOptions$ = combineLatest([
      this.options$,
      this.searchResultsSubject,
    ]).pipe(
      map(
        ([defaultOptions, searchResultOptions]: [
          FilterOptions[],
          FilterOptions[],
        ]) => {
          return searchResultOptions.length
            ? searchResultOptions
            : defaultOptions;
        },
      ),
    );
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    this.isLoading.set(true);
    const selectedLocationSearchStream = this.selected$.pipe(
      switchMap((selectedIds) => {
        if (selectedIds.length) {
          return this.locationsService.getGeoLocationsByIds(selectedIds).pipe(
            catchError(() => {
              return of([]);
            }),
          );
        } else {
          return of([]);
        }
      }),
    );

    const getRandomLocationsStream = this.showRandomSuggestions
      ? this.locationsService.getAll({
          limit: 30,
          order: {
            name: 'ASC',
          },
        })
      : of([]);

    return combineLatest([
      selectedLocationSearchStream,
      getRandomLocationsStream,
    ]).pipe(
      map(
        ([selectedLocations, randomLocations]: [
          GeoLocation[],
          GeoLocation[],
        ]) => {
          const combinedLocations = [...selectedLocations, ...randomLocations];
          this.isLoading.set(false);
          this.cdr.detectChanges();
          return combinedLocations.map((location) => {
            return {
              id: location.id as string,
              name: this.locationNamePipe.transform(location),
            };
          });
        },
      ),
    );
  }

  onSearch(value: string) {
    if (value) {
      this.searchSubscription?.unsubscribe(); //Cancel previous search request
      this.isLoading.set(true);

      this.searchSubscription = this.locationSuggestionAutocompleteService
        .searchFromInternalSystem(value)
        .pipe(
          first(),
          finalize(() => {
            this.isLoading.set(false);
            this.cdr.detectChanges();
          }),
        )
        .subscribe({
          next: (results) => {
            const resultOptions = results.map((result) => {
              return {
                id: result.id as string,
                name: this.locationNamePipe.transform(result),
              };
            });

            this.searchResultsSubject.next(resultOptions);
          },
        });
    }
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }
}
