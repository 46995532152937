import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import {
  FilterModule,
  QualificationV2CategoryFilterComponent,
  QualificationV2StatusFilterComponent,
  SharedUserFilterComponent,
  SharedUserQualificationV2PartnerFilterComponent,
} from '@wilson/filter';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface FilterValues {
  partnerIds: string[];
  userIds: string[];
  status: string[];
  category: string[];
}

@Component({
  selector: 'wilson-shared-user-qualifications-filter-drawer',
  templateUrl: './shared-user-qualifications-filter-drawer.component.html',
  styleUrls: ['./shared-user-qualifications-filter-drawer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AuthorizationModule,
    CommonModule,
    FilterModule,
    NzButtonModule,
    NzDividerModule,
    NzDrawerModule,
    NzSelectModule,
    QualificationV2CategoryFilterComponent,
    QualificationV2StatusFilterComponent,
    SharedUserFilterComponent,
    SharedUserQualificationV2PartnerFilterComponent,
    TranslateModule,
  ],
})
export class SharedUserQualificationsFilterDrawerComponent {
  selectedStatus: string[] = [];
  selectedCategories: string[] = [];
  selectedUserIds: string[] = [];
  selectedPartnerIds: string[] = [];

  @Input() isDrawerVisible!: boolean;
  @Output() closeDrawer = new EventEmitter<void>();
  @Output() applyFilters = new EventEmitter<FilterValues>();

  private filterChangeSubject = new Subject<FilterValues>();

  constructor() {
    this.setupFilterChangeDetection();
  }

  private setupFilterChangeDetection(): void {
    this.filterChangeSubject
      .pipe(
        debounceTime(200),
        distinctUntilChanged((prev, curr) => this.areFiltersEqual(prev, curr)),
      )
      .subscribe((filters) => {
        this.applyFilters.emit(filters);
      });
  }

  onStatusChange(status: string[]): void {
    this.selectedStatus = status;
    this.emitFilterChanges();
  }

  onCategoryChange(categories: string[]): void {
    this.selectedCategories = categories;
    this.emitFilterChanges();
  }

  onUserIdsChange(userIds: string[]): void {
    this.selectedUserIds = userIds;
    this.emitFilterChanges();
  }

  onPartnerChange(partners: string[]): void {
    this.selectedPartnerIds = partners;
    this.emitFilterChanges();
  }

  private emitFilterChanges(): void {
    this.filterChangeSubject.next({
      partnerIds: this.selectedPartnerIds,
      userIds: this.selectedUserIds,
      status: this.selectedStatus,
      category: this.selectedCategories,
    });
  }

  private areFiltersEqual(prev: FilterValues, curr: FilterValues): boolean {
    return JSON.stringify(prev) === JSON.stringify(curr);
  }
}
