import { QualificationV2 } from '@wilson/interfaces';

export class ResetQualificationsV2 {
  static readonly type = '[QualificationsState] ResetQualificationsState';
}

export class UpsertQualificationsV2 {
  static readonly type = '[QualificationsState] UpsertQualificationsState';
  constructor(public upsertQualifications: QualificationV2[]) {}
}

export class GetOrFetchQualificationV2 {
  static readonly type = '[QualificationsState] GetOrFetchQualification';
  constructor(public qualificationId: string) {}
}
