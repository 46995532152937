@let hasClientUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Client | async); @let forbiddenMessage =
'general.forbidden_action' | translate;

<form [formGroup]="form">
  <div class="row">
    <div class="w-100">
      <div>
        <div class="form-group">
          <label class="form-text" for="address">
            {{ 'page.general_settings.address.street' | translate }}
          </label>
          <input
            nz-input
            nz-tooltip
            [nzTooltipTitle]="
              !hasClientUpdatePermission ? forbiddenMessage : ''
            "
            class="rounded-0"
            autocomplete="off"
            type="text"
            id="street"
            formControlName="street"
            placeholder="{{ 'general.input' | translate }}"
          />
        </div>
      </div>
      <div class="d-flex form-row">
        <div class="form-group input-field mt-2">
          <label class="form-text" for="city">
            {{ 'page.general_settings.address.city' | translate }}
          </label>
          <input
            nz-input
            nz-tooltip
            [nzTooltipTitle]="
              !hasClientUpdatePermission ? forbiddenMessage : ''
            "
            class="rounded-0"
            autocomplete="off"
            type="text"
            id="city"
            formControlName="city"
            placeholder="{{ 'general.input' | translate }}"
          />
        </div>

        <div class="form-group input-field mt-2">
          <label class="form-text" for="postCode">
            {{ 'page.general_settings.address.postal_code' | translate }}
          </label>
          <input
            nz-input
            nz-tooltip
            [nzTooltipTitle]="
              !hasClientUpdatePermission ? forbiddenMessage : ''
            "
            class="rounded-0"
            autocomplete="off"
            [class.is-invalid]="
              (form.controls['postCode'] | formControlInvalid) ||
              form.controls['postCode'].invalid
            "
            type="text"
            id="postCode"
            formControlName="postCode"
            placeholder="{{ 'general.input' | translate }}"
          />
        </div>
      </div>

      <div class="d-flex form-row">
        <div class="form-group input-field mt-2">
          <label class="form-text" for="state">
            {{ 'page.general_settings.address.state' | translate }}
          </label>
          <input
            nz-input
            nz-tooltip
            [nzTooltipTitle]="
              !hasClientUpdatePermission ? forbiddenMessage : ''
            "
            class="rounded-0"
            autocomplete="off"
            [class.is-invalid]="
              (form.controls['state'] | formControlInvalid) ||
              form.controls['state'].invalid
            "
            type="text"
            id="state"
            formControlName="state"
            placeholder="{{ 'general.input' | translate }}"
          />
        </div>

        <div class="form-group input-field mt-2">
          <label class="form-text" for="country">
            {{ 'page.general_settings.address.country' | translate }}
          </label>
          <input
            nz-input
            nz-tooltip
            [nzTooltipTitle]="
              !hasClientUpdatePermission ? forbiddenMessage : ''
            "
            class="rounded-0"
            autocomplete="off"
            [class.is-invalid]="
              (form.controls['country'] | formControlInvalid) ||
              form.controls['country'].invalid
            "
            type="text"
            id="country"
            formControlName="country"
            placeholder="{{ 'general.input' | translate }}"
          />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="form.hasError('addressIncomplete')" class="mt-2">
    <span class="error-message">
      <nz-alert
        nzType="warning"
        [nzMessage]="'page.general_settings.address.error_message' | translate"
      >
      </nz-alert>
    </span>
  </div>
</form>
