<ng-container *ngIf="rule">
  <wilson-shift-categories
    [shiftCategories]="rule.params.shiftCategories"
    customDescription="agreement.rules.FixedShiftCategoryRate.shift_categories.description"
    customLabel="agreement.rules.FixedShiftCategoryRate.shift_categories.label"
  ></wilson-shift-categories>
  <wilson-price-per-unit
    (updatePricePerUnit)="setPricePerUnit($event)"
    [pricePerUnit]="rule.params.pricePerUnit"
    customDescription="agreement.rules.FixedShiftCategoryRate.price_per_unit.description"
    customLabel="agreement.rules.FixedShiftCategoryRate.price_per_unit.label"
  ></wilson-price-per-unit>
</ng-container>
