<wilson-filter-label [label]="showLabel && label">
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="label"
    nzAllowClear
    nzShowSearch
    nzServerSearch
    [ngModel]="selected$ | async"
    (ngModelChange)="saveSelected($event)"
    (nzOnSearch)="onSearch($event)"
    data-test-id="activity-location-filter:select"
  >
    <ng-container *ngFor="let location of filteredOptions$ | async">
      <nz-option
        *ngIf="!isLoading()"
        [nzValue]="location.id"
        [nzLabel]="location.name"
        [attr.data-test-id]="
          'activity-location-filter:select:option:' + location.id
        "
      ></nz-option>
    </ng-container>
    <nz-option *ngIf="isLoading()" nzDisabled nzCustomContent>
      <i nz-icon nzType="loading" class="loading-icon"></i>
      {{ 'general.loading' | translate }}
    </nz-option>
  </nz-select>
</wilson-filter-label>
