<form nz-form [nzLayout]="'vertical'" [formGroup]="agreementsFormService.form">
  <div class="col mb-2">
    <div
      [ngClass]="{
        'text-danger':
          agreementsFormService.form.controls['name'] | formControlInvalid
      }"
      class="form-text"
    >
      {{
        ('general.name' | translate) +
          (!agreementsFormService.form.disabled ? '*' : '')
      }}
    </div>
    <input
      [class.is-invalid]="
        agreementsFormService.form.controls['name'] | formControlInvalid
      "
      class="form-control"
      formControlName="name"
      type="text"
    />
  </div>

  <div class="col mb-2">
    <div class="form-text">
      {{ 'page.invoicing.purchase_order' | translate }}
    </div>
    <input class="form-control" formControlName="purchaseOrder" type="text" />
  </div>

  <div class="col mb-2">
    <div class="form-text">
      {{ 'page.invoicing.company_code' | translate }}
    </div>
    <input class="form-control" formControlName="companyCode" type="text" />
  </div>

  <div class="col mb-1">
    <div class="form-text">{{ 'page.invoicing.currency' | translate }}</div>
    <nz-form-control>
      <nz-select
        [nzPlaceHolder]="'general.input' | translate"
        formControlName="currency"
        nzAllowClear="true"
      >
        <nz-option
          [nzValue]="Currency.EUR"
          [nzLabel]="Currency.EUR + ' (' + CurrencySymbol.EUR + ')'"
        ></nz-option>
        <nz-option
          [nzValue]="Currency.CHF"
          [nzLabel]="Currency.CHF + ' (' + CurrencySymbol.CHF + ')'"
        ></nz-option>
        <nz-option
          [nzValue]="Currency.USD"
          [nzLabel]="Currency.USD + ' (' + CurrencySymbol.USD + ')'"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </div>

  <div class="col mb-1">
    <div class="form-text">{{ 'page.invoicing.vat' | translate }}</div>
    <nz-input-number-group nzAddOnAfter="%" class="w-100">
      <nz-input-number
        formControlName="vat"
        [nzMin]="0"
        [nzMax]="100"
        [nzPlaceHolder]="'general.input' | translate"
      ></nz-input-number>
    </nz-input-number-group>
  </div>

  <div class="col mb-1">
    <div class="form-text">
      {{ 'page.invoicing.advance_payment' | translate }}
    </div>
    <nz-input-group
      [nzAddOnAfter]="CurrencySymbol[selectedCurrency!]"
      class="w-100"
    >
      <input
        class="form-control"
        type="number"
        min="0"
        formControlName="advancePayment"
        nz-input
      />
    </nz-input-group>
  </div>

  <div class="col">
    <div class="form-text">
      {{ 'page.invoicing.cost_center' | translate }}
    </div>
    <textarea
      class="form-control"
      formControlName="costCenter"
      type="text"
    ></textarea>
  </div>

  <div class="col">
    <div class="form-text">
      {{ 'general.description' | translate }}
    </div>
    <textarea
      class="form-control"
      formControlName="description"
      type="text"
    ></textarea>
  </div>

  <div *ngIf="'portal-invoice-rules' | featureFlagPure | async" class="col">
    <div class="form-text">JSON rules</div>
    <textarea
      class="form-control"
      formControlName="rules"
      type="text"
    ></textarea>
  </div>
</form>
