import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { RuleLabelComponent } from '../rule-label/rule-label.component';

@Component({
  selector: 'wilson-round-up-after',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './round-up-after.component.html',
  styleUrl: './round-up-after.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundUpAfterComponent {
  @Input() roundUpAfter: number;
  @Input() customLabel!: string;
  @Input() customDescription!: string;
}
