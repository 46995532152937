<button
  class="more-actions-button"
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  [disabled]="!shift || disabled"
  data-test-id="wilson-more-shift-actions-dropdown:button"
  nz-tooltip
  [nzTooltipTitle]="showIconOnly ? ('general.actions' | translate) : ''"
  nzTooltipPlacement="bottom"
  [nzTrigger]="showIconOnly ? 'click' : 'hover'"
>
  @if(showIconOnly){
  <fa-icon
    data-test-id="wilson-more-shift-actions-dropdown:icon-only"
    [icon]="faEllipsis"
  ></fa-icon>
  }@else{
  <span
    class="me-1"
    data-test-id="wilson-more-shift-actions-dropdown:description-text"
    >{{ 'general.actions' | translate }}</span
  >
  <span
    nz-icon
    nzType="down"
    data-test-id="wilson-more-shift-actions-dropdown:drop-down-icon"
  ></span>
  }
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    @if ('portal-turn-shift-into-dispo-shift' | featureFlagPure | async) {
    <wilson-convert-to-standby-shift-action
      [shift]="shift"
      (updateSuccessful)="convertShiftToStandbySuccessful.next($event)"
    ></wilson-convert-to-standby-shift-action>
    } @if ( ('portal_shift_create_template' | featureFlagPure | async) &&
    (RoleAction.Create | ablePure : RolePermissionSubject.Shift | async) ) {
    <wilson-save-as-template-action
      [shift]="shift"
    ></wilson-save-as-template-action>
    } @if('azm-is-times-to-planned-times' | featureFlagPure | async) {
    <wilson-fill-with-planned-time-action
      [shift]="shift"
      (saveSuccessful)="filledShiftWithPlannedTimeSuccessful.emit()"
    ></wilson-fill-with-planned-time-action>
    } @if((RoleAction.Create | ablePure : RolePermissionSubject.Shift | async)
    && ('portal-duplicate-shift' | featureFlagPure | async) ){
    <wilson-duplicate-shift-action
      [openInNewTab]="duplicateShiftInNewTab"
      [shift]="shift"
    ></wilson-duplicate-shift-action>
    } @if(renderMoveOption){
    <wilson-move-single-shift-action
      [shift]="shift"
      [isMoveAllowed]="isMoveAllowed"
      (moveShiftSuccess)="moveShiftSuccess.emit($event)"
    ></wilson-move-single-shift-action>
    } @if(renderAssignShift) {
    <wilson-assign-single-shift-action
      [shift]="shift"
      [disableAssignShift]="disableAssignShift"
      (assignShiftSuccess)="assignShiftSuccess.emit()"
    ></wilson-assign-single-shift-action>
    } @if (shift) {
    <wilson-export-shift-action [shift]="shift"></wilson-export-shift-action>
    }
    <wilson-delete-shift-action
      [shift]="shift"
      (triggered)="deleteTriggered.emit()"
    ></wilson-delete-shift-action>
  </ul>
</nz-dropdown-menu>
