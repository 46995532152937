import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  GetCurrentBundleResult,
  LiveUpdate,
  SyncResult,
} from '@capawesome/capacitor-live-update';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/capacitor';
import { CaptureContext } from '@sentry/types';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FirstAppLaunchService } from '../../services/first-app-launch.service';
import { DevState } from '../../store/dev/dev.state';
import { DevStateAddLog } from '../../store/dev/dev.action';

@Injectable({
  providedIn: 'root',
})
export class CapacitorLiveUpdateService {
  public syncResult$: ReplaySubject<SyncResult> = new ReplaySubject<SyncResult>(
    1,
  );

  constructor(
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly firstAppLaunchService: FirstAppLaunchService,
    private readonly store: Store,
  ) {}

  public async initLiveUpdates() {
    this.store.dispatch(
      new DevStateAddLog({ level: 'info', message: 'InitLiveUpdates' }),
    );
    try {
      const featureFlagLiveUpdatesEnabled = await firstValueFrom(
        this.featureFlagPurePipe.transform('mobile-enable-live-updates'),
      );
      const featureFlagLiveUpdatesReloadOnFirstStart = await firstValueFrom(
        this.featureFlagPurePipe.transform(
          'mobile-live-updates-reload-on-first-start',
        ),
      );
      this.store.dispatch(
        new DevStateAddLog({
          level: 'info',
          message: 'FF - LiveUpdatesEnabled',
          extra: featureFlagLiveUpdatesEnabled,
        }),
      );
      this.store.dispatch(
        new DevStateAddLog({
          level: 'info',
          message: 'FF - LiveUpdatesReloadOnFirstStart',
          extra: featureFlagLiveUpdatesReloadOnFirstStart,
        }),
      );

      if (Capacitor.isNativePlatform()) {
        const isFirstLaunch: boolean =
          await this.firstAppLaunchService.isFirstLaunch();
        this.store.dispatch(
          new DevStateAddLog({
            level: 'info',
            message: 'isFirstLaunch',
            extra: isFirstLaunch,
          }),
        );

        if (
          featureFlagLiveUpdatesEnabled &&
          this.store.selectSnapshot(DevState.liveUpdatesActive)
        ) {
          await LiveUpdate.ready();
          this.store.dispatch(
            new DevStateAddLog({
              level: 'info',
              message: 'LiveUpdate ready() called',
            }),
          );
          await this.assignChannel();

          const res = await LiveUpdate.sync();
          this.syncResult$.next(res);
          this.store.dispatch(
            new DevStateAddLog({
              level: 'info',
              message: 'sync() result',
              extra: res,
            }),
          );

          const currentBundle: GetCurrentBundleResult =
            await LiveUpdate.getCurrentBundle();
          this.store.dispatch(
            new DevStateAddLog({
              level: 'info',
              message: 'current bundle',
              extra: currentBundle,
            }),
          );

          if (
            isFirstLaunch &&
            currentBundle.bundleId === null &&
            featureFlagLiveUpdatesReloadOnFirstStart
          ) {
            this.store.dispatch(
              new DevStateAddLog({
                level: 'info',
                message: 'Applying immediate reload()',
              }),
            );
            await LiveUpdate.reload();
          }
        }

        if (isFirstLaunch) {
          await this.firstAppLaunchService.setHasLaunched();
          this.store.dispatch(
            new DevStateAddLog({
              level: 'info',
              message: 'setting firstLaunched to true',
            }),
          );
        }
      }
    } catch (error) {
      const errorMessage = 'LiveUpdate - initialization failed';
      console.error(errorMessage, error);
      const hint: CaptureContext = {
        level: 'error',
        tags: {
          area: 'LiveUpdate: initLiveUpdates',
        },
      };
      Sentry.captureException(error, hint);
      this.store.dispatch(
        new DevStateAddLog({
          level: 'error',
          message: errorMessage,
          extra: error,
        }),
      );
    }
  }

  public async resetLiveUpdates() {
    if (Capacitor.isNativePlatform()) {
      await LiveUpdate.setChannel({ channel: null });
      await LiveUpdate.reset();
      await LiveUpdate.reload();
    }
  }

  public async checkAndSetLatestBundle() {
    const res = await LiveUpdate.sync();
    if (res) {
      await LiveUpdate.reload();
    }
  }

  private async assignChannel(): Promise<void> {
    this.store.dispatch(
      new DevStateAddLog({
        level: 'info',
        message: 'assigning Channel',
      }),
    );
    const currentChannel = await LiveUpdate.getChannel();
    this.store.dispatch(
      new DevStateAddLog({
        level: 'info',
        message: 'current channel',
        extra: currentChannel,
      }),
    );
    let targetChannel!: string | null;
    if (environment.sentry.environment === 'prod') {
      const appInfo: AppInfo = await App.getInfo();
      targetChannel = `${environment.sentry.environment}-${appInfo.version}`;
      if (currentChannel.channel !== targetChannel) {
        await LiveUpdate.setChannel({
          channel: targetChannel,
        });
      }
    } else {
      targetChannel = null;
      if (currentChannel.channel !== targetChannel) {
        await LiveUpdate.setChannel({ channel: null });
      }
    }
    this.store.dispatch(
      new DevStateAddLog({
        level: 'info',
        message: 'target channel',
        extra: targetChannel,
      }),
    );
  }
}
