import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
interface ChangeNoteModalInputData {
  clientNotes: string;
}

@Component({
  selector: 'wilson-external-user-change-note-modal',
  templateUrl: './change-note-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeExternalUserNoteModalComponent {
  noteForm: FormGroup = this.fb.group({
    note: [this.data.clientNotes.trim()],
  });

  constructor(
    @Inject(NZ_MODAL_DATA) private data: ChangeNoteModalInputData,
    private readonly fb: FormBuilder,
  ) {}
}
