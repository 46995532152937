<ng-container *ngIf="status">
  <ng-container *ngIf="status === SharedUserApprovalStatus.APPROVED">
    <fa-icon
      [icon]="faIcons.faCheckCircle"
      [class]="'option--' + status + '--icon'"
    ></fa-icon>
  </ng-container>

  <ng-container *ngIf="status === SharedUserApprovalStatus.DECLINED">
    <fa-icon
      [icon]="faIcons.faTimesCircle"
      [class]="'option--' + status + '--icon'"
    ></fa-icon>
  </ng-container>

  <ng-container
    *ngIf="
      status === SharedUserApprovalStatus.IN_REVIEW ||
      (status === SharedUserApprovalStatus.OPEN && providerView)
    "
  >
    <fa-icon
      [icon]="faIcons.faArrowCircleRight"
      [class]="'option--' + status + '--icon'"
    ></fa-icon>
  </ng-container>

  <ng-container *ngIf="!providerView; else providerViewLabel">
    <ng-container *ngTemplateOutlet="defaultViewLabel"></ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultViewLabel>
  <span [class]="'option--' + status">{{
    ' ' + ('client.shared_with_me.approval_status.' + status | translate)
  }}</span>
</ng-template>

<ng-template #providerViewLabel>
  <ng-container
    *ngIf="
      status === SharedUserApprovalStatus.OPEN ||
        status === SharedUserApprovalStatus.IN_REVIEW;
      else defaultViewLabel
    "
  >
    <span>{{
      ' ' +
        ('client.shared_with_me.approval_status.custom.approval_pending'
          | translate)
    }}</span>
  </ng-container>
</ng-template>
