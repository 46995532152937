import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wilson-filter-label',
  template: `
    <ng-content></ng-content>
    <span *ngIf="label" class="label"> {{ label }} </span>
  `,
  styleUrls: ['./filter-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class FilterLabelComponent {
  @Input()
  label!: string;
}
