@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ;
<li
  nz-menu-item
  [nzDisabled]="!isMoveAllowed || !hasShiftUpdatePermissions"
  nz-tooltip
  [nzTooltipTitle]="
    !hasShiftUpdatePermissions
      ? ('general.forbidden_action' | translate)
      : !isMoveAllowed
      ? ('shift.page.move_shift_not_allowed' | translate)
      : ''
  "
  nzTooltipPlacement="left"
  (click)="isMoveAllowed && triggerDurationSelectionModal()"
  data-test-id="wilson-move-single-shift-action:button"
>
  {{ 'shift.page.move_selected' | translate }}
</li>
