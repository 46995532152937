<div>
  <nz-select
    [nzPlaceHolder]="'page.shifts.publishing_state' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="release-status-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name | translate"
      [nzValue]="item.id"
      [attr.data-test-id]="'release-status-filter:select:option:' + item.id"
    ></nz-option>
  </nz-select>
</div>
