import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  LinkRendererComponent,
  UserAvatarRendererComponent,
} from '@wilson/ag-grid/renderers';
import { GridService } from '@wilson/ag-grid/service';
import { Client } from '@wilson/clients/interfaces';
import { ExternalUser, ExternalUsersService } from '@wilson/clients/services';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  FeatureName,
  LinkRendererParams,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import {
  ColDef,
  GridApi,
  GridOptions,
  ValueGetterParams,
} from 'ag-grid-community';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  firstValueFrom,
  of,
  switchMap,
} from 'rxjs';
import { approvedAtComparator } from '../provider-share-users-list/user-approved-at-renderer/user-approved-at-comparator';
import { UserApprovedAtRendererComponent } from '../provider-share-users-list/user-approved-at-renderer/user-approved-at-renderer.component';
import { sharedAtComparator } from '../provider-share-users-list/user-shared-at-renderer/user-shared-at-comparator';
import { UserSharedAtRendererComponent } from '../provider-share-users-list/user-shared-at-renderer/user-shared-at-renderer.component';
import { approvalStatusComparator } from '../shared-user-approval/shared-user-approval-comparator/shared-user-approval-comparator';
import { ExternalCompanyUsersListService } from './external-company-users-service/external-company-users.service';
import { SetApprovalRendererComponent } from './renderers/set-approval-renderer.component';
import { SetProfessionsRendererComponent } from './renderers/set-professions-renderer.component';
import { UserNoteRendererRendererComponent } from './renderers/user-note-renderer.component';
@Component({
  selector: 'wilson-external-company-users',
  templateUrl: './external-company-users.component.html',
  styleUrls: ['./external-company-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService, ReactiveFormsModule],
})
export class ExternalCompanyUsersComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  public users$: Observable<ExternalUser[]>;
  private gridApi?: GridApi;
  private readonly refresh$ = new BehaviorSubject<Date>(new Date());
  private updateSubscription: Subscription;
  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions: GridOptions = {
    ...defaultGridOptionsFactory(),
    onGridReady: (params) => {
      this.gridApi = params.api;
      this.setUserNameRendererParams();
    },
    getRowId: (params) => params.data.id,
    suppressModelUpdateAfterUpdateTransaction: true,
  };
  public readonly components = {
    linkRendererComponent: LinkRendererComponent,
    setApprovalRendererComponent: SetApprovalRendererComponent,
    userApprovedAtRendererComponent: UserApprovedAtRendererComponent,
    userAvatarRenderer: UserAvatarRendererComponent,
    userNoteRendererRendererComponent: UserNoteRendererRendererComponent,
    userSharedAtRendererComponent: UserSharedAtRendererComponent,
    setProfessionsRendererComponent: SetProfessionsRendererComponent,
  };
  public readonly comparators = {
    approvalStatusComparator,
    approvedAtComparator,
    sharedAtComparator,
  };

  public readonly userNameRendererParams: LinkRendererParams<ExternalUser> = {
    link: {
      link: (entity) =>
        entity.user.firstName
          ? `/settings/shared-user-qualifications/${entity.id}`
          : null,
    },
  };
  public readonly userNameRendererParamsQualificationV2: LinkRendererParams<ExternalUser> =
    {
      link: {
        link: (entity) =>
          entity.userId ? `/qualification-management/v2/shared` : null,
        params: (entity) =>
          entity.userId ? { sharedUsers: entity.userId } : null,
      },
    };
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  protected columnDefs: ColDef[] = [
    {
      resizable: false,
      sortable: false,
      width: 75,
      cellRenderer: 'userAvatarRenderer',
      field: 'profileImageUrl',
      headerName: '',
    },
    {
      flex: 1.5,
      headerName: this.translateService.instant(
        'page.settings.client.employee',
      ),
      resizable: true,
      sortable: true,
      valueGetter: this.externalEmployeeValueGetter,
      cellRenderer: 'linkRendererComponent',
      cellRendererParams: this.userNameRendererParams,
      filter: 'agTextColumnFilter',
      sort: 'asc',
      field: 'externalUserLink',
    },
    {
      flex: 1,
      comparator: this.comparators.sharedAtComparator,
      headerName: this.translateService.instant(
        'page.settings.client.shared_on',
      ),
      resizable: true,
      sortable: true,
      valueGetter: this.sharedAtValueGetter,
      cellRenderer: 'userSharedAtRendererComponent',
    },
    {
      flex: 4,
      headerName: this.translateService.instant(
        'page.settings.client.professions',
      ),
      resizable: true,
      sortable: false,
      valueGetter: this.professionValueGetter,
      cellRenderer: 'setProfessionsRendererComponent',
    },
    {
      flex: 1.5,
      comparator: this.comparators.approvalStatusComparator,
      headerName: this.translateService.instant(
        'page.settings.client.approval',
      ),
      resizable: true,
      sortable: true,
      valueGetter: this.approvedValueGetter,
      cellRenderer: 'setApprovalRendererComponent',
    },
    {
      flex: 1,
      comparator: this.comparators.approvedAtComparator,
      headerName: this.translateService.instant(
        'page.settings.client.approved_on',
      ),
      resizable: true,
      sortable: true,
      valueGetter: this.approvedAtValueGetter,
      cellRenderer: 'userApprovedAtRendererComponent',
    },
    {
      flex: 2,
      headerName: this.translateService.instant(
        'page.settings.client.further_information',
      ),
      resizable: true,
      sortable: false,
      wrapText: true,
      cellRenderer: 'userNoteRendererRendererComponent',
      field: 'clientNotes',
    },
  ];
  constructor(
    public readonly gridService: GridService<Client>,
    private readonly externalUsersService: ExternalUsersService,
    private readonly externalCompanyUsersListService: ExternalCompanyUsersListService,
    private readonly nzNotificationService: NzNotificationService,
    private readonly translateService: TranslateService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  ngOnInit(): void {
    this.setUserNameRendererParams();

    this.users$ = this.refresh$.pipe(
      switchMap(() =>
        this.externalUsersService
          .getUser(this.client.clientPartnership?.partnershipId)
          .pipe(
            catchError(() => {
              return of([]);
            }),
            this.gridService.finalizeLoad(),
          ),
      ),
    );

    this.updateSubscription =
      this.externalCompanyUsersListService.updateSubject$.subscribe(
        ({ result, cellRendererParams, success }) => {
          if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(
              cellRendererParams.node.id as string,
            );
            if (rowNode) {
              const transaction = { update: [result] };
              this.gridApi.applyTransaction(transaction);
              this.gridApi.redrawRows({ rowNodes: [rowNode] });
            }
          }
          if (!success) {
            this.nzNotificationService.error(
              this.translateService.instant('general.error'),
              this.translateService.instant('general.something_went_wrong'),
            );
          }
        },
      );
  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

  externalEmployeeValueGetter(params: ValueGetterParams) {
    const { firstName, lastName } = params.data.user;
    return params.data.user ? `${lastName}, ${firstName}` : 'n/a';
  }

  approvedAtValueGetter(params: ValueGetterParams) {
    return params.data;
  }

  approvedValueGetter(params: ValueGetterParams) {
    return params.data;
  }

  professionValueGetter(params: ValueGetterParams) {
    return params.data.profession ? params.data.approval : 'n/a';
  }

  sharedAtValueGetter(params: ValueGetterParams) {
    return params.data;
  }

  private async setUserNameRendererParams() {
    const featureFlag = await firstValueFrom(
      this.featureFlagPurePipe.transform('portal-mobile-qualifications-v-2'),
    );
    if (featureFlag && this.gridApi) {
      const colDefs = this.gridApi?.getColumnDefs() as ColDef<any>[];
      if (colDefs) {
        const indexToModify = colDefs?.findIndex(
          (colDef) => colDef.field === 'externalUserLink',
        );
        if (indexToModify) {
          colDefs[indexToModify].cellRendererParams =
            this.userNameRendererParamsQualificationV2;
          this.gridApi?.setGridOption('columnDefs', colDefs);
        }
      }
    }
  }
}
