<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'page.shifts.shift_category_placeholder' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="shift-category-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name | translate"
      [nzValue]="item.id"
      nzCustomContent
      [attr.data-test-id]="'shift-category-filter:select:option:' + item.id"
    >
      <div class="category-container">
        <div
          class="dot"
          [style.background-color]="shiftCategoriesColorCode[item.id]"
        ></div>
        <div>
          {{ item.name | translate }}
        </div>
      </div>
    </nz-option>
  </nz-select>
</div>
