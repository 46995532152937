import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileExclamation } from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-invoice-edit-again',
  templateUrl: './invoice-edit-again.component.html',
  styleUrls: ['./invoice-edit-again.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
})
export class InvoiceEditAgainComponent {
  faFileExclamation = faFileExclamation;
}
