import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Activity,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { DuplicateShiftActionService } from './duplicate-shift-action.service';
import { AuthorizationModule } from '@wilson/authorization';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-duplicate-shift-action',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzDropDownModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './duplicate-shift-action.component.html',
  styleUrl: './duplicate-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateShiftActionComponent {
  @Input({
    required: true,
  })
  shift!: Shift & { activities: Activity[] };

  @Input()
  openInNewTab = false;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    private readonly duplicateShiftActionService: DuplicateShiftActionService,
  ) {}

  duplicateShift(): void {
    this.duplicateShiftActionService.prepareDuplicateShiftDataAndRedirect(
      this.shift,
      this.openInNewTab,
    );
  }
}
