import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-partner-renderer',
  template: ` <div
      class="partner"
      *ngIf="partnershipId && partnerName; else notPartner"
    >
      {{ partnerName }}
    </div>
    <ng-template #notPartner>
      <div class="no-partner">{{ 'general.n/a' | translate }}</div>
    </ng-template>`,
  styles: [
    `
      .no-partner {
        color: var(--grey-6);
      }
      .partner {
        color: var(--dark-base);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerRendererComponent implements ICellRendererAngularComp {
  partnershipId: string;
  partnerName: string;

  agInit(params: ICellRendererParams): void {
    this.assignValuesToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.assignValuesToDisplay(params);
    return true;
  }

  assignValuesToDisplay(params: ICellRendererParams) {
    this.partnershipId = params.data.clientPartnership?.id;
    this.partnerName = params.data.clientPartnership?.partnerName;
  }
}
