import { BillingRule } from './billing-rules';

export interface BillingRuleActivityByCategoryFlatRate extends BillingRule {
  calculatorName: 'ActivityByCategoryFlatRate';
  params: {
    pricePerUnit: number;
    activityCategories: string[];
  };
}

export function isActivityByCategoryFlatRate(
  obj: BillingRule,
): obj is BillingRuleActivityByCategoryFlatRate {
  return obj.calculatorName === 'ActivityByCategoryFlatRate';
}
