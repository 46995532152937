import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FilterKey } from './filter.keys.type';
import { FilterService } from './filter.service';
import { ParserService } from './services/parser.service';

@Directive()
export abstract class BaseFilterDirective<T> implements OnInit {
  @Output() modelChange = new EventEmitter<T>();
  @Input() nullIfEmpty = false;
  public selected$!: Observable<T>;

  protected abstract readonly filterService: FilterService;
  protected abstract readonly parserService: ParserService<T>;

  protected constructor(
    protected readonly queryParamField: FilterKey | Observable<FilterKey>,
  ) {}

  private queryParam$!: Observable<FilterKey>;

  ngOnInit(): void {
    this.queryParam$ =
      typeof this.queryParamField === 'string'
        ? of(this.queryParamField)
        : this.queryParamField;
    this.selected$ = this.initializeSelected();
  }

  protected initializeSelected(): Observable<T> {
    return this.queryParam$.pipe(
      switchMap((queryParam) =>
        this.filterService.getValueFromQueryParam(queryParam).pipe(
          map((params) =>
            this.parserService.unparse(params ?? '', this.nullIfEmpty),
          ),
          tap((selected) => this.modelChange.emit(selected)),
        ),
      ),
    );
  }

  public saveSelected(selected: T): Promise<boolean> {
    this.modelChange.emit(selected);
    return firstValueFrom(
      this.queryParam$.pipe(
        switchMap((queryParam) =>
          this.filterService.saveValueInQueryParam(
            queryParam,
            this.parserService.parse(selected),
          ),
        ),
      ),
    );
  }
}
