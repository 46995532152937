import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-user-approved-at-renderer',
  template: `
    <span *ngIf="approvedAt; else noApprovedAt">
      {{ approvedAt | date : 'dd.MM.yyyy' }}
    </span>
    <ng-template #noApprovedAt>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserApprovedAtRendererComponent
  implements ICellRendererAngularComp
{
  approvedAt: string;

  agInit(params: ICellRendererParams): void {
    this.approvedAt = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.approvedAt = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    // shared-by-me || shared-with-me
    return params.data?.sharedUsers?.[0]?.approvedAt || params.data?.approvedAt;
  }
}
