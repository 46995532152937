import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-user-shared-on-renderer',
  template: `
    <span *ngIf="sharedAt; else noSharedAt">
      {{ sharedAt | date : 'dd.MM.yyyy' }}
    </span>
    <ng-template #noSharedAt>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSharedAtRendererComponent implements ICellRendererAngularComp {
  sharedAt: string;

  agInit(params: ICellRendererParams): void {
    this.sharedAt = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.sharedAt = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    // shared-by-me || shared-with-me
    return params.data?.sharedUsers?.[0]?.sharedAt || params.data?.sharedAt;
  }
}
