import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientsV2Service } from '@wilson/clients/services';
import { Observable, map } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showTitle = true;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly clientsService: ClientsV2Service,
  ) {
    super('clients');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.clientsService.getMany({ limit: 0 }).pipe(
      map((clients) => {
        return clients.data.map((client) => {
          return {
            id: (client.id as string).toString(),
            name: client.name.toString(),
          };
        });
      }),
    );
  }
}
