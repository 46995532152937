import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  StateOperator,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  append,
  compose,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import * as Sentry from '@sentry/capacitor';
import {
  activitiesCompareFn,
  activityReportsCompareFn,
} from '@wilson/activities/util';
import { AuthState, AuthStateModel } from '@wilson/auth/core';
import { FeatureFlagNumberPipe } from '@wilson/feature-flags';
import {
  ALL_SHIFT_RELATIONS,
  ActivityComment,
  OperationStatus,
  PublicationStatus,
  ResolvedActivityWithReports,
  ResolvedShiftWithReports,
  ShiftState,
} from '@wilson/interfaces';
import { ShiftUtilityService, ShiftsService } from '@wilson/shifts';
import { shiftsCompareFn } from '@wilson/shifts/src/shifts-util';
import { deepClone } from '@wilson/utils';
import {
  addDays,
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
  subDays,
} from 'date-fns';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  firstValueFrom,
  of,
  timer,
} from 'rxjs';
import { catchError, filter, first, map, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { EndLiveShiftStateService } from '../end-live-shift-state.service';
import { NewStartPauseLiveShiftStateOperatorFactory } from '../new-start-pause-live-shift-state-operator.factory';
import { NextLiveShiftActivityStateService } from '../next-live-shift-activity-state.service';
import { ShiftPostSyncOperatorFactory } from '../shift-post-sync-operator.factory';
import { ShiftSyncService } from '../shift-sync.service';
import { StartLiveShiftStateService } from '../start-live-shift-state.service';
import { StartPauseActivityStateService } from '../start-pause-activity-state.service';
import { sortActivitiesForShift, sortBy } from '../utils/custom-operators';
import {
  determinePartialHydrationRanges,
  shouldSkipHydration,
} from './history/eval-skip-hydration';
import { updateHydrationHistory } from './history/update-hydration-history';
import { ResolvedShiftActions } from './resolved-shifts.action';

export interface ResolvedShiftsStateModel {
  activeShiftId: string | null;
  activeActivityId: string | null;
  shifts: ResolvedShiftWithSyncStatus[];
  history: ResolvedShiftsStateHistory;
}

export interface ResolvedShiftsStateHistory {
  hydration: HydrationHistory[];
}

export interface HydrationHistory {
  startDate: string;
  endDate: string;
  shiftIds: string[];
  date: string;
}

export interface ResolvedShiftWithSyncStatus extends ResolvedShiftWithReports {
  syncedAt?: string | null;
  delete?: boolean;
}

export const RESOLVED_SHIFTS_STATE_NAME = 'resolved_shifts';
export const SHIFTS_RANGE_PAST_FACTORY = () =>
  subDays(startOfDay(new Date()), 31);
export const SHIFTS_RANGE_FUTURE_FACTORY = () =>
  addDays(endOfDay(new Date()), 7);

@State<ResolvedShiftsStateModel>({
  name: RESOLVED_SHIFTS_STATE_NAME,
  defaults: {
    shifts: [],
    activeShiftId: null,
    activeActivityId: null,
    history: {
      hydration: [],
    },
  },
})
@Injectable()
export class ResolvedShiftsState implements NgxsAfterBootstrap {
  public static readonly SYNC_TIMER = 20 * 1000;
  private shiftSyncSubscriptions: Record<string, Subscription> = {};

  private syncingShiftsDataSubject = new BehaviorSubject<boolean>(false);
  readonly syncingShiftsData$ = this.syncingShiftsDataSubject.asObservable();

  constructor(
    private readonly store: Store,
    private readonly shiftsService: ShiftsService,
    private readonly storage: Storage,
    private readonly startLiveShiftStateService: StartLiveShiftStateService,
    private readonly endLiveShiftStateService: EndLiveShiftStateService,
    private readonly nextLiveShiftActivityStateService: NextLiveShiftActivityStateService,
    private readonly startPauseActivityStateService: StartPauseActivityStateService,
    private readonly shiftSyncService: ShiftSyncService,
    private readonly shiftUtilityService: ShiftUtilityService,
    private readonly ngZone: NgZone,
    private readonly shiftPostSyncOperatorFactory: ShiftPostSyncOperatorFactory,
    private readonly newStartPauseLiveShiftStateOperatorFactory: NewStartPauseLiveShiftStateOperatorFactory,
    private readonly featureFlagNumberPipe: FeatureFlagNumberPipe,
  ) {}

  @Selector()
  static shiftsInDateRangeSelector(state: ResolvedShiftsStateModel) {
    return (start: Date, end: Date, userId: string) => {
      const shiftsFromState = state.shifts.filter(
        (s) =>
          !s.delete &&
          s.userId === userId &&
          isWithinInterval(new Date(s.startDate), {
            start,
            end,
          }) &&
          s.publicationStatus !== PublicationStatus.NotPublished &&
          s.publicationStatus !== PublicationStatus.NotPublishedAgain,
      );

      const shifts: ResolvedShiftWithSyncStatus[] = [];
      shiftsFromState.forEach((shift) => {
        const clonedShift = { ...shift };
        clonedShift.activities = clonedShift?.activities?.filter(
          (a) => !a.deletedAt,
        );
        shifts.push(clonedShift);
      });

      return shifts;
    };
  }

  @Selector()
  static liveShiftId(state: ResolvedShiftsStateModel): string | null {
    return state.activeShiftId;
  }

  @Selector()
  static shifts(state: ResolvedShiftsStateModel) {
    return state.shifts;
  }

  static shiftByIdStream(shiftId: string) {
    return createSelector(
      [ResolvedShiftsState.shifts],
      (shifts: ResolvedShiftWithSyncStatus[]) => {
        const shiftFromState = shifts.find(
          (s) => s.id === shiftId && !s.delete,
        );

        if (shiftFromState) {
          const shift = {
            ...shiftFromState,
          };
          shift.activities = shift?.activities?.filter((a) => !a.deletedAt);
          return shift;
        } else {
          return undefined;
        }
      },
    );
  }

  @Selector()
  static shiftById(state: ResolvedShiftsStateModel) {
    return (shiftId: string) => {
      const shiftFromState = state.shifts.find(
        (s) => s.id === shiftId && !s.delete,
      );
      if (!shiftFromState) return undefined;

      const shift = {
        ...shiftFromState,
      };
      shift.activities = shift?.activities?.filter((a) => !a.deletedAt);
      return shift;
    };
  }

  @Selector()
  static liveShift(
    state: ResolvedShiftsStateModel,
  ): ResolvedShiftWithSyncStatus | undefined {
    const shiftFromState = state.activeShiftId
      ? state.shifts?.find(
          (s) =>
            s.id === state.activeShiftId &&
            !s.delete &&
            (s.declinedAt === null || s.declinedAt === undefined) &&
            s.publicationStatus !== PublicationStatus.NotPublished &&
            s.publicationStatus !== PublicationStatus.NotPublishedAgain,
        )
      : undefined;
    if (!shiftFromState) return undefined;

    const shift = {
      ...shiftFromState,
    };
    shift.activities = shift?.activities?.filter((a) => !a.deletedAt);

    return shift;
  }

  @Selector()
  static ongoingLiveShiftActivity(
    state: ResolvedShiftsStateModel,
  ): ResolvedActivityWithReports | undefined {
    return ResolvedShiftsState.liveShift(state)?.activities.find(
      (activity) => activity.operationalStatus === OperationStatus.Ongoing,
    );
  }

  @Selector()
  static commentsByShiftAndActivityId(state: ResolvedShiftsStateModel) {
    return (shiftId: string, activityId: string) => {
      const shift = state.shifts.find((s) => s.id === shiftId && !s.delete);
      if (!shift) return undefined;

      const activity = shift?.activities?.find((a) => a.id === activityId);
      return activity?.activityComments || [];
    };
  }

  @Selector()
  static latestCommentByShiftAndActivityId(state: ResolvedShiftsStateModel) {
    return (shiftId: string, activityId: string) => {
      const shift = state.shifts.find((s) => s.id === shiftId && !s.delete);
      if (!shift) return undefined;

      const activity = shift?.activities?.find((a) => a.id === activityId);
      const clonedActivities = activity?.activityComments
        ? deepClone(activity?.activityComments)?.sort((a, b) =>
            (b.createdAt as string).localeCompare(a.createdAt as string),
          )
        : undefined;
      if (!clonedActivities) return;
      return activity?.activityComments?.find(
        (c) => c.id === clonedActivities[0].id,
      );
    };
  }

  @Selector()
  static getShiftInReportedTimeRange(state: ResolvedShiftsStateModel) {
    return (start: Date, end: Date, currentShiftId: string) => {
      const activitiesInShifts = state.shifts
        .filter((s) => !s.delete)
        .flatMap((s) => s.activities)
        .filter((a) => a && a.shiftId !== currentShiftId);
      const reportsInShifts = activitiesInShifts
        .flatMap((a) => a.activityReports)
        .filter((r) => r);
      const reportInInterval = reportsInShifts.find((r) =>
        isWithinInterval(new Date(r.dateTime), {
          start,
          end,
        }),
      );
      return reportInInterval;
    };
  }

  @Selector()
  static getShiftInTimeRange(state: ResolvedShiftsStateModel) {
    return (start: Date, end: Date, currentShiftId: string) => {
      const activitiesInShifts = state.shifts
        .filter((s) => !s.delete)
        .flatMap((s) => s.activities)
        .filter((a) => a && a.shiftId !== currentShiftId);

      const activityInInterval = activitiesInShifts.find(
        (a) =>
          isWithinInterval(new Date(a.startDatetime), {
            start,
            end,
          }) ||
          isWithinInterval(new Date(a.endDatetime), {
            start,
            end,
          }) ||
          (isBefore(new Date(a.startDatetime), start) &&
            isAfter(new Date(a.endDatetime), end)),
      );
      return state.shifts.find(({ id }) => id === activityInInterval?.shiftId);
    };
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<ResolvedShiftsStateModel>,
  ): Promise<void> {
    const shifts = await this.storage.get(RESOLVED_SHIFTS_STATE_NAME);
    if (shifts) ctx.patchState(shifts);

    this.setupSyncTimerForAllUnsychronizedShifts(ctx);
  }

  @Action(ResolvedShiftActions.SetupSyncForAllUnsychronizedShifts)
  startOneTimeSyncForAllUnsychronizedShifts(
    ctx: StateContext<ResolvedShiftsStateModel>,
  ): Promise<void[]> {
    const shiftsToSync = ctx.getState().shifts.filter((s) => !s.syncedAt);

    return Promise.all(
      shiftsToSync.map((s) =>
        this.sendShiftToTheBackendForSynchronization(ctx, s),
      ),
    );
  }

  @Action(ResolvedShiftActions.ClearShiftsState)
  clearShiftsState(
    ctx: StateContext<ResolvedShiftsStateModel>,
  ): Promise<void[]> {
    ctx.patchState({
      activeActivityId: null,
      activeShiftId: null,
      shifts: [],
    });
    return this.updateStorage(ctx);
  }

  setupSyncTimerForAllUnsychronizedShifts(
    ctx: StateContext<ResolvedShiftsStateModel>,
  ) {
    const shiftsToSync = ctx.getState().shifts.filter((s) => !s.syncedAt);

    shiftsToSync.forEach((s) => {
      this.setupSyncForShift(ctx, s.id as string);
    });
  }

  private setupSyncForShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    shiftId: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ngZone.runOutsideAngular(() => {
        if (this.shiftSyncSubscriptions[shiftId])
          this.unsubscribeAndDeleteSyncSubscription(shiftId);

        this.shiftSyncSubscriptions[shiftId] = timer(
          0,
          ResolvedShiftsState.SYNC_TIMER,
        ).subscribe(() => {
          this.ngZone.run(() => {
            const shift = ctx
              .getState()
              .shifts.find((s) => s.id === shiftId && !s.syncedAt);
            if (!shift) {
              this.unsubscribeAndDeleteSyncSubscription(shiftId);
              resolve();
              return;
            }

            this.sendShiftToTheBackendForSynchronization(ctx, shift)
              .then(() => {
                this.unsubscribeAndDeleteSyncSubscription(shiftId);
                resolve();
              })
              .catch(() => {
                console.log(`Catching a failed sync attempt for ${shiftId}`);
                reject();
              });
          });
        });
      });
    });
  }

  private async sendShiftToTheBackendForSynchronization(
    ctx: StateContext<ResolvedShiftsStateModel>,
    shift: ResolvedShiftWithSyncStatus,
  ): Promise<void> {
    return this.shiftSyncService.updateShiftFromMobile(shift).then(() => {
      ctx.setState(
        this.shiftPostSyncOperatorFactory.createPostSyncOperation(shift),
      );
      this.updateStorage(ctx);
    });
  }

  private unsubscribeAndDeleteSyncSubscription(shiftId: string) {
    this.shiftSyncSubscriptions[shiftId]?.unsubscribe();
    delete this.shiftSyncSubscriptions[shiftId];
  }

  @Action(ResolvedShiftActions.SmartHydrate)
  async smartHydrateShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.SmartHydrate,
  ) {
    const skipIfLastHydratedWithinSeconds = await firstValueFrom(
      this.featureFlagNumberPipe.transform(
        'mobile-smart-hydrate-cache-duration',
      ),
    );
    const startDate = action.range?.startDate || SHIFTS_RANGE_PAST_FACTORY();
    const endDate = action.range?.endDate || SHIFTS_RANGE_FUTURE_FACTORY();
    const skipHydration = shouldSkipHydration(
      ctx,
      skipIfLastHydratedWithinSeconds,
      startDate,
      endDate,
    );
    if (!skipHydration) {
      const partialHydrationRanges = determinePartialHydrationRanges(
        ctx,
        skipIfLastHydratedWithinSeconds,
        startDate,
        endDate,
      );
      if (partialHydrationRanges?.length) {
        const hydrateActions = partialHydrationRanges.map(
          (range) => new ResolvedShiftActions.HydrateState(range),
        );
        return ctx.dispatch(hydrateActions);
      }
    }
    return of();
  }

  @Action(ResolvedShiftActions.HydrateState)
  hydrateShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.HydrateState,
  ): Observable<ResolvedShiftWithSyncStatus[]> {
    const userId = this.store.selectSnapshot<AuthStateModel['userId']>(
      AuthState.userId,
    );
    if (!userId) return of([]);

    this.syncingShiftsDataSubject.next(true);

    const startDate = action.range?.startDate || SHIFTS_RANGE_PAST_FACTORY();
    const endDate = action.range?.endDate || SHIFTS_RANGE_FUTURE_FACTORY();

    return this.shiftsService
      .getByUserInDateRange(
        {
          userId: userId,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        ALL_SHIFT_RELATIONS,
      )
      .pipe(
        first(),
        map((shiftsFromServer) => {
          shiftsFromServer = shiftsFromServer.filter(
            (shift) => shift.declinedAt === null,
          );

          shiftsFromServer = shiftsFromServer.filter((shift) => {
            if (!shift.activities[0]) {
              Sentry.captureMessage('shift without activities', {
                level: 'warning',
                tags: {
                  shiftId: shift.id,
                },
              });
              return false;
            } else {
              return true;
            }
          });
          shiftsFromServer.forEach((shift) => {
            shift.activities.sort(activitiesCompareFn);
            shift.activities.forEach((activity) => {
              activity.activityReports.sort(activityReportsCompareFn);
            });
          });
          return shiftsFromServer.sort(shiftsCompareFn);
        }),
        tap((shiftsFromServer) => {
          const shiftsFromState = ctx.getState().shifts;

          const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

          // add or update shifts in state with new updates from server
          shiftsFromServer.forEach((shift) => {
            const currentShift = shiftsFromState?.find(
              (s) => s?.id === shift.id,
            );
            if (currentShift) {
              stateOperators.push(
                ...this.shiftSyncService.updateShiftFromServer(
                  shift,
                  currentShift,
                ),
              );
            } else {
              stateOperators.push(
                patch<ResolvedShiftsStateModel>({
                  shifts: insertItem<ResolvedShiftWithSyncStatus>({
                    ...shift,
                    syncedAt: new Date().toISOString(),
                  }),
                }),
              );
            }
          });

          // clean up shifts in state with fresh information from the server
          stateOperators.push(
            ...this.shiftSyncService.cleanUpShiftStateWithinInterval(
              shiftsFromServer,
              shiftsFromState,
              userId,
              { start: startDate, end: endDate },
            ),
          );

          stateOperators.push(
            patch<ResolvedShiftsStateModel>({
              shifts: sortBy<ResolvedShiftWithSyncStatus>((s) => s?.startDate),
            }),
          );

          stateOperators.push(
            patch<ResolvedShiftsStateModel>({
              shifts: removeItem<ResolvedShiftWithSyncStatus>(
                (s) => s?.delete === true && s?.syncedAt !== null,
              ),
            }),
          );

          ctx.setState(compose(...stateOperators));
          this.updateStorage(ctx);
          updateHydrationHistory(ctx, startDate, endDate, shiftsFromServer);
          this.syncingShiftsDataSubject.next(false);
          return shiftsFromServer;
        }),
        catchError(() => {
          this.syncingShiftsDataSubject.next(false);
          return of([]);
        }),
      );
  }

  @Action(ResolvedShiftActions.InitializeLiveShift)
  initializeLiveShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
  ): Observable<ResolvedShiftWithSyncStatus | null> {
    const state = ctx.getState();
    const shifts = state.shifts;
    const liveShiftId = this.getUserLiveShiftFromState(shifts);
    const liveShift = shifts.find(
      (s) => s.id === liveShiftId && s.declinedAt === null,
    );

    const userId = this.store.selectSnapshot<AuthStateModel['userId']>(
      AuthState.userId,
    );
    if (!userId) return of(null);

    if (state.activeShiftId !== liveShift?.id) {
      ctx.patchState({
        activeShiftId: liveShift?.id || null,
      });
      this.updateStorage(ctx);
    }

    return this.shiftsService.getLiveShiftForUser(userId).pipe(
      first(),
      map((liveShift) => {
        liveShift.activities.sort(activitiesCompareFn);
        liveShift.activities.forEach((activity) => {
          activity.activityReports.sort(activityReportsCompareFn);
        });
        return liveShift;
      }),
      tap((liveShiftFromServer) => {
        const shifts = ctx.getState().shifts;
        const localShift = shifts.find((s) => s.id === liveShiftFromServer.id);

        if (
          (liveShiftFromServer.id !== liveShift?.id &&
            liveShift?.activities?.find(
              (a) => a.operationalStatus === OperationStatus.Ongoing,
            )) ||
          (localShift &&
            (this.shiftUtilityService.isCompleted(localShift) ||
              (this.shiftUtilityService.isNotStarted(liveShiftFromServer) &&
                this.shiftUtilityService.isOngoing(localShift))))
        ) {
          // TO DO: add some error handling - e.g. toast to warn user
          // offer conflict resolving, e.g. let user end current shift and update/start with shift from server
          console.error(
            'live shift from server conflicts with current live shift',
          );
          return;
        }

        const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

        if (localShift) {
          stateOperators.push(
            ...this.shiftSyncService.updateShiftFromServer(
              liveShiftFromServer,
              localShift,
            ),
          );
          stateOperators.push(
            patch<ResolvedShiftsStateModel>({
              activeShiftId: liveShiftFromServer.id,
            }),
          );
        } else {
          stateOperators.push(
            patch<ResolvedShiftsStateModel>({
              activeShiftId: liveShiftFromServer.id,
              shifts: insertItem<ResolvedShiftWithSyncStatus>({
                ...liveShiftFromServer,
                syncedAt: new Date().toISOString(),
              }),
            }),
          );
        }

        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: sortBy<ResolvedShiftWithSyncStatus>((s) => s?.startDate),
          }),
        );
        ctx.setState(compose(...stateOperators));
        this.updateStorage(ctx);
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }

  @Action(ResolvedShiftActions.StartLiveShift)
  startLiveShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.StartLiveShift,
  ) {
    const state = ctx.getState();
    const shift = state.shifts.find((s) => s.id === state.activeShiftId);
    if (!shift?.id) return;

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] =
      this.startLiveShiftStateService.patchStartLiveShift(action, shift);

    ctx.setState(compose(...stateOperators));

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.StartNextActivityLiveShift)
  startNextActivityLiveShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.StartNextActivityLiveShift,
  ) {
    const state = ctx.getState();
    const shift = state.shifts.find((s) => s.id === state.activeShiftId);
    if (!shift?.id) return;

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] =
      this.nextLiveShiftActivityStateService.patchNextLiveShiftActivity(
        action,
        shift,
      );

    ctx.setState(compose(...stateOperators));

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.NewStartPauseActivityLiveShift)
  newStartPauseActivityLiveShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.NewStartPauseActivityLiveShift,
  ) {
    const state = ctx.getState();
    const shift = state.shifts.find((s) => s.id === state.activeShiftId);
    if (!shift?.id || !action.currentActivityId) return;
    const currentActivity = shift.activities.find(
      (a) => a.id === action.currentActivityId,
    );

    if (!currentActivity || !action.pauseActivity) return;
    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] =
      this.newStartPauseLiveShiftStateOperatorFactory.createNewStartPauseLiveShiftOperation(
        action,
        shift,
      );
    ctx.setState(compose(...stateOperators));

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.StartPauseActivityLiveShift)
  startPauseActivityLiveShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.StartPauseActivityLiveShift,
  ) {
    const state = ctx.getState();
    const shift = state.shifts.find((s) => s.id === state.activeShiftId);
    if (!shift?.id) return;

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] =
      this.startPauseActivityStateService.patchStartPauseActivity(
        action,
        shift,
      );

    ctx.setState(compose(...stateOperators));

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.EndLiveShift)
  async endLiveShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.EndLiveShift,
  ) {
    const state = ctx.getState();
    const shift = state.shifts.find((s) => s.id === state.activeShiftId);
    if (!shift?.id) return;

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] =
      this.endLiveShiftStateService.patchEndLiveShift(action, shift);

    ctx.setState(compose(...stateOperators));
    await this.setupSyncForShift(ctx, shift.id);
    return this.updateStorage(ctx);
  }

  @Action(ResolvedShiftActions.WithdrawSubmittedShift)
  withdrawSubmittedShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.WithdrawSubmittedShift,
  ) {
    const { shiftId } = action;

    const shift = ctx.getState().shifts.find((s) => s.id === shiftId);
    if (!shift?.id) return;

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          {
            ...shift,
            state: ShiftState.Reopened,
            syncedAt: null,
          },
        ),
      }),
    );

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.SetSubmitShift)
  setSubmitShiftState(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.SetSubmitShift,
  ) {
    const { shiftReport } = action;

    const shift = ctx
      .getState()
      .shifts.find((s) => s.id === shiftReport.shiftId);
    if (!shift?.id) return;

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          {
            ...shift,
            shiftReport,
            state: ShiftState.Submitted,
            syncedAt: null,
          },
        ),
      }),
    );

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shift.id);
  }

  @Action(ResolvedShiftActions.UpdateActivity)
  updateActivity(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateActivity,
  ) {
    const activity = action.activity;
    if (!activity?.shiftId) return;

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === activity.shiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === activity.id,
              activity,
            ),
          }),
        ),
      }),
    );

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, activity.shiftId);
  }

  @Action(ResolvedShiftActions.InsertActivity)
  insertActivity(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.InsertActivity,
  ) {
    const activity = action.activity;
    if (!activity?.shiftId) return;

    const activityToAdd = {
      ...activity,
      id: activity.id || uuidv4(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === activity.shiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
            activities: append<ResolvedActivityWithReports>([activityToAdd]),
          }),
        ),
      }),
    );

    stateOperators.push(sortActivitiesForShift(activity.shiftId));

    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, activity.shiftId);
  }

  @Action(ResolvedShiftActions.InsertActivityWithoutSync)
  insertActivityWithoutSync(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.InsertActivityWithoutSync,
  ) {
    const activity = action.activity;
    if (!activity?.shiftId) return;

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === activity.shiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: new Date().toISOString(),
            activities: append<ResolvedActivityWithReports>([activity]),
          }),
        ),
      }),
    );

    stateOperators.push(sortActivitiesForShift(activity.shiftId));

    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
  }

  @Action(ResolvedShiftActions.RemoveActivity)
  removeActivity(
    ctx: StateContext<ResolvedShiftsStateModel>,
    { payload }: ResolvedShiftActions.RemoveActivity,
  ) {
    if (payload.shiftId && payload.activityId) {
      ctx.setState(
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === payload.shiftId,
            patch<ResolvedShiftWithSyncStatus>({
              syncedAt: null,
              activities: removeItem<ResolvedActivityWithReports>(
                (a) => a?.id === payload.activityId,
              ),
            }),
          ),
        }),
      );

      this.updateStorage(ctx);
      this.setupSyncForShift(ctx, payload.shiftId);
    }
  }

  @Action(ResolvedShiftActions.RemoveActivityWithoutSync)
  removeActivityWithoutSync(
    ctx: StateContext<ResolvedShiftsStateModel>,
    { payload }: ResolvedShiftActions.RemoveActivityWithoutSync,
  ) {
    if (payload.shiftId && payload.activityId) {
      ctx.setState(
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === payload.shiftId,
            patch<ResolvedShiftWithSyncStatus>({
              syncedAt: null,
              activities: removeItem<ResolvedActivityWithReports>(
                (a) => a?.id === payload.activityId,
              ),
            }),
          ),
        }),
      );

      this.updateStorage(ctx);
    }
  }

  @Action(ResolvedShiftActions.DeleteMobileActivity)
  deleteMobileActivity(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.DeleteMobileActivity,
  ) {
    if (!action?.activity?.shiftId) return;
    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === action.activity.shiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === action.activity.id,
              { ...action.activity, deletedAt: new Date().toISOString() },
            ),
          }),
        ),
      }),
    );

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, action.activity.shiftId);
  }

  @Action(ResolvedShiftActions.CreateActivityComment)
  createActivityComment(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.CreateActivityComment,
  ) {
    ctx.setState(
      compose(
        ...[
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === action.shiftId,
              patch<ResolvedShiftWithSyncStatus>({
                syncedAt: null,
                activities: updateItem<ResolvedActivityWithReports>(
                  (a) => a?.id === action.activityId,
                  patch<ResolvedActivityWithReports>({
                    activityComments: append<ActivityComment>([
                      {
                        ...action.comment,
                        id: uuidv4(),
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                      },
                    ]),
                  }),
                ),
              }),
            ),
          }),
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === action.shiftId,
              patch<ResolvedShiftWithSyncStatus>({
                activities: updateItem<ResolvedActivityWithReports>(
                  (a) => a?.id === action.activityId,
                  patch<ResolvedActivityWithReports>({
                    activityComments: sortBy<ActivityComment>(
                      (a) => a?.createdAt,
                    ),
                  }),
                ),
              }),
            ),
          }),
        ],
      ),
    );
    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, action.shiftId);
  }

  @Action(ResolvedShiftActions.UpdateActivityCommentsSeenBy)
  updateActivityCommentsSeenBy(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateActivityCommentsSeenBy,
  ) {
    const activeUserId = this.store.selectSnapshot(AuthState.userId);
    if (!activeUserId) return;

    const commentsToUpdate = ctx
      .getState()
      .shifts.find((s) => s.id === action.shiftId)
      ?.activities.find((a) =>
        a.activityComments?.find(
          (c) =>
            !c?.seenByIds?.includes(activeUserId) &&
            c?.senderId !== activeUserId,
        ),
      );

    if (!commentsToUpdate) return;

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === action.shiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === action.activityId,
              patch<ResolvedActivityWithReports>({
                activityComments: updateItem<ActivityComment>(
                  (c) =>
                    !c?.seenByIds?.includes(activeUserId) &&
                    c?.senderId !== activeUserId,
                  patch<ActivityComment>({
                    seenByIds: append<string>([activeUserId]),
                  }),
                ),
              }),
            ),
          }),
        ),
      }),
    );
    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, action.shiftId);
  }

  @Action(ResolvedShiftActions.EditShift)
  editShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.EditShift,
  ) {
    const { shiftId, shift } = action;

    const shiftFromState = ctx.getState().shifts.find((s) => s.id === shiftId);
    if (!shiftFromState?.id) return;

    ctx.setState(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shiftFromState.id,
          {
            ...shiftFromState,
            ...shift,
            syncedAt: null,
          },
        ),
      }),
    );

    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, shiftId);
  }

  @Action(ResolvedShiftActions.UpdateShiftFromServer)
  updateShiftFromServer(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateShiftFromServer,
  ) {
    const shiftFromServer = action.shiftFromServer;
    if (!shiftFromServer) return;
    this.syncingShiftsDataSubject.next(true);

    const shiftFromState = ctx
      .getState()
      .shifts.find((s) => s.id === shiftFromServer.id);

    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    if (shiftFromState) {
      stateOperators.push(
        ...this.shiftSyncService.updateShiftFromServer(
          shiftFromServer,
          shiftFromState,
        ),
      );
    } else {
      stateOperators.push(
        patch<ResolvedShiftsStateModel>({
          shifts: insertItem<ResolvedShiftWithSyncStatus>({
            ...shiftFromServer,
            syncedAt: new Date().toISOString(),
          }),
        }),
      );
    }
    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: sortBy<ResolvedShiftWithSyncStatus>((s) => s?.startDate),
      }),
    );

    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
    this.syncingShiftsDataSubject.next(false);
  }

  @Action(ResolvedShiftActions.CreateShift)
  createShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.CreateShift,
  ) {
    const newShift: ResolvedShiftWithSyncStatus = {
      ...action.newShift,
      syncedAt: null,
      lastSeenAt: new Date().toISOString(),
    };
    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];
    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: insertItem<ResolvedShiftWithSyncStatus>(newShift),
      }),
    );
    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: sortBy<ResolvedShiftWithSyncStatus>((s) => s?.startDate),
      }),
    );
    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, newShift.id as string);
  }

  @Action(ResolvedShiftActions.UpdateLastSeenAtShift)
  updateLastSeenAtShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateLastSeenAtShift,
  ) {
    this.syncingShiftsData$
      .pipe(
        filter((syncingShifts) => !syncingShifts),
        take(1),
      )
      .subscribe(() => {
        if (!action.shiftId) return;
        const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) =>
                s?.id === action.shiftId &&
                s?.publicationStatus !== PublicationStatus.NotPublished &&
                s?.publicationStatus !== PublicationStatus.NotPublishedAgain,
              patch<ResolvedShiftWithSyncStatus>({
                syncedAt: null,
                lastSeenAt: new Date().toISOString(),
              }),
            ),
          }),
        );
        ctx.setState(compose(...stateOperators));
        this.updateStorage(ctx);
        this.setupSyncForShift(ctx, action.shiftId as string);
      });
  }

  @Action(ResolvedShiftActions.UpdateConfirmedAtShift)
  updateConfirmedAtShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateConfirmedAtShift,
  ) {
    this.syncingShiftsData$
      .pipe(
        filter((syncingShifts) => !syncingShifts),
        take(1),
      )
      .subscribe(() => {
        if (!action.shiftId) return;
        const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === action.shiftId,
              patch<ResolvedShiftWithSyncStatus>({
                syncedAt: null,
                confirmedAt: new Date().toISOString(),
                declinedAt: null,
              }),
            ),
          }),
        );
        ctx.setState(compose(...stateOperators));
        this.updateStorage(ctx);
        this.setupSyncForShift(ctx, action.shiftId as string);
      });
  }

  @Action(ResolvedShiftActions.UpdateDeclineReasonShift)
  updateDeclineReasonShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateDeclineReasonShift,
  ) {
    this.syncingShiftsData$
      .pipe(
        filter((syncingShifts) => !syncingShifts),
        take(1),
      )
      .subscribe(() => {
        if (!action.reasonAndShiftId.shiftId) return;
        const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === action.reasonAndShiftId.shiftId,
              patch<ResolvedShiftWithSyncStatus>({
                confirmedAt: null,
                declinedAt: new Date().toISOString(),
                declineReason: action.reasonAndShiftId.reason,
                publicationStatus: PublicationStatus.NotPublished,
                syncedAt: null,
              }),
            ),
          }),
        );
        ctx.setState(compose(...stateOperators));
        this.updateStorage(ctx);
        this.setupSyncForShift(ctx, action.reasonAndShiftId.shiftId as string);
      });
  }

  @Action(ResolvedShiftActions.UpdateDeclinedAtShift)
  updateDeclinedAtShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.UpdateDeclinedAtShift,
  ) {
    this.syncingShiftsData$
      .pipe(
        filter((syncingShifts) => !syncingShifts),
        take(1),
      )
      .subscribe(() => {
        if (!action.shiftId) return;
        const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

        stateOperators.push(
          patch<ResolvedShiftsStateModel>({
            shifts: updateItem<ResolvedShiftWithSyncStatus>(
              (s) => s?.id === action.shiftId,
              patch<ResolvedShiftWithSyncStatus>({
                confirmedAt: null,
                declinedAt: new Date().toISOString(),
                publicationStatus: PublicationStatus.NotPublished,
                syncedAt: null,
              }),
            ),
          }),
        );
        ctx.setState(compose(...stateOperators));
        this.updateStorage(ctx);
        this.setupSyncForShift(ctx, action.shiftId as string);
      });
  }

  @Action(ResolvedShiftActions.DeleteShift)
  deleteShift(
    ctx: StateContext<ResolvedShiftsStateModel>,
    action: ResolvedShiftActions.DeleteShift,
  ) {
    if (!action.deleteShiftId) return;
    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators.push(
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === action.deleteShiftId,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
            delete: true,
          }),
        ),
      }),
    );
    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
    this.setupSyncForShift(ctx, action.deleteShiftId as string);
  }

  private updateStorage(ctx: StateContext<ResolvedShiftsStateModel>) {
    return this.storage.set(RESOLVED_SHIFTS_STATE_NAME, ctx.getState());
  }

  private getUserLiveShiftFromState(
    shifts: ResolvedShiftWithReports[],
  ): string | undefined {
    const sortedShifts = deepClone(shifts)
      .sort(shiftsCompareFn)
      .filter(
        (shift) =>
          shift.publicationStatus !== PublicationStatus.NotPublished &&
          shift.publicationStatus !== PublicationStatus.NotPublishedAgain,
      );
    const userId = this.store.selectSnapshot(AuthState.userId);
    let liveShiftFromState;
    sortedShifts.forEach((shift, index, shifts) => {
      shifts[index].activities = shifts[index].activities?.filter(
        (activity) => !activity.deletedAt,
      );
    });
    liveShiftFromState = sortedShifts.find((shift) => {
      return (
        shift.userId === userId &&
        shift.activities?.find(
          (activity) => activity.operationalStatus === OperationStatus.Ongoing,
        )
      );
    });
    if (liveShiftFromState) {
      return liveShiftFromState.id;
    } else {
      liveShiftFromState = sortedShifts.find((shift) => {
        return (
          shift.userId === userId &&
          shift.activities?.find(
            (activity) =>
              activity.operationalStatus !== OperationStatus.Completed &&
              activity.operationalStatus !== OperationStatus.SkippedByUser &&
              activity.operationalStatus !== OperationStatus.Cancelled &&
              isAfter(new Date(activity.endDatetime), new Date()),
          )
        );
      });
      return liveShiftFromState?.id;
    }
  }
}
