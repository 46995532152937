@let hasLabelsReadPermission = RoleAction.Read | ablePure :
RolePermissionSubject.Label| async; @if(hasLabelsReadPermission){
<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="placeholder || ('page.shift.filter_labels' | translate)"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="labels-filter:select"
  >
    <nz-option
      *ngIf="!hideNoLabelOption"
      [nzLabel]="'page.service.no_label' | translate"
      [nzValue]="'no-label'"
      data-test-id="labels-filter:select:no-label"
    >
      <span nz-tooltip [nzTooltipColor]="'#fff'">
        {{ 'page.service.no_label' | translate }}
      </span>
    </nz-option>
    <nz-option
      *ngFor="let label of options$ | async"
      [nzLabel]="label.name"
      [nzValue]="label.id"
      nzCustomContent
      [attr.data-test-id]="'labels-filter:select:option:' + label.id"
    >
      <div class="category-container">
        <div class="dot" [style.background-color]="label.colorCode"></div>
        <div class="label-name">
          {{ label.name | translate }}
        </div>
      </div>
    </nz-option>
  </nz-select>
</div>
<wilson-filter-label
  *ngIf="showLabel"
  [label]="'page.shift.filter_labels' | translate"
>
</wilson-filter-label>
}
