import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { FilterOptions } from '../../options.interface';
import { catchError, map } from 'rxjs/operators';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ClientsV2Service } from '@wilson/clients/services';
@Component({
  selector: 'wilson-shared-user-qualification-v2-partner-filter',
  templateUrl: './shared-user-qualification-v2-partner-filter.component.html',
  styleUrls: ['./shared-user-qualification-v2-partner-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzSelectModule, TranslateModule, CommonModule, FormsModule],
})
export class SharedUserQualificationV2PartnerFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly clientsV2Service: ClientsV2Service,
  ) {
    super('sharedUserQualificationV2Partner');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.clientsV2Service
      .findAll({
        relations: ['clientPartnership'],
        limit: 0,
      })
      .pipe(
        map((clients) => {
          return clients.data
            .filter(
              (client) => client.id !== undefined && client.clientPartnership,
            )
            .map((client) => ({
              id: client.clientPartnership?.partnershipId as string,
              name: client.name,
              value: client.clientPartnership?.partnershipId as string,
            }));
        }),
        catchError(() => of([])),
      );
  }
}
