import { BillingRule } from './billing-rules';

export interface BillingRuleTimeFrameBonus extends BillingRule {
  calculatorName: 'TimeFrameBonus';
  params: {
    timeBonusCalculationMethod: 'hierarchal-calculation' | 'flat-calculation';
    bonuses: BillingRuleTimeFrameBonusBonus[];
  };
}

export interface BillingRuleTimeFrameBonusBonus {
  ruleName: string;
  isoWeekdays: BillingRuleTimeFrameBonusIsoWeekDay[];
  onHolidaysRegion: string[];
  timeFrame: BillingRuleTimeFrameBonusTimeFrame;
  roundUpAfter: number;
  pricePerUnit: number;
  notConsideredActivityCategories: string[];
  priority: number;
}

export type BillingRuleTimeFrameBonusIsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface BillingRuleTimeFrameBonusTimeFrame {
  start: string;
  end: string;
}

export function isTimeFrameBonus(
  obj: BillingRule,
): obj is BillingRuleTimeFrameBonus {
  return obj.calculatorName === 'TimeFrameBonus';
}
