import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wilson-page-frame',
  templateUrl: './page-frame.component.html',
  styleUrls: ['./page-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFrameComponent {
  @Input() title!: string;
  @Input() description!: string;
}
