import { Injectable } from '@angular/core';
import { ParserService } from './parser.service';

@Injectable({
  providedIn: 'root',
})
export class BooleanParserService extends ParserService<boolean> {
  public parse(value: boolean): string {
    return value.toString();
  }

  public unparse(value: string): boolean {
    return value ? JSON.parse(value) : false;
  }
}
