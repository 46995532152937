@if(shift) { @let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ;
<ng-container
  *ngIf="{
    isDisabled:
      shift.state === ShiftState.AcceptedTimes ||
      shift.createdFrom === WilsonApp.Mobile ||
      shift.state === ShiftState.SubmittedToPayrollProvider ||
      !hasShiftUpdatePermissions
  } as data"
>
  <li
    nz-tooltip
    [nzTooltipTitle]="
      !hasShiftUpdatePermissions ? ('general.forbidden_action' | translate) : ''
    "
    nz-menu-item
    [nzDisabled]="data.isDisabled"
    data-test-id="wilson-convert-to-standby-shift-action:button"
    (click)="openModal(data.isDisabled)"
  >
    {{ 'shift.turn_shift_to_dispo_shift.title' | translate }}
  </li>
</ng-container>

}
