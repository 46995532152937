import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseFilterDirective } from './base-filter.directive';
import { FilterOptions } from './options.interface';

@Directive()
export abstract class BaseSelectFilterDirective<T extends FilterOptions, K>
  extends BaseFilterDirective<K>
  implements OnInit
{
  public options$!: Observable<T[]>;

  override ngOnInit(): void {
    super.ngOnInit();
    this.options$ = this.initializeOptions();
  }

  protected abstract initializeOptions(): Observable<T[]>;
}
