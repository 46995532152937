import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  PublicationStatus,
  RoleAction,
  RolePermissionSubject,
  Shift,
  ShiftState,
} from '@wilson/interfaces';
import { canShiftBeTimeAdjusted } from './can-shift-be-time-adjusted.fn';
import { ShiftsService } from '@wilson/shifts';
import { firstValueFrom } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthorizationModule } from '@wilson/authorization';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-fill-with-planned-time-action',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    TranslateModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './fill-with-planned-time-action.component.html',
  styleUrl: './fill-with-planned-time-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillWithPlannedTimeActionComponent {
  protected shiftId = '';
  protected canShiftBeTimeAdjusted = false;
  protected PublicationStatus = PublicationStatus;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  @Input({
    required: true,
  })
  set shift(value: Shift | undefined) {
    if (value) {
      this.shiftId = value.id as string;
      this.canShiftBeTimeAdjusted = canShiftBeTimeAdjusted({
        shiftState: value.state,
        assignedUserId: value.userId as string,
        shiftPublicationStatus: value.publicationStatus,
      });
    }
  }

  @Output()
  saveSuccessful = new EventEmitter();

  ShiftState = ShiftState;

  constructor(
    private readonly translate: TranslateService,
    private shiftsService: ShiftsService,
    private nzMessage: NzMessageService,
  ) {}

  async fillShiftWithPlannedTime(isDisabled: boolean) {
    if (!isDisabled) {
      try {
        await firstValueFrom(
          this.shiftsService.patchBulkSubmitShifts([this.shiftId]),
        );
        this.nzMessage.success(this.translate.instant('popup.save.text'));
        this.saveSuccessful.emit();
      } catch {
        this.nzMessage.error(
          this.translate.instant('general.something_went_wrong'),
        );
      }
    }
  }
}
