<div class="ps-4 d-flex flex-column flex-grow-1">
  <div *ngIf="title" class="my-3">
    <h6 class="fw-normal">
      {{ title }}
    </h6>
  </div>
  <p *ngIf="description" class="description">{{ description }}</p>

  <div class="d-flex flex-row flex-grow-1">
    <div class="flex-grow-1 pe-4">
      <ng-content select="[mainContent]"></ng-content>
    </div>
    <div class="col-2 pe-3 px-xl-3">
      <ng-content></ng-content>
    </div>
  </div>
</div>
