import {
  ExternalUser,
  UserSharedByMe,
  SharedUserApprovalStatus,
} from '@wilson/clients/services';

const approvalStatusPriority: { [key in SharedUserApprovalStatus]: number } = {
  [SharedUserApprovalStatus.APPROVED]: 1,
  [SharedUserApprovalStatus.IN_REVIEW]: 2,
  [SharedUserApprovalStatus.OPEN]: 3,
  [SharedUserApprovalStatus.TEMPORARY_DECLINED]: 4,
  [SharedUserApprovalStatus.DECLINED]: 5,
};

export const approvalStatusComparator = (
  valueA: UserSharedByMe | ExternalUser,
  valueB: UserSharedByMe | ExternalUser,
  nodeA: unknown,
  nodeB: unknown,
  isDescending: boolean,
) => {
  const statusA =
    'sharedUsers' in valueA
      ? valueA.sharedUsers?.[0]?.approvalStatus
      : valueA.approvalStatus;

  const statusB =
    'sharedUsers' in valueB
      ? valueB.sharedUsers?.[0]?.approvalStatus
      : valueB.approvalStatus;

  // handle null & undefined values
  const priorityA = statusA ? approvalStatusPriority[statusA] : 6;
  const priorityB = statusB ? approvalStatusPriority[statusB] : 6;

  // null & undefined always at the bottom
  if (priorityA === 6 && priorityB === 6) return 0;
  if (priorityA === 6) return isDescending ? -1 : 1;
  if (priorityB === 6) return isDescending ? 1 : -1;

  return priorityA - priorityB;
};
