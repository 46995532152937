import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShiftCategoriesColorCode } from '@wilson/interfaces';
import { ShiftDropdownOptionsService } from '@wilson/non-domain-specific/shift-helpers/core';
import { Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-shift-category-filter',
  templateUrl: './shift-category-filter.component.html',
  styleUrls: ['./shift-category-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftCategoryFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly translate: TranslateService,
    private readonly shiftDropdownOptionsService: ShiftDropdownOptionsService,
  ) {
    super('shiftCategory');
  }

  public shiftCategoriesColorCode = ShiftCategoriesColorCode;

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.shiftDropdownOptionsService.shiftCategories$ as Observable<
      FilterOptions[]
    >;
  }
}
