<ng-container *ngIf="rule">
  <wilson-price-per-unit
    [pricePerUnit]="rule.params.pricePerUnit"
    customLabel="agreement.rules.TimeFrameMinimumRate.price_per_unit.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.price_per_unit.description"
    (updatePricePerUnit)="setPricePerUnit($event)"
  ></wilson-price-per-unit>
  <wilson-fill-up-to
    [fillUpTo]="rule.params.timeFrameFillUpTo"
    customLabel="agreement.rules.TimeFrameMinimumRate.time_frame_fill_up_to.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.time_frame_fill_up_to.description"
  ></wilson-fill-up-to>
  <wilson-fill-up-to
    [fillUpTo]="rule.params.shiftFillUpTo"
    customLabel="agreement.rules.TimeFrameMinimumRate.shift_fill_up_to.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.shift_fill_up_to.description"
  ></wilson-fill-up-to>
  <div class="activity_category_options">
    <wilson-rule-label
      customLabel="agreement.rules.TimeFrameMinimumRate.activity_category_options.label"
    ></wilson-rule-label>
    <wilson-rule-description
      customDescription="agreement.rules.TimeFrameMinimumRate.activity_category_options.description"
    ></wilson-rule-description>
    <div class="activity_category_options__entries">
      <ng-container
        *ngFor="let entry of rule.params.activityCategoryOptions; let i = index"
      >
        <div class="activity_category_options__entries__entry">
          <wilson-activity-categories
            [activityCategories]="entry.activityCategories"
            customLabel="agreement.rules.TimeFrameMinimumRate.activity_category_options.activity_categories.label"
            customDescription="agreement.rules.TimeFrameMinimumRate.activity_category_options.activity_categories.description"
          ></wilson-activity-categories>
          <wilson-round-up-after
            [roundUpAfter]="entry.roundUpAfter"
            customLabel="agreement.rules.TimeFrameMinimumRate.activity_category_options.round_up_after.label"
            customDescription="agreement.rules.TimeFrameMinimumRate.activity_category_options.round_up_after.description"
          ></wilson-round-up-after>
          <wilson-minimum-amount
            [minimumAmount]="entry.minimumAmount"
            customLabel="agreement.rules.TimeFrameMinimumRate.activity_category_options.minimumAmount.label"
            customDescription="agreement.rules.TimeFrameMinimumRate.activity_category_options.minimumAmount.description"
          ></wilson-minimum-amount>
          <wilson-price-per-unit
            [pricePerUnit]="entry.pricePerUnit"
            [index]="i"
            customLabel="agreement.rules.TimeFrameMinimumRate.activity_category_options.price_per_unit.label"
            customDescription="agreement.rules.TimeFrameMinimumRate.activity_category_options.price_per_unit.description"
            (updatePricePerUnit)="setPricePerUnit($event)"
          ></wilson-price-per-unit>
        </div>
      </ng-container>
    </div>
  </div>
  <wilson-activity-categories
    [activityCategories]="rule.params.absenceCategories"
    customLabel="agreement.rules.TimeFrameMinimumRate.abscence_categories.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.abscence_categories.description"
  ></wilson-activity-categories>
  <wilson-exclude-holidays
    [excludeHolidays]="rule.params.excludeHolidays"
    customLabel="agreement.rules.TimeFrameMinimumRate.excludeHolidays.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.excludeHolidays.description"
  ></wilson-exclude-holidays>
  <wilson-frequency
    [frequency]="rule.params.frequency"
    customLabel="agreement.rules.TimeFrameMinimumRate.frequency.label"
    customDescription="agreement.rules.TimeFrameMinimumRate.frequency.description"
  ></wilson-frequency>
</ng-container>
