import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { InvoiceStatus } from './create-pdf-payload';

export interface InvoiceItem {
  activityName: string;
  serviceName: string;
  shiftName: string;
  shiftStartDate: string;
  shiftOrganizationalUnitName: string;
  startTime: string;
  endTime: string;
  startLocation: string;
  endLocation: string;
  activityCategory: string;
  activityComments: string;
  userOrganizationalUnitName: string;
  userName: string;
  clientName: string;
  companyCode: string;
  clientNumber: string;
  agreementName: string;
  duration: number;
  tonnage: number;
  vehicleEmissionClass: string;
  numberOfWagons: number;
  ruleName: string;
  unit: Unit;
  quantity: string;
  pricePerUnit: string;
  sum: string;
  travelDirection: string;
  organizationalUnitTransportGoods: string;
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  CHF = 'CHF',
}

export enum CurrencySymbol {
  USD = '$',
  EUR = '€',
  CHF = '₣',
}

export enum InvoiceFormKeys {
  CustomerDetails = 'customerDetails',
  InvoiceDetails = 'invoiceDetails',
  InvoiceHeader = 'invoiceHeader',
  InvoiceFooter = 'invoiceFooter',
  AggregatedInvoiceItems = 'aggregatedInvoiceItems',
  AdvancePayment = 'advancePayment',
  ClientId = 'clientId',
  ActivityIds = 'activityIds',
  Status = 'status',
  ID = 'id',
}

export interface CustomerDetails {
  clientName: string;
  clientNumber: string;
  clientEmail: string;
  taxId: string;
  contactPersonClient: string;
  invoiceAddress: string;
  clientId: string;
  externalOrderId: string;
}

export interface InvoiceDetails {
  currency: Currency;
  valueAddedTax: number;
  invoiceNumber: string;
  contactPersonProvider: string;
  purchaseOrder: string;
  companyCode: string;
  invoiceDate: string;
  daysToPayInvoice: number;
  paymentMethod: string;
  discountDays: number;
  discountPercentage: number;
  serviceStartDatetime: string;
  serviceEndDatetime: string;
  serviceTimeframe: string;
}

export interface InvoiceHeader {
  invoiceText?: string;
  invoiceSubject?: string;
}

export interface InvoiceFooter {
  furtherRemarks: string;
}

export interface AggregatedInvoiceItem {
  invoiceItems: InvoiceItem[];
  pricePerUnit: string;
  totalQuantity: number;
  unit: Unit;
  agreementName: string;
  ruleName: string;
  newEntry?: boolean;
}

export type AggregatedInvoiceItemKeys = keyof AggregatedInvoiceItem;
export type InvoiceItemKeys = keyof InvoiceItem;

export enum Unit {
  hour = 'hour',
  fixed = 'fixed',
}

export interface Invoice {
  [InvoiceFormKeys.CustomerDetails]: CustomerDetails;
  [InvoiceFormKeys.InvoiceDetails]: InvoiceDetails;
  [InvoiceFormKeys.InvoiceHeader]: InvoiceHeader;
  [InvoiceFormKeys.InvoiceFooter]: InvoiceFooter;
  [InvoiceFormKeys.AggregatedInvoiceItems]: AggregatedInvoiceItem[];
  [InvoiceFormKeys.AdvancePayment]: number;
  status: InvoiceStatus;
  canEditInvoice?: boolean;
  lexofficeId?: string | null;
}

export type InvoiceForm = FormGroup<ControlsOf<Invoice>>;

export enum PaymentMethod {
  SepaCreditTransfer = 'sepaCreditTransfer',
  SepaDirectDebitTransfer = 'sepaDirectDebitTransfer',
  Cash = 'cash',
}
