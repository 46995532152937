import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { QualificationCategoryV2Service } from '@wilson/api/gateway';
import { QualificationCategoryV2 } from '@wilson/interfaces';
import { catchError, Observable, of, take, tap } from 'rxjs';
import {
  InitQualificationsV2Categories,
  ResetQualificationsV2Categories,
  SetQualificationsV2Categories,
} from './qualifications-v2-category.action';

export interface QualificationsV2CategoryStateModel {
  categories: QualificationCategoryV2[];
  loading: boolean;
  hasInitialized: boolean;
}

const QUALIFICATION_V2_CATEGORY_STATE = 'qualificationsV2Categories';
const defaultQualificationsCategoryState: QualificationsV2CategoryStateModel = {
  categories: [],
  loading: false,
  hasInitialized: false,
};

@State<QualificationsV2CategoryStateModel>({
  name: QUALIFICATION_V2_CATEGORY_STATE,
  defaults: defaultQualificationsCategoryState,
})
@Injectable()
export class QualificationsV2CategoriesState implements NgxsAfterBootstrap {
  constructor(
    private readonly storage: Storage,
    private readonly qualificationCategoryV2Service: QualificationCategoryV2Service,
  ) {}

  async ngxsAfterBootstrap(
    ctx: StateContext<QualificationsV2CategoryStateModel>,
  ): Promise<void> {
    const storageState = await this.storage.get(
      QUALIFICATION_V2_CATEGORY_STATE,
    );
    if (storageState) {
      ctx.patchState({
        categories: storageState,
      });
    }
  }

  @Selector()
  static isLoading(
    state: QualificationsV2CategoryStateModel,
  ): QualificationsV2CategoryStateModel['loading'] {
    return state.loading;
  }

  @Selector()
  static hasInitialized(
    state: QualificationsV2CategoryStateModel,
  ): QualificationsV2CategoryStateModel['hasInitialized'] {
    return state.hasInitialized;
  }

  @Selector()
  static categories(
    state: QualificationsV2CategoryStateModel,
  ): QualificationsV2CategoryStateModel['categories'] {
    return state.categories;
  }

  static categoryById(
    categoryId: string,
  ): (
    state: QualificationsV2CategoryStateModel,
  ) => QualificationCategoryV2 | undefined {
    return createSelector(
      [QualificationsV2CategoriesState],
      (state: QualificationsV2CategoryStateModel) => {
        return state.categories.find((category) => category.id === categoryId);
      },
    );
  }

  static categoryByIdExists(
    categoryId: string,
  ): (state: QualificationsV2CategoryStateModel) => boolean {
    return createSelector(
      [QualificationsV2CategoriesState],
      (state: QualificationsV2CategoryStateModel) => {
        return state.categories.some((category) => category.id === categoryId);
      },
    );
  }

  @Action(ResetQualificationsV2Categories)
  resetQualificationsV2Categories({
    setState,
  }: StateContext<QualificationsV2CategoryStateModel>): void {
    setState(defaultQualificationsCategoryState);
    this.updateStorage(defaultQualificationsCategoryState);
  }

  @Action(InitQualificationsV2Categories)
  initQualificationsV2Categories({
    patchState,
    getState,
  }: StateContext<QualificationsV2CategoryStateModel>): Observable<
    QualificationCategoryV2[] | undefined
  > {
    patchState({
      loading: true,
    });
    return this.qualificationCategoryV2Service.getCategories().pipe(
      take(1),
      tap((fetchedCategories) => {
        patchState({
          categories: fetchedCategories,
          loading: false,
          hasInitialized: true,
        });
        this.updateStorage(getState());
      }),
      catchError((error) => {
        Sentry.captureException(error);
        patchState({
          loading: false,
        });
        return of(undefined);
      }),
    );
  }

  @Action(SetQualificationsV2Categories)
  setQualificationsV2Categories(
    { patchState, getState }: StateContext<QualificationsV2CategoryStateModel>,
    { setCategories }: SetQualificationsV2Categories,
  ): void {
    patchState({
      categories: setCategories,
    });
    this.updateStorage(getState());
  }

  private updateStorage(state: QualificationsV2CategoryStateModel): void {
    this.storage.set(QUALIFICATION_V2_CATEGORY_STATE, state.categories);
  }
}
