import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BillingRuleActivityHourRate } from '../../interfaces/activity-hour-rate';
import { PricePerHourComponent } from '../partials/price-per-hour/price-per-hour.component';
import { RoundUpAfterComponent } from '../partials/round-up-after/round-up-after.component';

@Component({
  selector: 'wilson-activity-hour-rate',
  standalone: true,
  imports: [CommonModule, PricePerHourComponent, RoundUpAfterComponent],
  templateUrl: './activity-hour-rate.component.html',
  styleUrl: './activity-hour-rate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityHourRateComponent {
  @Input() rule: BillingRuleActivityHourRate;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleActivityHourRate;
    index: number;
  }>();

  setPricePerHour(newPricePerHour: number) {
    this.rule.params.pricePerHour = newPricePerHour;
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }
}
