<div>
  <nz-tree-select
    *ngIf="options$ | async as agreementOptions"
    class="w-100"
    [nzNodes]="agreementOptions"
    [nzShowSearch]="true"
    [nzHideUnMatched]="true"
    [nzCheckable]="true"
    [nzPlaceHolder]="placeholder || 'activity.agreement' | translate"
    [ngModel]="selected$ | async"
    (ngModelChange)="saveSelectedAgreement(agreementOptions)"
    [nzDropdownStyle]="{ 'max-height': '300px', overflow: 'auto' }"
    data-test-id="agreement-filter:select"
  ></nz-tree-select>
</div>
<wilson-filter-label
  *ngIf="showLabel"
  [label]="'filter.client-agreement' | translate"
>
</wilson-filter-label>
