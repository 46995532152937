<ng-container
  *ngIf="
    ('agreement-rules-edit' | featureFlagPure | async) === false;
    else agreementsWithRuleEditing
  "
>
  <wilson-agreements-form></wilson-agreements-form>
</ng-container>
<ng-template #agreementsWithRuleEditing>
  <div class="agreements_tabset">
    <nz-tabset>
      <nz-tab [nzTitle]="'agreement.modal.tabs.general' | translate">
        <wilson-agreements-form></wilson-agreements-form>
      </nz-tab>
      <nz-tab
        *ngIf="agreement?.rules?.length"
        [nzTitle]="'agreement.modal.tabs.rules' | translate"
      >
        <ng-container *ngIf="agreement?.rules as rules">
          <wilson-generic-rule
            *ngFor="let rule of rules; let i = index"
            [rule]="rule"
            [index]="i"
          ></wilson-generic-rule>
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </div>
</ng-template>
