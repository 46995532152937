<wilson-rule-label [customLabel]="customLabel"></wilson-rule-label>
<wilson-rule-description
  [customDescription]="customDescription"
></wilson-rule-description>
<nz-input-group [nzAddOnAfter]="currencySymbol">
  <input
    nz-input
    class="price-per-unit__input"
    [value]="pricePerUnit"
    (change)="setPricePerUnit($event)"
    type="number"
  />
</nz-input-group>
