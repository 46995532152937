import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import * as Sentry from '@sentry/angular';
import { QualificationV2 } from '@wilson/interfaces';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  GetQualificationsDto,
  QualificationV2Service,
} from '@wilson/qualification-v2/common';
import {
  GetOrFetchQualificationV2,
  ResetQualificationsV2,
  UpsertQualificationsV2,
} from './qualifications-v2.action';

export interface QualificationsV2StateModel {
  qualifications: QualificationV2[];
  loading: boolean;
}

const QUALIFICATIONS_V2_STATE = 'qualificationsV2State';
const defaultQualificationsState: QualificationsV2StateModel = {
  qualifications: [],
  loading: false,
};

@State<QualificationsV2StateModel>({
  name: QUALIFICATIONS_V2_STATE,
  defaults: defaultQualificationsState,
})
@Injectable()
export class QualificationsV2State implements NgxsAfterBootstrap {
  constructor(
    private readonly qualificationV2Service: QualificationV2Service,
    private readonly store: Store,
    private readonly storage: Storage,
  ) {}

  async ngxsAfterBootstrap(
    ctx: StateContext<QualificationsV2StateModel>,
  ): Promise<void> {
    const storageState = await this.storage.get(QUALIFICATIONS_V2_STATE);
    if (storageState) {
      ctx.patchState({
        qualifications: storageState,
      });
    }
  }

  @Selector()
  static isLoading(
    state: QualificationsV2StateModel,
  ): QualificationsV2StateModel['loading'] {
    return state.loading;
  }

  @Selector()
  static qualifications(
    state: QualificationsV2StateModel,
  ): QualificationsV2StateModel['qualifications'] {
    return state.qualifications;
  }

  static qualificationById(
    qualificationId: string,
  ): (state: QualificationsV2StateModel) => QualificationV2 | undefined {
    return createSelector(
      [QualificationsV2State],
      (state: QualificationsV2StateModel) => {
        return state.qualifications.find(
          (qualification) => qualification.id === qualificationId,
        );
      },
    );
  }

  static qualificationByIdExists(
    qualificationId: string,
  ): (state: QualificationsV2StateModel) => boolean {
    return createSelector(
      [QualificationsV2State],
      (state: QualificationsV2StateModel) => {
        return state.qualifications.some(
          (qualification) => qualification.id === qualificationId,
        );
      },
    );
  }

  @Action(ResetQualificationsV2)
  resetQualificationsV2({
    setState,
  }: StateContext<QualificationsV2StateModel>): void {
    setState(defaultQualificationsState);
  }

  @Action(UpsertQualificationsV2)
  UpsertQualificationsV2(
    { setState, getState }: StateContext<QualificationsV2StateModel>,
    { upsertQualifications }: UpsertQualificationsV2,
  ): void {
    setState(
      patch({
        qualifications: (
          existingQualifications: ReadonlyArray<QualificationV2>,
        ) => {
          const updatedQualifications = existingQualifications.map(
            (qualification) => {
              const newQualification = upsertQualifications.find(
                (upsertQualification) =>
                  upsertQualification.id === qualification.id,
              );
              return newQualification ? newQualification : qualification;
            },
          );

          const newQualifications = upsertQualifications.filter(
            (upsertQualification) =>
              !existingQualifications.some(
                (existingQualification) =>
                  existingQualification.id === upsertQualification.id,
              ),
          );

          return [...updatedQualifications, ...newQualifications];
        },
      }),
    );
    this.updateStorage(getState());
  }

  @Action(GetOrFetchQualificationV2)
  getOrFetchQualificationV2(
    { setState, getState }: StateContext<QualificationsV2StateModel>,
    action: GetOrFetchQualificationV2,
  ): Observable<QualificationV2 | undefined> {
    return this.store.select(QualificationsV2State.isLoading).pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => {
        if (
          QualificationsV2State.qualificationByIdExists(action.qualificationId)(
            getState(),
          )
        ) {
          const existingQualification = QualificationsV2State.qualificationById(
            action.qualificationId,
          )(getState());
          return of(existingQualification);
        }

        setState(
          patch<QualificationsV2StateModel>({
            loading: true,
          }),
        );

        const findDto: GetQualificationsDto = {
          pageSize: 1,
          pageNumber: 1,
          findOptions: {
            id: action.qualificationId,
          },
        };

        return this.qualificationV2Service.getQualifications(findDto).pipe(
          map((response) => {
            return response.data[0];
          }),
          tap((qualification) => {
            setState(
              patch<QualificationsV2StateModel>({
                qualifications: append<QualificationV2>([qualification]),
                loading: false,
              }),
            );
            this.updateStorage(getState());
          }),
          catchError((error) => {
            Sentry.captureException(error);
            setState(
              patch<QualificationsV2StateModel>({
                loading: false,
              }),
            );
            return of(undefined);
          }),
        );
      }),
    );
  }

  private updateStorage(state: QualificationsV2StateModel): void {
    this.storage.set(QUALIFICATIONS_V2_STATE, state.qualifications);
  }
}
