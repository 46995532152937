import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Agreement } from '@wilson/clients/interfaces';
import { AgreementsService } from '@wilson/clients/services';
import { FormService } from '@wilson/forms';
import { Currency } from '@wilson/invoicing/interfaces';

@Injectable()
export class AgreementsFormService extends FormService<Agreement> {
  Currency = Currency;
  public readonly form = new FormGroup<ControlsOf<Agreement>>({
    name: new FormControl<Agreement['name']>('', [Validators.required]),
    purchaseOrder: new FormControl<Agreement['purchaseOrder']>(''),
    companyCode: new FormControl<Agreement['companyCode']>(''),
    currency: new FormControl<Agreement['currency']>(),
    vat: new FormControl<Agreement['vat']>(0),
    advancePayment: new FormControl<Agreement['advancePayment']>(0),
    rules: new FormControl(''),
    description: new FormControl<Agreement['description']>(''),
    clientId: new FormControl<Agreement['clientId']>(''),
    costCenter: new FormControl<Agreement['costCenter']>(''),
  });

  constructor(protected readonly datasource: AgreementsService) {
    super();
  }
}
