<ng-container *ngIf="rule">
  <wilson-price-per-hour
    [pricePerHour]="rule.params.pricePerHour"
    customLabel="agreement.rules.ActivityHourRate.price_per_hour.label"
    customDescription="agreement.rules.ActivityHourRate.price_per_hour.description"
    (updatePricePerHour)="setPricePerHour($event)"
  ></wilson-price-per-hour>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.countAfterMinutes"
    customLabel="agreement.rules.ActivityHourRate.count_after_minutes.label"
    customDescription="agreement.rules.ActivityHourRate.count_after_minutes.description"
  ></wilson-round-up-after>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.roundUpAfterMinutes"
    customLabel="agreement.rules.ActivityHourRate.round_up_after_minutes.label"
    customDescription="agreement.rules.ActivityHourRate.round_up_after_minutes.description"
  ></wilson-round-up-after>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.roundUpAfter"
    customLabel="agreement.rules.ActivityHourRate.round_up_after.label"
    customDescription="agreement.rules.ActivityHourRate.round_up_after.description"
  ></wilson-round-up-after>
</ng-container>
