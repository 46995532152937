import { inject, Pipe, PipeTransform } from '@angular/core';
import { RolesPipe } from '@wilson/authorization';
import {
  RoleAction,
  RoleName,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { Observable, of, switchMap } from 'rxjs';
import { AblePurePipe } from '@casl/angular';

@Pipe({
  name: 'shiftDeletionEvaluator',
  standalone: true,
})
export class ShiftDeletionEvaluatorPipe implements PipeTransform {
  protected rolesPipe = inject(RolesPipe);
  protected ablePurePipe = inject(AblePurePipe);

  transform(isShiftSubmittedOrAccepted: boolean): Observable<boolean> {
    return this.rolesPipe.transform([RoleName.CompanyAdmin]).pipe(
      switchMap((isCompanyAdmin) => {
        if (isShiftSubmittedOrAccepted) {
          return of(isCompanyAdmin);
        } else {
          return this.ablePurePipe.transform(
            RoleAction.Delete,
            RolePermissionSubject.Shift,
          );
        }
      }),
    );
  }
}
