import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TimelineActiveFilterRegionEnum } from '@wilson/interfaces';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { Observable, of } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { StringsParserService } from '../../services/strings-parser.service';

@Component({
  selector: 'wilson-timeline-filter-shifts-region-select',
  templateUrl: './timeline-filter-shifts-region-select.component.html',
  styleUrls: ['./timeline-filter-shifts-region-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    CommonModule,
    NzCheckboxModule,
    NzSwitchModule,
    ReactiveFormsModule,
    FormsModule,
    NzSelectModule,
  ],
})
export class TimelineFilterShiftsRegionSelectComponent
  extends BaseSelectFilterDirective<FilterOptions, string[]>
  implements OnInit
{
  protected initializeOptions(): Observable<FilterOptions[]> {
    return of([
      {
        id: TimelineActiveFilterRegionEnum.AssignedRegion,
        name: 'page.shift_timeline.filter.use_filter_on.assigned_shift',
      },
      {
        id: TimelineActiveFilterRegionEnum.UnassignedRegion,
        name: 'page.shift_timeline.filter.use_filter_on.unassigned_shift',
      },
    ]);
  }

  protected TimelineActiveFilterRegionEnum = TimelineActiveFilterRegionEnum;
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: StringsParserService,
  ) {
    super('timelineActiveFilterRegion');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  updateSelected(value: string[]): void {
    this.saveSelected(value);
  }
}
