<ng-container
  *ngIf="
    (('portal-mobile-setting-confirm-shift' | featureFlagPure | async) &&
      (canConfirmShift$ | async)) ||
    (('portal-mobile-settings-decline-shift' | featureFlagPure | async) &&
      (canDeclineShift$ | async))
  "
>
  <div>
    <nz-select
      nzMode="multiple"
      [nzPlaceHolder]="
        placeholder || ('shift.filter.confirm_decline.placeholder' | translate)
      "
      nzAllowClear
      [nzShowArrow]="true"
      (ngModelChange)="saveSelected($event)"
      [ngModel]="selected$ | async"
    >
      <nz-option
        *ngIf="
          ('portal-mobile-setting-confirm-shift' | featureFlagPure | async) &&
          (canConfirmShift$ | async)
        "
        [nzLabel]="'shift.filter.confirm_decline.confirmed_at' | translate"
        [nzValue]="'confirmed'"
      >
        {{ 'shift.filter.confirm_decline.confirmed_at' | translate }}
      </nz-option>
      <nz-option
        *ngIf="
          ('portal-mobile-settings-decline-shift' | featureFlagPure | async) &&
          (canDeclineShift$ | async)
        "
        [nzLabel]="'shift.filter.confirm_decline.declined_at' | translate"
        [nzValue]="'declined'"
      >
        {{ 'shift.filter.confirm_decline.declined_at' | translate }}
      </nz-option>
    </nz-select>
  </div>
  <wilson-filter-label
    *ngIf="showLabel"
    [label]="'shift.filter.confirm_decline.label' | translate"
  ></wilson-filter-label>
</ng-container>
