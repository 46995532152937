<div class="modal-title-wrapper">
  <p>
    {{ 'page.copy-agreement-modal.title' | translate }}
  </p>
  <p>
    {{ 'page.copy-agreement-modal.sub-title' | translate }}
  </p>
</div>
<div class="row mt-1 client-agreement-grid">
  <ng-container *ngIf="clients$ | async as clients; else loading">
    <ng-container *ngIf="clients.length; else emptyState">
      <ag-grid-angular
        class="ag-theme-wilson"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [components]="components"
        [rowData]="clients$ | async"
        [rowSelection]="'multiple'"
        [columnDefs]="columnDefs"
        (gridReady)="gridService.onGridReady($event)"
      >
      </ag-grid-angular>
    </ng-container>
  </ng-container>
  <ng-template #emptyState>
    <wilson-empty-state></wilson-empty-state>
  </ng-template>
  <ng-template #loading>
    <wilson-loading-state></wilson-loading-state>
  </ng-template>
</div>
