import { Injectable } from '@angular/core';
import {
  ExternalUser,
  SharedUsersWithYouPayload,
  SharedUsersWithYouService,
} from '@wilson/clients/services';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class ExternalCompanyUsersListService {
  private _updateSubject = new ReplaySubject<{
    result: ExternalUser;
    cellRendererParams: ICellRendererParams;
    success: boolean;
  }>(1);

  get updateSubject$(): Observable<{
    result: ExternalUser;
    cellRendererParams: ICellRendererParams;
    success: boolean;
  }> {
    return this._updateSubject.asObservable();
  }

  constructor(
    private readonly sharedUsersWithYouService: SharedUsersWithYouService,
  ) {}

  async updateSharedWithMeUser(
    cellRendererParams: ICellRendererParams,
    user: ExternalUser,
    payload: SharedUsersWithYouPayload,
  ) {
    try {
      const result = await this.sharedUsersWithYouService.updateUser(
        { id: user.id },
        payload,
      );
      this._updateSubject.next({
        result: {
          ...user,
          ...result,
        },
        cellRendererParams,
        success: true,
      });
    } catch (error) {
      this._updateSubject.next({
        result: user,
        cellRendererParams,
        success: false,
      });
    }
  }
}
