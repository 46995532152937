import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import {
  Activity,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { AssignShiftHelperService } from '@wilson/non-domain-specific/shift-helpers/core';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
@Component({
  selector: 'wilson-assign-single-shift-action',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    NzMenuModule,
    AuthorizationModule,
  ],
  templateUrl: './assign-single-shift-action.component.html',
  styleUrl: './assign-single-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignSingleShiftActionComponent {
  @Input({
    required: true,
  })
  disableAssignShift = false;

  @Input({
    required: true,
  })
  shift!: Shift & { activities: Activity[] };

  @Output() assignShiftSuccess = new EventEmitter();

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    private readonly assignShiftHelperService: AssignShiftHelperService,
  ) {}

  async assignShift() {
    const userId = await this.assignShiftHelperService.assignShifts([
      this.shift,
    ]);
    if (userId) {
      await this.assignShiftHelperService.updateShifts([this.shift], userId);
      this.assignShiftSuccess.emit();
    }
  }
}
