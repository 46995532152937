import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Subscription } from 'rxjs';
import { AgreementsFormService } from '../../../agreements-form.service';
import { AgreementRuleDirectiveDirective } from '../../directives/agreementRuleDirective.directive';
import { isActivityBonusByHourShiftAndActivityCategory } from '../../interfaces/activity-bonus-by-hour-shift-and-activity-category';
import { isActivityByCategoryFlatRate } from '../../interfaces/activity-by-category-flat-rate';
import { isActivityByTonnage } from '../../interfaces/activity-by-tonnage';
import { isActivityByWagonNumber } from '../../interfaces/activity-by-wagon-number';
import { isActivityFlatRate } from '../../interfaces/activity-flat-rate';
import { isActivityHourRate } from '../../interfaces/activity-hour-rate';
import { isAdHocShiftRate } from '../../interfaces/ad-hoc-shift-rate';
import { BillingRules } from '../../interfaces/billing-rules';
import { isFixedShiftCategoryRate } from '../../interfaces/fixed-shift-category-rate';
import { isShiftMinimumRate } from '../../interfaces/shift-minimum-rate';
import { isTimeFrameBonus } from '../../interfaces/time-frame-bonus';
import { isTimeFrameMinimumRate } from '../../interfaces/time-frame-minimum-rate';
import { ActivityBonusByHourShiftAndActivityCategoryComponent } from '../activity-bonus-by-hour-shift-and-activity-category/activity-bonus-by-hour-shift-and-activity-category.component';
import { ActivityByCategoryFlatRateComponent } from '../activity-by-category-flat-rate/activity-by-category-flat-rate.component';
import { ActivityByTonnageComponent } from '../activity-by-tonnage/activity-by-tonnage.component';
import { ActivityByWagonNumberComponent } from '../activity-by-wagon-number/activity-by-wagon-number.component';
import { ActivityFlatRateComponent } from '../activity-flat-rate/activity-flat-rate.component';
import { ActivityHourRateComponent } from '../activity-hour-rate/activity-hour-rate.component';
import { AdHocShiftRateComponent } from '../ad-hoc-shift-rate/ad-hoc-shift-rate.component';
import { FixedShiftCategoryRateComponent } from '../fixed-shift-category-rate/fixed-shift-category-rate.component';
import { RuleDescriptionComponent } from '../partials/rule-description/rule-description.component';
import { RuleLabelComponent } from '../partials/rule-label/rule-label.component';
import { ShiftMinimumRateComponent } from '../shift-minimum-rate/shift-minimum-rate.component';
import { TimeFrameBonusComponent } from '../time-frame-bonus/time-frame-bonus.component';
import { TimeFrameMinimumRateComponent } from '../time-frame-minimum-rate/time-frame-minimum-rate.component';

export interface UpdateRule {
  rule: BillingRules;
  index: number;
}

type RuleComponentTypes =
  | ActivityBonusByHourShiftAndActivityCategoryComponent
  | ActivityByCategoryFlatRateComponent
  | ActivityByTonnageComponent
  | ActivityByWagonNumberComponent
  | ActivityFlatRateComponent
  | ActivityHourRateComponent
  | AdHocShiftRateComponent
  | FixedShiftCategoryRateComponent
  | ShiftMinimumRateComponent
  | TimeFrameBonusComponent
  | TimeFrameMinimumRateComponent;

@Component({
  selector: 'wilson-generic-rule',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    FormsModule,
    AgreementRuleDirectiveDirective,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './generic-rule.component.html',
  styleUrl: './generic-rule.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericRuleComponent implements AfterViewInit, OnDestroy {
  @ViewChild(AgreementRuleDirectiveDirective, { static: false })
  wilsonAgreementRuleDirective!: AgreementRuleDirectiveDirective;
  @Input()
  rule: BillingRules;
  @Input()
  index: number;
  componentRef!: ComponentRef<RuleComponentTypes>;
  agreementRulesSubscription!: Subscription;
  updateRuleSubscription!: Subscription;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly agreementFormService: AgreementsFormService,
  ) {}

  ngAfterViewInit(): void {
    this.loadAgreementRuleSpecificComponent();
    this.initAgreementRulesSub();
  }

  ngOnDestroy(): void {
    if (this.updateRuleSubscription) {
      this.updateRuleSubscription.unsubscribe();
    }
    if (this.agreementRulesSubscription) {
      this.agreementRulesSubscription.unsubscribe();
    }
  }

  private initAgreementRulesSub() {
    if (this.agreementFormService.form.controls['rules']) {
      this.agreementRulesSubscription = this.agreementFormService.form.controls[
        'rules'
      ].valueChanges.subscribe((val) => {
        if (val && typeof val === 'string') {
          const ruleOfInterest = JSON.parse(val as string)[this.index];
          if (!isEqual(ruleOfInterest, this.rule)) {
            this.rule = ruleOfInterest;
            this.componentRef.instance.rule = ruleOfInterest;
            const childCdr = this.componentRef.injector.get(ChangeDetectorRef);
            this.cdr.detectChanges();
            childCdr.detectChanges();
          }
        }
      });
    }
  }

  private loadAgreementRuleSpecificComponent() {
    const viewContainerRef = this.wilsonAgreementRuleDirective.viewContainerRef;
    viewContainerRef.clear();

    if (isActivityBonusByHourShiftAndActivityCategory(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityBonusByHourShiftAndActivityCategoryComponent,
      );
    } else if (isActivityByCategoryFlatRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityByCategoryFlatRateComponent,
      );
    } else if (isActivityByTonnage(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityByTonnageComponent,
      );
    } else if (isActivityByWagonNumber(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityByWagonNumberComponent,
      );
    } else if (isActivityFlatRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityFlatRateComponent,
      );
    } else if (isActivityHourRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ActivityHourRateComponent,
      );
    } else if (isAdHocShiftRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        AdHocShiftRateComponent,
      );
    } else if (isFixedShiftCategoryRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        FixedShiftCategoryRateComponent,
      );
    } else if (isShiftMinimumRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        ShiftMinimumRateComponent,
      );
    } else if (isTimeFrameBonus(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        TimeFrameBonusComponent,
      );
    } else if (isTimeFrameMinimumRate(this.rule)) {
      this.componentRef = viewContainerRef.createComponent(
        TimeFrameMinimumRateComponent,
      );
    }

    if (this.componentRef) {
      this.componentRef.instance.rule = this.rule;
      this.componentRef.instance.index = this.index;
      this.updateRuleSubscription =
        this.componentRef.instance.updateRule.subscribe((args: UpdateRule) =>
          this.updateRule(args),
        );
      this.cdr.detectChanges();
    }
  }

  private updateRule(updateRule: UpdateRule) {
    const currentRulesString = this.agreementFormService.form.controls['rules']
      ?.value as string;
    if (currentRulesString) {
      const currentRulesArray = JSON.parse(
        currentRulesString,
      ) as BillingRules[];
      if (currentRulesArray && currentRulesArray[updateRule.index]) {
        currentRulesArray[updateRule.index] = updateRule.rule;
        this.agreementFormService.form.controls['rules']?.patchValue(
          JSON.stringify(currentRulesArray),
        );
        this.cdr.detectChanges();
      }
    }
  }

  public setRuleName(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.value) {
      this.rule.ruleName = input.value;
      this.updateRule({
        rule: this.rule,
        index: this.index,
      });
    }
  }
}
