import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserQualificationV2 } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  SharedUserQualificationDetailViewResponse,
  SharedUserQualificationsV2Gateway,
} from '../../gateways/shared-user-qualifications-v2.gateway';
import {
  GetSharedUserQualificationsV2Overview,
  GetSharedUserQualificationsV2OverviewDetails,
  OpenSharedUserQualificationV2OverviewDetailsDrawer,
} from './shared-user-qualifications-v2-overview.action';
import { UserQualificationsResponse } from '@wilson/qualification-v2/common';

export interface SharedUserQualificationsV2OverviewStateModel {
  selectedQualificationDetails: SharedUserQualificationDetailViewResponse | null;
  qualifications: UserQualificationV2[];
  totalRecords: number;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  isDrawerVisible: boolean;
}

@State<SharedUserQualificationsV2OverviewStateModel>({
  name: 'sharedUserQualificationsV2Overview',
  defaults: {
    selectedQualificationDetails: null,
    qualifications: [],
    totalRecords: 0,
    loading: false,
    currentPage: 1,
    pageSize: 10,
    isDrawerVisible: false,
  },
})
@Injectable()
export class SharedUserQualificationsV2OverviewState {
  constructor(
    private sharedUserQualificationsV2Gateway: SharedUserQualificationsV2Gateway,
  ) {}

  @Selector()
  static getSharedUserQualifications(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): UserQualificationV2[] {
    return state.qualifications;
  }

  @Selector()
  static getTotalRecords(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): number {
    return state.totalRecords;
  }

  @Selector()
  static getPageSize(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): number {
    return state.pageSize ?? 10;
  }

  @Selector()
  static isLoading(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): boolean {
    return state.loading;
  }

  @Selector()
  static sharedUserQualificationById(id: string) {
    return (
      state: SharedUserQualificationsV2OverviewStateModel,
    ): UserQualificationV2 | undefined => {
      return state.qualifications.find(
        (qualification) => qualification.id === id,
      );
    };
  }

  @Selector()
  static sharedUserQualificationByIdExists(id: string) {
    return (state: SharedUserQualificationsV2OverviewStateModel): boolean => {
      return state.qualifications.some(
        (qualification) => qualification.id === id,
      );
    };
  }

  @Selector()
  static getSharedUserQualificationDetails(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): SharedUserQualificationDetailViewResponse | null {
    return state.selectedQualificationDetails || null;
  }

  @Selector()
  static isDrawerVisible(
    state: SharedUserQualificationsV2OverviewStateModel,
  ): boolean {
    return state.isDrawerVisible;
  }

  @Action(GetSharedUserQualificationsV2Overview)
  getSharedUserQualifications(
    { patchState }: StateContext<SharedUserQualificationsV2OverviewStateModel>,
    { params }: GetSharedUserQualificationsV2Overview,
  ): Observable<UserQualificationsResponse> {
    patchState({ loading: true });

    return this.sharedUserQualificationsV2Gateway
      .getSharedUserQualifications(params)
      .pipe(
        tap((response) => {
          patchState({
            qualifications: response.data,
            totalRecords: response.pagination.totalRecords,
            currentPage: params.pageNumber,
            pageSize: params.pageSize,
            loading: false,
          });
        }),
        catchError((error) => {
          patchState({ loading: false });
          throw error;
        }),
      );
  }

  @Action(GetSharedUserQualificationsV2OverviewDetails)
  getSharedUserQualificationsV2OverviewDetails(
    {
      patchState,
      dispatch,
    }: StateContext<SharedUserQualificationsV2OverviewStateModel>,
    { userQualificationId }: GetSharedUserQualificationsV2OverviewDetails,
  ): Observable<SharedUserQualificationDetailViewResponse> {
    patchState({ loading: true });

    return this.sharedUserQualificationsV2Gateway
      .getSharedUserQualificationDetails(userQualificationId)
      .pipe(
        tap((response) => {
          patchState({
            selectedQualificationDetails: response || null,
            loading: false,
            isDrawerVisible: true,
          });
        }),
        catchError((error) => {
          console.error(error);
          patchState({ loading: false });
          throw error;
        }),
      );
  }

  @Action(OpenSharedUserQualificationV2OverviewDetailsDrawer)
  openSharedUserQualificationV2OverviewDetailsDrawer(
    { patchState }: StateContext<SharedUserQualificationsV2OverviewStateModel>,
    { isOpen }: OpenSharedUserQualificationV2OverviewDetailsDrawer,
  ): void {
    patchState({
      isDrawerVisible: isOpen,
    });
  }
}
