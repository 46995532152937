import { Pipe, PipeTransform } from '@angular/core';
import {
  UserQualificationV2,
  UserQualificationV2Status,
} from '@wilson/interfaces';

@Pipe({
  name: 'userQualificationV2IsExpired',
  standalone: true,
})
export class UserQualificationV2IsExpiredPipe implements PipeTransform {
  transform(userQualification: Pick<UserQualificationV2, 'status'>): boolean {
    return userQualification.status === UserQualificationV2Status.expired;
  }
}
