import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivitiesGateway } from '@wilson/api/gateway';
import { Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-activity-category-filter',
  templateUrl: './activity-category-filter.component.html',
  styleUrls: ['./activity-category-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoryFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showLabel = true;
  @Input() activityCategories: string[] = [];

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly activitiesService: ActivitiesGateway,
  ) {
    super('activityCategory');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.activitiesService.getEnabledActivityCategoriesWithTranslationKey() as Observable<
      FilterOptions[]
    >;
  }
}
