import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PayrollService } from '@wilson/api/gateway';
import {
  CorePayrollCategory,
  PayrollTransaction,
  PayrollTransactionStatus,
} from '@wilson/interfaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import { firstValueFrom } from 'rxjs';
import {
  CreatePayrollTransaction,
  UpdatePayrollTransaction,
} from '../payroll-transaction-list/payroll-transaction-list.component';
import { format } from 'date-fns';
import { DateTimeFormat } from '@wilson/utils';
import { AuthState } from '@wilson/auth/core';
import { Store } from '@ngxs/store';

@Injectable()
export class PayrollTransactionsHelperService {
  constructor(
    private readonly store: Store,
    private readonly payrollService: PayrollService,
    private readonly translate: TranslateService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  async createPayrollTransaction(
    payload: CreatePayrollTransaction,
    userId: string,
    shiftId: string | null,
    shiftDate: string,
    payrollTransactions: PayrollTransaction[],
    payrollCategories: CorePayrollCategory[],
  ) {
    const currentUserId = this.store.selectSnapshot(AuthState.userId);
    let isSuccessfullyCreated = false;
    try {
      const newTransactions = await firstValueFrom(
        this.payrollService.createPayrollTransactions([
          {
            absenceId: null,
            payrollCategoryId: payload.id,
            shiftId: shiftId,
            transactionDate: format(
              new Date(shiftDate),
              DateTimeFormat.DatabaseDateFormat,
            ),
            transactionStatus: PayrollTransactionStatus.Final,
            transactionValue: payload.value,
            userId: userId,
            createdByUserId: currentUserId,
          },
        ]),
      );
      payrollTransactions.push({
        ...newTransactions[0],
        payrollCategory: payrollCategories.find(
          (category) => category.id === newTransactions[0].payrollCategoryId,
        ),
      });
      isSuccessfullyCreated = true;
      this.nzMessageService.success(
        this.translate.instant('general.saved_successfully'),
      );
    } catch {
      isSuccessfullyCreated = false;
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
    return { payrollTransactions, isSuccessfullyCreated };
  }

  async updatePayrollTransaction(
    payload: UpdatePayrollTransaction,
    payrollTransactions: PayrollTransaction[],
    payrollCategories: CorePayrollCategory[],
  ) {
    let isSuccessfullyUpdated = false;
    try {
      await firstValueFrom(
        this.payrollService.updatePayrollTransactions(payload.transactionId, {
          payrollCategoryId: payload.id,
          transactionValue: Number(payload.value),
        }),
      );
      payrollTransactions[payload.updateIndex] = {
        ...payrollTransactions[payload.updateIndex],
        payrollCategoryId: payload.id,
        payrollCategory: payrollCategories.find(
          (category) => category.id === payload.id,
        ),
        transactionValue: Number(payload.value),
      };
      isSuccessfullyUpdated = true;
      this.nzMessageService.success(
        this.translate.instant('general.saved_successfully'),
      );
    } catch {
      isSuccessfullyUpdated = false;
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
    return { payrollTransactions, isSuccessfullyUpdated };
  }

  async deletePayrollTransaction(
    transactionId: string,
    payrollTransactions: PayrollTransaction[],
  ) {
    try {
      await firstValueFrom(
        this.payrollService.deletePayrollTransactions(transactionId),
      );
      payrollTransactions = payrollTransactions.filter(
        (payrollTransaction) => payrollTransaction.id !== transactionId,
      );
      this.nzMessageService.success(
        this.translate.instant('general.delete.successful'),
      );
    } catch {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
    return payrollTransactions;
  }
}
