import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'sortProfessionIds',
})
export class SortProfessionIdsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(professionsIds: string[]): string[] {
    if (!professionsIds && !professionsIds.length) {
      return professionsIds;
    }

    const sortedProfessionIds = [...professionsIds].sort((a, b) => {
      const translatedNameA = this.translateService.instant(`profession.${a}`);
      const translatedNameB = this.translateService.instant(`profession.${b}`);
      return translatedNameA.localeCompare(translatedNameB);
    });

    return sortedProfessionIds;
  }
}
