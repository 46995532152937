import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AgreementsFormService } from '../agreements-form.service';
import { Currency, CurrencySymbol } from '@wilson/invoicing/interfaces';

@Component({
  selector: 'wilson-agreements-form',
  templateUrl: './agreements-form.component.html',
  styleUrls: ['./agreements-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementsFormComponent {
  Currency = Currency;
  CurrencySymbol = CurrencySymbol;

  get selectedCurrency() {
    return this.agreementsFormService.form.controls.currency?.value;
  }

  constructor(public readonly agreementsFormService: AgreementsFormService) {}
}
