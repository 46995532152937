import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ShiftsService } from '@wilson/shifts';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { downloadFile } from '@wilson/utils';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { format } from 'date-fns';

@Component({
  selector: 'wilson-export-shift-action',
  standalone: true,
  imports: [
    AuthorizationModule,
    CommonModule,
    FeatureFlagsModule,
    NzMenuModule,
    TranslateModule,
  ],
  templateUrl: './export-shift-action.component.html',
  styleUrl: './export-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportShiftActionComponent {
  @Input({ required: true }) shift!: Shift;

  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly nzMessageService: NzMessageService,
    private readonly shiftsService: ShiftsService,
    private readonly translateService: TranslateService,
  ) {}

  async exportShift() {
    const loadingMessage = this.nzMessageService.loading(
      this.translateService.instant(
        'page.shift_details.export_shift_loading_message',
      ),
    );

    try {
      const blob = await firstValueFrom(
        this.shiftsService.getShiftExport(this.shift.id as string),
      );
      const fileName = [
        this.shift.name,
        format(new Date(this.shift.startDate), 'yyyyMMdd'),
      ].join('_');

      downloadFile(blob, fileName, 'pdf');

      this.nzMessageService.success(
        this.translateService.instant(
          'page.shift_details.export_shift_success_message',
        ),
      );
    } catch (error) {
      console.log(error);
      this.nzMessageService.error(
        this.translateService.instant(
          'page.shift_details.export_shift_error_message',
        ),
      );
    }

    this.nzMessageService.remove(loadingMessage.messageId);
  }
}
