import { Injectable } from '@angular/core';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable } from 'rxjs';

@Injectable()
export class SharedEmployeesSettingsDetailDrawerHelperService {
  @WilsonState<Date | null>(null)
  private refresh!: Date;
  refresh$!: Observable<Date | null>;

  refreshData() {
    this.refresh = new Date();
  }
}
