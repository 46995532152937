import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IsOneActivityStartedPipe } from '@wilson/activities/pipes';
import { AuthState } from '@wilson/auth/core';
import {
  PublicationStatus,
  QualificationCategory,
  QualificationSetting,
  Shift,
  ShiftState,
  UserQualification,
} from '@wilson/interfaces';
import { MasterDataState } from '@wilson/master-data/states';
import { QualificationSettingsService } from '@wilson/qualification-settings';
import { QualificationsService } from '@wilson/qualifications';
import { ResolvedShiftsState } from '@wilson/shifts-mobile';
import {
  endOfMonth,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { combineLatest, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeStateService {
  constructor(
    private readonly isOneActivityStartedPipe: IsOneActivityStartedPipe,
    private readonly qualificationsService: QualificationsService,
    private readonly qualificationSettingsService: QualificationSettingsService,
    private readonly store: Store,
  ) {}

  getQualifications() {
    return combineLatest([
      this.getQualificationCategories(),
      this.getQualificationSettings(),
      this.getUserQualifications(),
    ]).pipe(
      map(
        ([qualificationCategories, qualificationSettings, qualifications]) => {
          return qualifications
            .filter(this.qualificationsFilterFn)
            .sort(this.qualificationsSortFn)
            .map((qualification) => {
              return {
                ...qualification,
                qualificationCategory: qualificationCategories.find(
                  ({ id }) => id === qualification.qualificationCategoryId,
                ),
                qualificationSetting: qualificationSettings.find(
                  ({ qualificationCategoryId }) =>
                    qualificationCategoryId ===
                    qualification.qualificationCategoryId,
                ),
              };
            });
        },
      ),
    );
  }

  getShifts() {
    return this.store
      .select(ResolvedShiftsState.shiftsInDateRangeSelector)
      .pipe(
        map((shiftsInDateRangeFn) => {
          const startDate = startOfMonth(subMonths(new Date(), 1));
          const endDate = endOfMonth(new Date());

          const shifts = shiftsInDateRangeFn(
            startDate,
            endDate,
            this.store.selectSnapshot(AuthState.userId),
          )
            .filter(this.shiftsFilterFn)
            .sort(this.shiftsSortFn);

          return shifts;
        }),
      );
  }

  private getQualificationCategories(): Observable<QualificationCategory[]> {
    return this.store.select(MasterDataState.qualificationCategories);
  }

  private getQualificationSettings(): Observable<QualificationSetting[]> {
    const organizationalUnitId = this.store.selectSnapshot(
      AuthState.organizationalUnitId,
    );
    if (!organizationalUnitId) return of([]);
    return this.qualificationSettingsService.searchQualificationSettings({
      organizationalUnitId,
    });
  }

  private getUserQualifications(): Observable<UserQualification[]> {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (!userId) return of([]);
    return this.qualificationsService.getResolvedUserQualifications(userId);
  }

  private qualificationsFilterFn(qualification: UserQualification) {
    return (
      !qualification.signedByUser &&
      isBefore(new Date(), new Date(qualification.validTill))
    );
  }

  private qualificationsSortFn = (
    qualificationA: UserQualification,
    qualificationB: UserQualification,
  ) => {
    return isAfter(
      new Date(qualificationB.validFrom),
      new Date(qualificationA.validFrom),
    )
      ? 1
      : -1;
  };

  private shiftsFilterFn = (shift: Shift) => {
    const isFutureShift = isBefore(
      new Date(),
      new Date(shift.activities[shift.activities.length - 1].endDatetime),
    );
    const shiftStates = [ShiftState.NotSubmitted, ShiftState.Reopened];

    return (
      !isFutureShift &&
      shiftStates.includes(shift.state) &&
      !this.isOneActivityStartedPipe.transform(shift.activities)
    );
  };

  private shiftsSortFn = (shiftA: Shift, shiftB: Shift) => {
    return isAfter(new Date(shiftB.startDate), new Date(shiftA.startDate))
      ? 1
      : -1;
  };
}
