@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ;
<li
  nz-menu-item
  [nzDisabled]="disableAssignShift || !hasShiftUpdatePermissions"
  nz-tooltip
  [nzTooltipTitle]="
    !hasShiftUpdatePermissions
      ? ('general.forbidden_action' | translate)
      : disableAssignShift
      ? ('shift.page.edit_shift_not_allowed' | translate)
      : ''
  "
  (click)="!disableAssignShift && assignShift()"
  data-test-id="wilson-assign-single-shift-action:button"
>
  {{ 'shift.page.assign_selected' | translate }}
</li>
