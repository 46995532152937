import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  UserQualificationV2,
  UserQualificationV2Attachment,
  UserQualificationV2History,
  UserQualificationV2WithRelation,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

interface GetSharedUserQualificationsParams {
  pageNumber: number;
  pageSize: number;
  relations: string[];
  userIds?: string[];
  qualificationIds?: string[];
  organizationalUnitId?: string[];
  status?: string[];
  search?: string;
  lang: string;
  userQualificationId?: string;
  order?: Record<string, 'asc' | 'desc'>;
}

export interface SharedUserQualificationsResponse {
  data: UserQualificationV2[];
  pagination: {
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    numberOfPages: number;
  };
}

export interface SharedUserQualificationsWithRelationsResponse {
  data: UserQualificationV2WithRelation[];
  pagination: {
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    numberOfPages: number;
  };
}

export interface SharedUserQualificationDetailViewResponse {
  id: string;
  userId: string;
  validFrom: string;
  validTill: string;
  confirmedByUser: boolean;
  updatedAt: string | null;
  acquiredAt: string;
  notes: string | null;
  user: {
    firstName: string;
    lastName: string;
  };
  qualification: {
    nameDe: string;
    nameEn: string;
    nameFr: string;
    namePl: string;
    route?: {
      name: string;
      startLocation: { name: string; locationCode: string | null };
      endLocation: { name: string; locationCode: string | null };
      viaLocations: { name: string; locationCode: string }[];
    } | null;
  };
  partnerName: string;
  userQualificationAttachments: UserQualificationV2Attachment[];
  userQualificationHistories: UserQualificationV2History[];
}

@Injectable({
  providedIn: 'root',
})
export class SharedUserQualificationsV2Gateway {
  protected readonly path = 'v2/shared/users';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getSharedUserQualifications(
    options: GetSharedUserQualificationsParams,
  ): Observable<SharedUserQualificationsResponse> {
    const { pageNumber, pageSize, relations, lang, order, ...filters } =
      options;

    const queryParams = {
      pageNumber,
      pageSize,
      relations,
      lang,
      order,
      query: {
        ...filters,
        ...(options.userQualificationId
          ? { id: options.userQualificationId }
          : {}),
      },
    };

    const queryString = stringify(queryParams, {
      arrayFormat: 'brackets',
      addQueryPrefix: true,
    });

    return this.http.get<SharedUserQualificationsResponse>(
      `${this.config.host}/${this.path}/qualifications${queryString}`,
    );
  }

  getSharedUserQualificationDetails(userQualificationId: string) {
    return this.http.get<SharedUserQualificationDetailViewResponse>(
      `${this.config.host}/${this.path}/qualifications/${userQualificationId}`,
    );
  }

  exportZipQualifications(
    userId: string,
    lang: string,
  ): Observable<ArrayBuffer> {
    const queryParams = stringify({ lang }, { addQueryPrefix: true });

    return this.http.get(
      `${this.config.host}/${this.path}/${userId}/qualifications/pdf/zip${queryParams}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  exportComplementaryCertificate(
    userId: string,
    lang: string,
  ): Observable<ArrayBuffer> {
    const queryParams = stringify({ lang }, { addQueryPrefix: true });

    return this.http.get(
      `${this.config.host}/${this.path}/${userId}/qualifications/certificate${queryParams}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }
}
