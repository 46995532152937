import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { BaseFilterDirective } from '../../base-filter.directive';
import { FilterKey } from '../../filter.keys.type';
import { FilterService } from '../../filter.service';
import { StringParserService } from '../../services/string-parser.service';

@Component({
  selector: 'wilson-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent
  extends BaseFilterDirective<string>
  implements OnInit
{
  @Input()
  label!: string;

  @Input()
  showLabel = true;

  @Input()
  placeholder!: string;

  @Input()
  debounceTime = 0;

  @Input()
  set filterKey(value: FilterKey) {
    this.filterKeySubject.next(value);
  }

  private filterKeySubject!: BehaviorSubject<FilterKey>;
  private searchTermSubject = new BehaviorSubject<string>('');

  isFocusing = false;
  currentSearchTerm$!: Observable<string | null>;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: StringParserService,
  ) {
    const subject = new BehaviorSubject<FilterKey>('search');
    super(subject.asObservable());
    this.filterKeySubject = subject;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currentSearchTerm$ = combineLatest([
      this.selected$,
      this.searchTermSubject,
      this.filterService.isFiltered,
    ]).pipe(
      map(([selectedValue, recentSearchTerm, isFiltering]) => {
        if (isFiltering) {
          return this.isFocusing ? recentSearchTerm : selectedValue;
        } else {
          return null;
        }
      }),
    );
  }

  updateSearchTermAndSaveSelected(value: string) {
    this.searchTermSubject.next(value);
    this.saveSelected(value);
  }
}
