import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';

/**
 * This is a leaf component for the search filter component that has little logic in it.
 * It is meant to be used as is and/or extended upon to add functionality such as tracking search term in URL query param.
 */
@Component({
  selector: 'wilson-basic-search-filter',
  templateUrl: './basic-search-filter.component.html',
  styleUrls: ['./basic-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicSearchFilterComponent implements OnInit, OnDestroy {
  @Input()
  debounceTime = 0;

  @Input()
  label!: string;

  @Input()
  placeholder!: string;

  @Input()
  value!: string;

  @Output()
  isFocusing = new EventEmitter<boolean>(false);

  @Output()
  inputChanged = new EventEmitter<string>();

  public searchTermSubject = new Subject<string>();

  private searchTermDebounceSubscription?: Subscription;

  ngOnInit() {
    this.searchTermDebounceSubscription = this.searchTermSubject
      .pipe(debounceTime(this.debounceTime))
      .subscribe((searchTerm) => {
        this.inputChanged.emit(searchTerm);
      });
  }

  ngOnDestroy() {
    this.searchTermDebounceSubscription?.unsubscribe();
  }
}
