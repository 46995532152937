import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Profession } from '@wilson/interfaces';

@Pipe({
  name: 'sortProfessions',
})
export class SortProfessionsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(professions: Profession[]): Profession[] {
    if (!professions && !professions.length) {
      return professions;
    }

    const sortedProfessions = [...professions].sort((a, b) => {
      const translatedNameA = this.translateService.instant(
        `profession.${a.id}`,
      );
      const translatedNameB = this.translateService.instant(
        `profession.${b.id}`,
      );
      return translatedNameA.localeCompare(translatedNameB);
    });

    return sortedProfessions;
  }
}
