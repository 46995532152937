import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueFormatterService } from './value-formatter.service';
import { LinkRendererService } from './link-renderer.service';

@NgModule({
  imports: [CommonModule],
  providers: [ValueFormatterService, LinkRendererService],
})
export class AgGridServiceModule {}
