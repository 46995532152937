import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wilson-view-shared-user-professions',
  templateUrl: './view-shared-user-professions.component.html',
  styleUrls: ['./view-shared-user-professions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSharedUserProfessionsComponent {
  @Input()
  professionIds: string[];
}
