@let hasPayrollDeletePermission = !!(RoleAction.Delete | ablePure :
RolePermissionSubject.Payroll | async); @let hasPayrollUpdatePermission =
!!(RoleAction.Update | ablePure : RolePermissionSubject.Payroll | async); @let
forbiddenMessage = ('general.forbidden_action' | translate); @for (transaction
of payrollTransactions; track $index) { @if (editTransactionIndex === $index) {
<div
  class="row payroll-row"
  [class.created-by-user]="transaction.createdByUserId"
>
  <div [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-4' : 'col-5'">
    <nz-select
      nzShowSearch
      [compareWith]="compareFn"
      [(ngModel)]="selectedPayrollCategoryId"
      [nzPlaceHolder]="'general.select_value' | translate"
      [nzDropdownMatchSelectWidth]="false"
      [nzDropdownStyle]="nzWidthConfig"
    >
      <nz-option
        *ngFor="let category of payrollCategories"
        [nzLabel]="category.translatedName"
        [nzValue]="category.id"
      ></nz-option>
    </nz-select>
  </div>
  <div [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-3' : 'col-5'">
    <input
      type="number"
      class="form-control rounded-0"
      [(ngModel)]="transactionValue"
      [placeholder]="'general.enter_value' | translate"
    />
  </div>
  @if(isShiftOrAbsenceColumnVisible) {
  <div class="col-3"></div>
  }
  <div class="col-2 d-flex align-items-center justify-content-center">
    <fa-icon
      class="red-icon"
      [icon]="faTimes"
      (click)="editTransactionIndex = -1"
    ></fa-icon>
    @if (isUpdatingTransaction) {
    <wilson-loading-spinner [showSmallSpinner]="true"></wilson-loading-spinner>
    } @else {
    <fa-icon
      class="green-icon"
      [icon]="faCheck"
      (click)="updatePayrollTransaction(transaction.id, $index)"
    ></fa-icon>
    }
  </div>
</div>
} @else {
<div
  class="row payroll-row"
  [class.created-by-user]="transaction.createdByUserId"
>
  <div
    class="normal-text category-text text-truncate"
    [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-4' : 'col-5'"
  >
    <span
      nz-tooltip
      [nzTooltipTitle]="transaction.payrollCategory?.translatedName"
      [nzTooltipColor]="'#fff'"
    >
      {{ transaction.payrollCategory?.translatedName }}
    </span>
  </div>
  <div
    class="normal-text unit-text"
    [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-3' : 'col-5'"
  >
    {{ transaction.transactionValue }}
  </div>
  @if(isShiftOrAbsenceColumnVisible) {
  <div class="col-3 normal-text unit-text">
    @if(transaction.shift) {
    <a
      class="link-dark"
      [href]="'/shifts/' + transaction.shiftId"
      target="_blank"
    >
      {{ transaction.shift.name }}
    </a>
    } @else if(transaction.absence && transaction.absence.absenceCategory) {
    {{
      'enum.absence_category.' + transaction.absence.absenceCategory.name
        | translate
    }}
    }
  </div>
  }
  <div class="col-2 d-flex align-items-center justify-content-center">
    @if (transaction.createdByUserId) { @if (deletingId === transaction.id) {
    <wilson-loading-spinner [showSmallSpinner]="true"></wilson-loading-spinner>
    } @else {
    <button
      class="p-0"
      nz-button
      nzType="text"
      [disabled]="!hasPayrollDeletePermission"
      nz-tooltip
      [nzTooltipTitle]="!hasPayrollDeletePermission ? forbiddenMessage : ''"
      nz-popconfirm
      [nzPopconfirmTitle]="
        'page.invoicing.delete_popconfirm_message' | translate
      "
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="deletePayrollTransaction(transaction.id)"
      data-test-id="wilson-payroll-transaction-list:delete-button"
    >
      <fa-icon class="red-icon" [icon]="faTrash"></fa-icon>
    </button>
    }
    <button
      class="p-0"
      nz-button
      nzType="text"
      [disabled]="!hasPayrollUpdatePermission"
      nz-tooltip
      [nzTooltipTitle]="!hasPayrollUpdatePermission ? forbiddenMessage : ''"
      (click)="editTransaction(transaction, $index)"
      data-test-id="wilson-payroll-transaction-list:edit-button"
    >
      <fa-icon class="gray-icon" [icon]="faEdit"></fa-icon>
    </button>
    }
  </div>
</div>
}
<nz-divider class="my-0"></nz-divider>
} @if (isAddingNewTransaction) {
<div class="row payroll-row created-by-user">
  <div [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-4' : 'col-5'">
    <nz-select
      nzShowSearch
      [(ngModel)]="selectedPayrollCategoryId"
      [nzPlaceHolder]="'general.select_value' | translate"
      [nzDropdownMatchSelectWidth]="false"
      [nzDropdownStyle]="nzWidthConfig"
    >
      <nz-option
        *ngFor="let category of payrollCategories"
        [nzLabel]="category.translatedName"
        [nzValue]="category.id"
      ></nz-option>
    </nz-select>
  </div>
  <div [ngClass]="isShiftOrAbsenceColumnVisible ? 'col-3' : 'col-5'">
    <input
      type="number"
      class="form-control rounded-0"
      [(ngModel)]="transactionValue"
      [placeholder]="'general.enter_value' | translate"
    />
  </div>
  @if(isShiftOrAbsenceColumnVisible) {
  <div class="col-3"></div>
  }
  <div class="col-2 d-flex align-items-center justify-content-center">
    <fa-icon
      class="red-icon"
      [icon]="faTimes"
      (click)="setCreateMode.emit(false)"
    ></fa-icon>
    @if (isUpdatingTransaction) {
    <wilson-loading-spinner [showSmallSpinner]="true"></wilson-loading-spinner>
    } @else {
    <fa-icon
      class="green-icon"
      [icon]="faCheck"
      (click)="createPayrollTransaction()"
    ></fa-icon>
    }
  </div>
</div>
<nz-divider class="my-0"></nz-divider>
}
