import { ExternalUser, UserSharedByMe } from '@wilson/clients/services';

export const sharedAtComparator = (
  valueA: UserSharedByMe | ExternalUser,
  valueB: UserSharedByMe | ExternalUser,
  nodeA: unknown,
  nodeB: unknown,
  isDescending: boolean,
) => {
  const sharedAtA =
    'sharedUsers' in valueA
      ? valueA.sharedUsers?.[0]?.sharedAt
      : valueA.sharedAt;
  const sharedAtB =
    'sharedUsers' in valueB
      ? valueB.sharedUsers?.[0]?.sharedAt
      : valueB.sharedAt;

  if (!sharedAtA && !sharedAtB) return 0;

  // Put null or undefined sharedAt values at the end for both ascending and descending sorts
  if (!sharedAtA) return isDescending ? -1 : 1;
  if (!sharedAtB) return isDescending ? 1 : -1;

  return sharedAtA.localeCompare(sharedAtB);
};
