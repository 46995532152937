<div class="w-100">
  <nz-form-item>
    @if(!hideLabel) {
    <nz-form-label class="quali-form-label" nzNoColon>
      {{ label || ('general.qualifications_category' | translate) }}
    </nz-form-label>
    }
    <nz-form-control>
      <nz-select
        [required]="isRequired"
        [nzPlaceHolder]="
          placeholder
            ? placeholder
            : ('general.qualifications_category' | translate)
        "
        nzShowSearch
        nzAllowClear
        [nzMode]="showMultiple ? 'multiple' : 'default'"
        [disabled]="isDisabled"
        [ngModel]="selectedCategoryIds"
        (ngModelChange)="updateSelectedCategory($event)"
      >
        <ng-container *ngIf="categoryOptions$ | async as categoryOptions">
          <nz-option
            *ngFor="let category of categoryOptions"
            [nzValue]="category.id"
            [nzLabel]="category.translatedName"
          >
          </nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div>
