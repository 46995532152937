import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PublicationStatus } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { StringParserService } from '../../services/string-parser.service';

@Component({
  selector: 'wilson-release-status-filter',
  templateUrl: './release-status-filter.component.html',
  styleUrls: ['./release-status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseStatusFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string
> {
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: StringParserService,
  ) {
    super('releaseStatus');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return of([
      {
        id: PublicationStatus.Published,
        name: 'page.shifts.publishing_states.published',
      },
      {
        id: PublicationStatus.NotPublished,
        name: 'page.shifts.publishing_states.unpublished',
      },
    ]);
  }
}
