import { Injectable } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Injectable()
export class ValueFormatterService {
  formatEmptyValue(params: ValueFormatterParams<string>): string {
    if (!params.value) return '---';
    return params.value;
  }

  dateFormatter(params: ValueFormatterParams): string {
    if (!params.value) return '---';
    return format(new Date(params.value), 'dd.MM.yyyy');
  }

  arrayPrintFormatter(params: ValueFormatterParams): string {
    if (!params.value || params.value.length === 0) return '---';
    return params.value.join(', ');
  }
}
