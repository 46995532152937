import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { isPresignedUrlExpired } from '@wilson/files';
import { UserQualificationV2Attachment } from '@wilson/interfaces';
import {
  ResetUserQualificationsV2Attachments,
  SetUserQualificationsV2Attachments,
  SetUserQualificationsV2AttachmentsLoading,
  UpsertUserQualificationsV2Attachments,
} from './user-qualifications-v2-attachments.action';

export interface UserQualificationsV2AttachmentsStateModel {
  attachments: UserQualificationV2Attachment[];
  loading: boolean;
}
type StateModel = UserQualificationsV2AttachmentsStateModel;

const USER_QUALIFICATIONS_V2_ATTACHMENTS_STATE =
  'userQualificationsV2Attachments';
const defaults: StateModel = {
  attachments: [],
  loading: false,
};

@State<UserQualificationsV2AttachmentsStateModel>({
  name: USER_QUALIFICATIONS_V2_ATTACHMENTS_STATE,
  defaults: defaults,
})
@Injectable()
export class UserQualificationsV2AttachmentsState
  implements NgxsAfterBootstrap
{
  constructor(private readonly storage: Storage) {}

  async ngxsAfterBootstrap(ctx: StateContext<StateModel>): Promise<void> {
    const storageState = await this.storage.get(
      USER_QUALIFICATIONS_V2_ATTACHMENTS_STATE,
    );
    if (storageState) {
      ctx.patchState({
        attachments: storageState,
      });
      this.removeAttachmentsWithExpiredPresignedUrls(ctx);
    }
  }

  @Selector()
  static getState(state: StateModel): StateModel {
    return state;
  }

  @Selector()
  static isLoading(state: StateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getAttachments(state: StateModel): UserQualificationV2Attachment[] {
    return state.attachments;
  }

  static attachmentsByUserQualificationId(
    userQualificationId: string,
  ): (state: StateModel) => UserQualificationV2Attachment[] {
    return createSelector(
      [UserQualificationsV2AttachmentsState],
      (state: StateModel) => {
        return state.attachments.filter(
          (attachment) =>
            attachment.userQualificationId === userQualificationId,
        );
      },
    );
  }

  static attachmentById(
    attachmentId: string,
  ): (state: StateModel) => UserQualificationV2Attachment | undefined {
    return createSelector(
      [UserQualificationsV2AttachmentsState],
      (state: StateModel) => {
        return state.attachments.find(
          (attachment) => attachment.id === attachmentId,
        );
      },
    );
  }

  static attachmentByIdExists(
    attachmentId: string,
  ): (state: StateModel) => boolean {
    return createSelector(
      [UserQualificationsV2AttachmentsState],
      (state: StateModel) => {
        return state.attachments.some(
          (attachment) => attachment.id === attachmentId,
        );
      },
    );
  }

  @Action(SetUserQualificationsV2AttachmentsLoading)
  setUserQualificationsV2AttachmentsLoading(
    { patchState }: StateContext<StateModel>,
    action: SetUserQualificationsV2AttachmentsLoading,
  ): void {
    patchState({
      loading: action.isLoading,
    });
  }

  @Action(ResetUserQualificationsV2Attachments)
  resetUserQualificationsV2Attachments({
    setState,
    getState,
  }: StateContext<StateModel>): void {
    setState(defaults);
    this.updateStorage(getState());
  }

  @Action(SetUserQualificationsV2Attachments)
  setUserQualificationsV2Attachments(
    ctx: StateContext<StateModel>,
    action: SetUserQualificationsV2Attachments,
  ): void {
    ctx.patchState({
      attachments: action.setAttachments,
    });
    this.removeAttachmentsWithExpiredPresignedUrls(ctx);
    this.updateStorage(ctx.getState());
  }

  @Action(UpsertUserQualificationsV2Attachments)
  upsertUserQualificationsV2Attachments(
    ctx: StateContext<StateModel>,
    action: UpsertUserQualificationsV2Attachments,
  ): void {
    ctx.setState(
      patch({
        attachments: (
          existingAttachments: ReadonlyArray<UserQualificationV2Attachment>,
        ) => {
          const updatedAndExistingAttachments = existingAttachments.map(
            (existingAttachment) => {
              const updatedAttachment = action.upsertAttachments.find(
                (upsertAttachment) =>
                  upsertAttachment.id === existingAttachment.id,
              );
              return updatedAttachment ? updatedAttachment : existingAttachment;
            },
          );

          const newAttachments = action.upsertAttachments.filter(
            (upsertAttachment) =>
              !existingAttachments.some(
                (existingAttachment) =>
                  existingAttachment.id === upsertAttachment.id,
              ),
          );

          return [...updatedAndExistingAttachments, ...newAttachments];
        },
      }),
    );

    this.removeAttachmentsWithExpiredPresignedUrls(ctx);
    this.updateStorage(ctx.getState());
  }

  private updateStorage(state: StateModel): void {
    this.storage.set(
      USER_QUALIFICATIONS_V2_ATTACHMENTS_STATE,
      state.attachments,
    );
  }

  private removeAttachmentsWithExpiredPresignedUrls(
    ctx: StateContext<StateModel>,
  ): void {
    const attachments = ctx.getState().attachments;
    const attachmentsWithValidPresignedUrls = attachments.filter(
      (attachment) => {
        return !isPresignedUrlExpired(attachment.s3Path);
      },
    );
    ctx.patchState({
      attachments: attachmentsWithValidPresignedUrls,
    });
  }
}
