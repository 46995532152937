@let isShiftSubmittedOrAccepted = shift?.state | isShiftSubmittedOrAccepted;
@let canShiftBeDeleted = isShiftSubmittedOrAccepted | shiftDeletionEvaluator |
async;
<ng-container>
  <li
    nz-menu-item
    [nzDisabled]="!canShiftBeDeleted"
    nz-tooltip
    [nzTooltipPlacement]="'left'"
    [nzTooltipTitle]="
      canShiftBeDeleted
        ? ''
        : ('common.cannot_delete_shift.tooltip' | translate)
    "
    (click)="
      canShiftBeDeleted && deleteButtonClicked(isShiftSubmittedOrAccepted)
    "
    data-test-id="wilson-delete-shift-action:button"
  >
    {{ 'page.shift_timeline.drawer.shift_form.delete_shift' | translate }}
  </li>
</ng-container>
