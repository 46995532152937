<p>
  {{ 'agreements.modal.save_and_apply_text' | translate }}
</p>
<div class="row mt-1 agreement-grid">
  <ag-grid-angular
    class="ag-theme-wilson"
    [gridOptions]="gridOptions"
    [defaultColDef]="defaultColDef"
    [rowData]="agreementsWithClientName$ | async"
    [rowSelection]="'multiple'"
    [columnDefs]="columnDefs"
    (gridReady)="gridService.onGridReady($event)"
  >
  </ag-grid-angular>
</div>
