import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { BaseFilterDirective } from '../../base-filter.directive';
import { FilterService } from '../../filter.service';
import { BooleanParserService } from '../../services/boolean-parser.service';
@Component({
  selector: 'wilson-toggle-highlight-filter',
  templateUrl: './toggle-highlight-filter.component.html',
  styleUrls: ['./toggle-highlight-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, FormsModule, CommonModule, NzSwitchModule],
})
export class ToggleHighlightFilterComponent
  extends BaseFilterDirective<boolean>
  implements OnInit, OnDestroy
{
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: BooleanParserService,
  ) {
    super('renderUnmatchedElements');
  }

  private subscriptions = new Subscription();
  private DEBOUNCE_TIME_IN_MS = 300;

  private toggleSwitchSubject = new Subject<boolean>();

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.add(
      this.toggleSwitchSubject
        .pipe(debounceTime(this.DEBOUNCE_TIME_IN_MS))
        .subscribe((value) => {
          this.saveSelected(value);
        }),
    );
  }

  protected toggleSetting(value: boolean): void {
    this.toggleSwitchSubject.next(value);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
