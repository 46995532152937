<wilson-page-frame>
  <wilson-clients-form mainContent></wilson-clients-form>

  <button
    nz-button
    nzType="primary"
    class="text-dark p-1 w-100 mb-2 fw-normal"
    (click)="clientsFormService.submitForm()"
    [disabled]="
      (clientsFormService.isSaving$ | async) ||
      (clientsFormService.form.invalid$ | async) ||
      (RoleAction.Create | ablePure : RolePermissionSubject.Client | async) ===
        false
    "
  >
    <span
      class="spinner-border spinner-border-sm me-1"
      role="status"
      aria-hidden="true"
      *ngIf="clientsFormService.isSaving$ | async"
    ></span>
    {{
      ((clientsFormService.isSaving$ | async)
        ? 'general.saving'
        : 'general.save'
      ) | translate
    }}
  </button>
  <button
    nz-button
    nzType="default"
    class="text-dark p-1 w-100 mb-2 fw-normal"
    [routerLink]="'..'"
  >
    {{ 'general.cancel' | translate }}
  </button>
</wilson-page-frame>
