import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BillingRuleTimeFrameBonus } from '../../interfaces/time-frame-bonus';
import { IsoWeekdaysComponent } from '../partials/iso-weekdays/iso-weekdays.component';
import { OnHolidayRegionComponent } from '../partials/on-holiday-region/on-holiday-region.component';
import {
  PricePerUnitComponent,
  UpdatePricePerUnit,
} from '../partials/price-per-unit/price-per-unit.component';
import { RoundUpAfterComponent } from '../partials/round-up-after/round-up-after.component';
import { RuleDescriptionComponent } from '../partials/rule-description/rule-description.component';
import { RuleLabelComponent } from '../partials/rule-label/rule-label.component';
import { RuleNameComponent } from '../partials/rule-name/rule-name.component';
import { TimeBonusCalculationMethodComponent } from '../partials/time-bonus-calculation-method/time-bonus-calculation-method.component';
import { TimeFrameComponent } from '../partials/time-frame/time-frame.component';
import { ActivityCategoriesComponent } from '../partials/activity-categories/activity-categories.component';
import { PriorityComponent } from '../partials/priority/priority.component';

@Component({
  selector: 'wilson-time-frame-bonus',
  standalone: true,
  imports: [
    CommonModule,
    TimeBonusCalculationMethodComponent,
    RuleLabelComponent,
    RuleDescriptionComponent,
    RuleNameComponent,
    IsoWeekdaysComponent,
    OnHolidayRegionComponent,
    TimeFrameComponent,
    RoundUpAfterComponent,
    PricePerUnitComponent,
    ActivityCategoriesComponent,
    PriorityComponent,
  ],
  templateUrl: './time-frame-bonus.component.html',
  styleUrl: './time-frame-bonus.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFrameBonusComponent {
  @Input() rule: BillingRuleTimeFrameBonus;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleTimeFrameBonus;
    index: number;
  }>();

  setRuleName({ ruleName, index }: { ruleName: string; index: number }) {
    this.rule.params.bonuses[index].ruleName = ruleName;
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }

  setPricePerUnit(update: UpdatePricePerUnit) {
    if (update.index) {
      this.rule.params.bonuses[update.index].pricePerUnit = update.pricePerUnit;
      this.updateRule.emit({
        rule: this.rule,
        index: this.index,
      });
    }
  }
}
