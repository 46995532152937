import { UserQualificationV2 } from '@wilson/interfaces';

export class GetUserQualificationsV2Overview {
  static readonly type = '[UserQualificationsV2] Get User Qualifications';
  constructor(
    public readonly params: {
      pageNumber: number;
      pageSize: number;
      relations: string[];
      lang: string;
      userIds?: string[];
      qualificationCategoryIds?: string[];
      userOrganizationalUnitIds?: string[];
      statuses?: string[];
      searchText?: string;
    },
  ) {}
}

export class ResetUserQualificationsV2OverviewDetails {
  static readonly type =
    '[UserQualificationsV2] Reset UserQualifications Details';
}

export class GetUserQualificationsV2OverviewDetails {
  static readonly type =
    '[UserQualificationsV2] Get User Qualifications details';
  constructor(
    public readonly userQualificationId: string,
    public readonly userId: string,
  ) {}
}

export class UpsertUserQualificationsV2Overview {
  static readonly type = '[QualificationsV2] Upsert Qualifications';
  constructor(public readonly qualifications: UserQualificationV2[]) {}
}

export class OpenUserQualificationV2OverviewDetailsDrawer {
  static readonly type = '[UserQualificationsV2] Open Qualification Drawer';
  constructor(public readonly isOpen: boolean) {}
}
