import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import { GridService } from '@wilson/ag-grid/service';
import { Agreement } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { ColDef } from 'ag-grid-community';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Observable, map } from 'rxjs';
import { AgreementsFormService } from '../agreements-form.service';

export interface ApplyToOtherAgreementsModalInput {
  editAgreementId?: string;
}

@Component({
  selector: 'wilson-apply-to-other-agreements-modal',
  templateUrl: './apply-to-other-agreements-modal.component.html',
  styleUrls: ['./apply-to-other-agreements-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AgreementsFormService],
})
export class ApplyToOtherAgreementsModalComponent implements OnInit {
  @Input() editAgreementId!: string | undefined;
  agreementsWithClientName$!: Observable<Agreement & { clientName?: string }[]>;

  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = {
    ...defaultGridOptionsFactory(),
    pagination: false,
  };

  protected columnDefs: ColDef[] = [
    {
      resizable: false,
      sortable: false,
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      field: 'name',
      sort: 'asc',
      width: 300,
      sortable: true,
      headerName: this.translate.instant('general.name'),
    },
    {
      field: 'clientName',
      flex: 1,
      sortable: true,
      headerName: this.translate.instant('page.invoicing.client_name'),
    },
  ];
  constructor(
    public readonly agreementsFormService: AgreementsFormService,
    @Inject(NZ_MODAL_DATA) private data: ApplyToOtherAgreementsModalInput,
    public readonly gridService: GridService<Agreement>,
    private readonly clientsService: ClientsV2Service,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.editAgreementId = this.data.editAgreementId;
    this.agreementsWithClientName$ = this.clientsService
      .getMany({ relations: ['agreements'], limit: 0 })
      .pipe(
        map((clients) => {
          const localAgreementsWithClientsName: Agreement &
            { clientName?: string }[] = [] as unknown as Agreement &
            { clientName?: string }[];
          clients.data.forEach((client) => {
            client.agreements?.forEach((agreement) => {
              if (this.editAgreementId !== agreement.id) {
                const agreementWithClientName: Agreement & {
                  clientName?: string;
                } = agreement;
                agreementWithClientName.clientName = client.name;
                localAgreementsWithClientsName.push(agreementWithClientName);
              }
            });
          });
          return localAgreementsWithClientsName;
        }),
      );
  }

  getSelectedAgreements(): Agreement[] | undefined {
    return this.gridService.gridApi?.getSelectedRows().map((row) => {
      delete row.clientName;
      return row;
    });
  }
}
