import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { RuleLabelComponent } from '../rule-label/rule-label.component';

@Component({
  selector: 'wilson-rule-name',
  standalone: true,
  imports: [
    CommonModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
    NzInputModule,
  ],
  templateUrl: './rule-name.component.html',
  styleUrl: './rule-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleNameComponent {
  @Input() ruleName: string;
  @Input() index: number;
  @Input() customLabel!: string;
  @Input() customDescription!: string;
  @Output() updateRuleName = new EventEmitter<{
    ruleName: string;
    index: number;
  }>();

  setRuleName(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.value) {
      this.ruleName = input.value;
      this.updateRuleName.emit({
        ruleName: this.ruleName,
        index: this.index,
      });
    }
  }
}
