import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Activity,
  ActivityCategoriesIdsMap,
  GeoLocation,
  OperationStatus,
  RoleAction,
  RolePermissionSubject,
  Shift,
  ShiftCategoriesIdsMap,
  ShiftState,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ShiftOperationsService } from '@wilson/non-domain-specific/shift-helpers/core';
import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { LocationsStoreService } from '@wilson/locations';
import { createFullActivityObject } from '@wilson/non-domain-specific/activities-helpers/services';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthorizationModule } from '@wilson/authorization';
import { captureException } from '@sentry/capacitor';

@Component({
  selector: 'wilson-convert-to-standby-shift-action',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    TranslateModule,
    NzToolTipModule,
    AuthorizationModule,
  ],
  templateUrl: './convert-to-standby-shift-action.component.html',
  styleUrl: './convert-to-standby-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvertToStandbyShiftActionComponent {
  @Input({
    required: true,
  })
  shift!: Shift & { activities: Activity[] };
  public ShiftState = ShiftState;
  public WilsonApp = WilsonApp;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  @Output() updateSuccessful = new EventEmitter<Shift>();

  constructor(
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
    private readonly shiftOperationsService: ShiftOperationsService,
    private readonly shiftsService: ShiftsService,
    private readonly message: NzMessageService,
    private readonly locationStore: LocationsStoreService,
  ) {}

  openModal(isDisabled: boolean): void {
    if (!isDisabled) {
      this.modalService.confirm({
        nzTitle: this.translate.instant(
          'shift.turn_shift_to_dispo_shift.modal_title',
        ),
        nzContent: this.translate.instant(
          'shift.turn_shift_to_dispo_shift.description',
        ),
        nzOkText: this.translate.instant('general.yes'),
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCancelText: this.translate.instant('general.no'),
        nzOnOk: async () => {
          const dispoShift = await this.convertToDispoShift(
            this.shift.activities,
          );
          try {
            await firstValueFrom(this.shiftsService.updateShift(dispoShift));
            this.updateSuccessful.emit(dispoShift);
            this.message.success(
              this.translate.instant('general.saved_successfully'),
            );
          } catch (error) {
            captureException(error);
            this.message.error(
              this.translate.instant('general.something_went_wrong'),
            );
          }
        },
      });
    }
  }

  private async convertToDispoShift(activities: Activity[]): Promise<Shift> {
    const shiftStartDateTime = activities[0].startDatetime;
    const shiftEndDateTime = activities[activities.length - 1].endDatetime;
    const shiftLocation = await firstValueFrom(
      this.locationStore.getLocationFromCache(
        activities[0].startLocationId as string,
      ),
    );

    const existingPauseActivities = activities.filter(
      (a) => a.activityCategoryId === ActivityCategoriesIdsMap.Break,
    );

    const newActivities: Activity[] = [];

    const clonedShift = cloneDeep(this.shift);

    this.shiftOperationsService.cleanUpShiftObject(clonedShift);

    if (existingPauseActivities.length) {
      existingPauseActivities.forEach((pause, index) => {
        const isFirst = index === 0;
        const isLast = index === existingPauseActivities.length - 1;
        const previousPauseEnd =
          existingPauseActivities[index - 1]?.endDatetime;

        if (isFirst) {
          newActivities.push(
            this.addDispositionActivity(
              shiftStartDateTime,
              pause.startDatetime,
              shiftLocation,
            ),
          );
        } else {
          newActivities.push(
            this.addDispositionActivity(
              previousPauseEnd,
              pause.startDatetime,
              shiftLocation,
            ),
          );
        }

        newActivities.push(this.addPauseActivity(pause, shiftLocation));

        if (isLast) {
          newActivities.push(
            this.addDispositionActivity(
              pause.endDatetime,
              shiftEndDateTime,
              shiftLocation,
            ),
          );
        }
      });
    } else {
      newActivities.push(
        this.addDispositionActivity(
          shiftStartDateTime,
          shiftEndDateTime,
          shiftLocation,
        ),
      );
    }

    return {
      ...clonedShift,
      shiftCategoryId: ShiftCategoriesIdsMap.Normal,
      activities: newActivities,
    };
  }

  private addDispositionActivity(
    start: string,
    end: string,
    shiftLocation: GeoLocation,
  ): Activity {
    return this.createActivity(
      ActivityCategoriesIdsMap.Disposition,
      start,
      shiftLocation,
      end,
      shiftLocation,
    );
  }

  private addPauseActivity(
    pause: Activity,
    shiftLocation: GeoLocation,
  ): Activity {
    return {
      ...pause,
      startLocation: shiftLocation,
      startLocationId: shiftLocation.id as string,
      endLocation: shiftLocation,
      endLocationId: shiftLocation.id as string,
    };
  }

  private createActivity(
    activityCategoryId: string,
    startDatetime: string,
    startLocation: GeoLocation,
    endDatetime: string,
    endLocation: GeoLocation,
  ): Activity {
    return createFullActivityObject({
      activityCategoryId,
      createdFrom: WilsonApp.Portal,
      endDatetime,
      endLocationId: endLocation.id as string,
      endLocation,
      jobId: null,
      name: '',
      operationalStatus: OperationStatus.NotStarted,
      professionId: null,
      serviceId: null,
      shiftId: this.shift.id as string,
      startDatetime,
      startLocationId: startLocation.id as string,
      startLocation,
      viaLocationIds: [],
    });
  }
}
