import { BillingRule } from './billing-rules';

export interface BillingRuleActivityFlatRate extends BillingRule {
  calculatorName: 'ActivityFlatRate';
  params: {
    pricePerUnit: number;
  };
}

export function isActivityFlatRate(
  obj: BillingRule,
): obj is BillingRuleActivityFlatRate {
  return obj.calculatorName === 'ActivityFlatRate';
}
