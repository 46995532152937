@let hasSharedUserUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.SharedUser | async);

<ng-container
  *ngIf="professionIds && user && params && professions$ | async as professions"
>
  <nz-select
    (ngModelChange)="professionsChanged($event)"
    [ngModel]="professionIds | sortProfessionIds"
    [nzAllowClear]="true"
    [nzPlaceHolder]="
      'client.shared_with_me.set_professions.select.placeholder' | translate
    "
    (nzOpenChange)="onOpenChange($event)"
    nzMode="multiple"
    [nzDisabled]="!hasSharedUserUpdatePermission"
    nz-tooltip
    [nzTooltipTitle]="
      !hasSharedUserUpdatePermission
        ? ('general.forbidden_action' | translate)
        : ''
    "
  >
    <nz-option
      *ngFor="let profession of professions | sortProfessions"
      [nzLabel]="'profession.' + profession.id | translate"
      [nzValue]="profession.id"
    ></nz-option>
  </nz-select>
</ng-container>
