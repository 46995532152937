import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleLabelComponent } from '../rule-label/rule-label.component';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'wilson-exclude-holidays',
  standalone: true,
  imports: [
    CommonModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
    NzSwitchModule,
    FormsModule,
  ],
  templateUrl: './exclude-holidays.component.html',
  styleUrl: './exclude-holidays.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcludeHolidaysComponent {
  @Input() excludeHolidays: boolean;
  @Input() customLabel!: string;
  @Input() customDescription!: string;
}
