import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { GetQualificationV2 } from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

interface FindQualificationDto {
  id?: string;
  name?: string;
  qualificationCategoryIds?: string[];
  sectorIds?: string[];
}

interface OrderQualificationDto {
  name?: 'asc' | 'desc';
}

export interface GetQualificationsDto {
  findOptions?: FindQualificationDto;
  orderOptions?: OrderQualificationDto;
  lang?: 'de' | 'en' | 'fr' | 'pl';
  pageNumber?: number;
  pageSize?: number;
}

@Injectable({
  providedIn: 'root',
})
export class QualificationV2Service {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getQualifications(
    dto?: GetQualificationsDto,
  ): Observable<GetQualificationV2> {
    let params: any = {};
    if (dto?.findOptions) {
      params.query = dto.findOptions;
    }
    if (dto?.orderOptions) {
      params.order = dto.orderOptions;
    }
    if (dto?.lang) {
      params.lang = dto.lang;
    }
    if (dto?.pageNumber != null) {
      params.pageNumber = dto.pageNumber.toString();
    }
    if (dto?.pageSize != null) {
      params.pageSize = dto.pageSize.toString();
    }

    params = stringify(params, {
      arrayFormat: 'indices',
    });

    return this.http.get<GetQualificationV2>(
      `${this.config.host}/qualifications?${params}`,
    );
  }
}
