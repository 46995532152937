<div class="toggle-container mb-2">
  <div class="form-title text-dark">
    {{ 'shift.filter.show_unmatched' | translate }}
  </div>

  <div class="toggle-container">
    <nz-switch
      data-test-id="toggle-highlight-filter:switch"
      class="mb-2"
      [ngModel]="selected$ | async"
      (ngModelChange)="toggleSetting($event)"
    ></nz-switch>
  </div>
</div>
