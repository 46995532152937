import { Base } from '@wilson/base';
import { InvoiceItem } from './invoice-item';

export interface CreateInvoicePdfPayload extends Base {
  clientName: string;
  clientNumber: string;
  clientEmail: string;
  taxId: string;
  contactPersonProvider: string;
  contactPersonClient: string;
  invoiceAddress: string;
  currency: string;
  vat: string;
  invoiceNumber: string;
  serviceTimeframe: string;
  purchaseOrder: string;
  companyCode: string;
  furtherRemarks: string;
  advancePayment: string;
  invoiceItems: InvoiceItem[];
  activityIds?: string[];
  invoiceSubject?: string;
  invoiceText?: string;
  paymentMethod: string;
  status: InvoiceStatus;
  daysToPayInvoice: number;
  discountDays: number;
  discountPercentage: number;
  invoiceDate: string;
  clientId: string;
  canEditInvoice?: boolean;
  externalOrderId: string;
  serviceStartDatetime: string;
  serviceEndDatetime: string;
  lexofficeId?: string | null;
}

export interface InvoiceDTO extends CreateInvoicePdfPayload {
  id: string;
  status: InvoiceStatus;
  clientId: string;
  invoiceDate: string;
  daysToPayInvoice: number;
  paymentMethod: string;
  invoiceSubject: string;
  invoiceText?: string;
}

export interface CreateInvoiceDTO extends CreateInvoicePdfPayload {
  status: InvoiceStatus;
  clientId: string;
  activityIds: string[];
}

export interface UpdateInvoiceDTO extends CreateInvoicePdfPayload {
  id: string;
  status: InvoiceStatus;
  clientId: string;
}

export enum InvoiceStatus {
  Open = 'open',
  Draft = 'draft',
  Overdue = 'overdue',
  Paid = 'paid',
  Cancelled = 'cancelled',
}
