<div>
  @let selected = selected$ | async;
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'page.shift_timeline.filter.use_filter_on' | translate"
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected"
    data-test-id="timeline-filter-shifts-region-select:select"
  >
    <nz-option
      *ngFor="let region of options$ | async"
      [nzLabel]="region.name | translate"
      [nzValue]="region.id"
      [nzDisabled]="selected.length === 1 && selected[0] === region.id"
      [attr.data-test-id]="
        'timeline-filter-shifts-region-select:select:option:' + region.id
      "
    >
      <span>
        {{ region.name | translate }}
      </span>
    </nz-option>
  </nz-select>
</div>
