import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClientsFormService } from '../clients-form.service';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-new-clients',
  templateUrl: './new-clients.component.html',
  styleUrls: ['./new-clients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ClientsFormService],
})
export class NewClientsComponent {
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;
  constructor(public readonly clientsFormService: ClientsFormService) {}
}
