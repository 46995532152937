import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import * as SentryAngular from '@sentry/angular';
import { AccountModule, UsersState } from '@wilson/account';
import { ActivitiesModule } from '@wilson/activities';
import { AuthCoreModule, AuthInterceptor, AuthState } from '@wilson/auth/core';
import { AuthorizationModule, PermissionsState } from '@wilson/authorization';
import { CompanySettingsCoreModule } from '@wilson/company-settings/core';
import { ConfigModule } from '@wilson/config';
import { ContractsModule } from '@wilson/contracts';
import { FeatureFlagsModule, FeatureFlagsService } from '@wilson/feature-flags';
import { FilesModule } from '@wilson/files';
import { storageAndAuthStateInitializer } from '@wilson/ionic/storage';
import { LangState } from '@wilson/languages';
import { LocationsModule, LocationState } from '@wilson/locations';
import {
  CustomLocationsState,
  FavoriteLocationsState,
  LocationSelectionHistoryState,
} from '@wilson/locations-v2/state';
import { MasterDataState } from '@wilson/master-data/states';
import { MobileCompanySettingsState } from '@wilson/mobile-company-settings';
import { FavoriteActivityCategoriesState } from '@wilson/non-domain-specific/activities-helpers/states';
import { NotificationsModule } from '@wilson/notifications';
import { OrganizationalUnitModule } from '@wilson/organizational-unit';
import {
  LocationPreferencesState,
  PreferenceSettingsState,
  ShiftTimePreferenceSettingState,
  UserGeneralPreferencesState,
  UserLocationPreferencesState,
  UserPreferencesState,
  UserShiftTimePreferenceState,
  UserTimePreferencesState,
  UserVehiclePreferencesState,
  VehiclePreferencesState,
} from '@wilson/preferences/state';
import { ProfitabilityScoreState } from '@wilson/profitability-score/state';
import { ProjectsState } from '@wilson/projects/state';
import { QualificationCategoriesModule } from '@wilson/qualification-categories';
import { QualificationSettingsModule } from '@wilson/qualification-settings';
import {
  QualificationsV2CategoriesState,
  QualificationsV2State,
  UserQualificationsV2AttachmentsState,
  UserQualificationsV2State,
} from '@wilson/qualification-v2';
import { QualificationsModule } from '@wilson/qualifications';
import { ShiftCategoriesState } from '@wilson/shift-category';
import { ShiftsModule } from '@wilson/shifts';
import { ResolvedShiftsState, ShiftsMobileModule } from '@wilson/shifts-mobile';
import { TalkJsConfigToken } from '@wilson/talkjs/services';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { ToastrModule } from 'ngx-toastr';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerFactory } from './app.init';
import { HttpLoaderFactory } from './http-loader.factory';
import { backgroundTaskInitializer } from './initializer/background-task.initializer';
import { JwtOptionsFactory } from './jwt-options.factory';
import { OperativeReportsState } from './modules/operative-reports/state/operative-reports.state';
import { VersionUpdateModule } from './modules/version-update/version-update.module';
import { HomeState } from './pages/home/store/home.state';
import { CalendarNavigationService } from './services/calendar-navigation.service';
import { CalendarTasksService } from './services/calendar-tasks.service';
import { CalendarService } from './services/calendar.service';
import { StorageService } from './services/storage.service';
import { DevState } from './store/dev/dev.state';
import { DeviceState } from './store/device/device.state';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot(
      [
        AuthState,
        DeviceState,
        HomeState,
        PermissionsState,
        UserPreferencesState,
        UserGeneralPreferencesState,
        LangState,
        LocationPreferencesState,
        MobileCompanySettingsState,
        MasterDataState,
        ResolvedShiftsState,
        OperativeReportsState,
        UserLocationPreferencesState,
        PreferenceSettingsState,
        VehiclePreferencesState,
        UserVehiclePreferencesState,
        UserTimePreferencesState,
        UserShiftTimePreferenceState,
        ShiftTimePreferenceSettingState,
        ShiftCategoriesState,
        CustomLocationsState,
        FavoriteLocationsState,
        ProjectsState,
        ProfitabilityScoreState,
        UsersState,
        FavoriteActivityCategoriesState,
        LocationSelectionHistoryState,
        DevState,
        LocationState,
        UserQualificationsV2State,
        QualificationsV2State,
        QualificationsV2CategoriesState,
        UserQualificationsV2AttachmentsState,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsStoragePluginModule.forRoot({
      keys: [
        DeviceState,
        LangState,
        PermissionsState,
        LocationState,
        UserQualificationsV2State,
        QualificationsV2State,
        QualificationsV2CategoriesState,
        UserQualificationsV2AttachmentsState,
      ],
    }),
    NzMessageModule,
    AccountModule,
    ActivitiesModule,
    AppRoutingModule,
    AuthCoreModule,
    AuthorizationModule,
    BrowserModule,
    CompanySettingsCoreModule,
    ContractsModule,
    FilesModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({ swipeBackEnabled: false }),
    LocationsModule,
    NotificationsModule,
    OrganizationalUnitModule,
    QualificationsModule,
    QualificationCategoriesModule,
    QualificationSettingsModule,
    ShiftsModule,
    ShiftsMobileModule,
    PdfViewerModule,
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    ConfigModule.forRoot({
      host: environment.backend.host,
      application: environment.application,
    }),
    FeatureFlagsModule.forRoot({
      clientId: environment.launchDarklyClientId,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: JwtOptionsFactory,
        deps: [Store],
      },
    }),
    VersionUpdateModule,
    MatomoModule.forRoot({
      disabled: environment.matomo.disabled,
      trackerUrl: environment.matomo.trackerUrl,
      siteId: environment.matomo.siteId,
    }),
    MatomoRouterModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    DatePipe,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    Diagnostic,
    OpenNativeSettings,
    CalendarService,
    CalendarTasksService,
    CalendarNavigationService,
    {
      provide: TalkJsConfigToken,
      useValue: environment.talkJsConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: storageAndAuthStateInitializer,
      deps: [Storage, Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: backgroundTaskInitializer,
      deps: [Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, Store, FeatureFlagsService, Platform, StorageService],
      multi: true,
    },
    FormBuilder,
    provideHttpClient(withInterceptorsFromDi()),
    withNgxsResetPlugin(),
  ],
})
export class AppModule {}
