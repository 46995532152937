import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-rule-description',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './rule-description.component.html',
  styleUrl: './rule-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleDescriptionComponent {
  @Input() customDescription!: string;
}
