@let hasShiftCreatePermissions = (RoleAction.Create | ablePure :
RolePermissionSubject.Shift | async);
<li
  nz-tooltip
  [nzTooltipTitle]="
    !hasShiftCreatePermissions ? ('general.forbidden_action' | translate) : ''
  "
  [nzDisabled]="!hasShiftCreatePermissions"
  data-test-id="wilson-duplicate-shift-action:button"
  nz-menu-item
  (click)="duplicateShift()"
>
  {{ 'page.shift_details.duplicate_shift' | translate }}
</li>
