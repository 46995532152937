import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import {
  CorePayrollCategory,
  PayrollTransaction,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { LoadingSpinnerModule } from '@wilson/shared-components';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

export interface CreatePayrollTransaction {
  id: string;
  value: number;
}

export interface UpdatePayrollTransaction {
  id: string;
  value: number;
  updateIndex: number;
  transactionId: string;
}
@Component({
  selector: 'wilson-payroll-transaction-list',
  templateUrl: './payroll-transaction-list.component.html',
  styleUrls: ['./payroll-transaction-list.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzDividerModule,
    FontAwesomeModule,
    NzSelectModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzSpinModule,
    LoadingSpinnerModule,
    AuthorizationModule,
    NzButtonModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrollTransactionListComponent {
  @Input() payrollTransactions!: PayrollTransaction[] | null;
  @Input() payrollCategories!: CorePayrollCategory[] | null;
  @Input() deletingId: string | null = null;
  @Input() set isAddingNewTransaction(isEnable: boolean) {
    if (isEnable) {
      this.selectedPayrollCategoryId = null;
      this.transactionValue = null;
      this._isAddingNewTransaction = true;
      this.editTransactionIndex = -1;
    } else {
      this._isAddingNewTransaction = false;
    }
    this.cdRef.detectChanges();
  }
  get isAddingNewTransaction(): boolean {
    return this._isAddingNewTransaction;
  }
  @Input() isUpdatingTransaction!: boolean;
  @Input() set resetEditTransaction(date: Date) {
    this.editTransactionIndex = -1;
  }
  @Input() isShiftOrAbsenceColumnVisible = false;

  @Output() setCreateMode = new EventEmitter<boolean>();
  @Output() create = new EventEmitter<CreatePayrollTransaction>();
  @Output() update = new EventEmitter<UpdatePayrollTransaction>();
  @Output() delete = new EventEmitter<string>();

  public faTimes = faTimes;
  public faCheck = faCheck;
  public faTrash = faTrash;
  public faEdit = faEdit;

  public editTransactionIndex = -1;
  public selectedPayrollCategoryId!: string | null;
  public transactionValue!: number | null;
  public nzWidthConfig = { width: '240px' };

  private _isAddingNewTransaction!: boolean;
  private _isUpdatingTransaction!: boolean;

  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  editTransaction(transaction: PayrollTransaction, index: number): void {
    this.selectedPayrollCategoryId = transaction.payrollCategoryId;
    if (transaction.payrollCategory && this.payrollCategories) {
      this.payrollCategories.push(transaction.payrollCategory);
    }
    this.transactionValue = transaction.transactionValue;
    this.editTransactionIndex = index;
    this.setCreateMode.emit(false);
    this.cdRef.detectChanges();
  }

  async createPayrollTransaction(): Promise<void> {
    if (this.selectedPayrollCategoryId && this.transactionValue) {
      this.create.emit({
        id: this.selectedPayrollCategoryId,
        value: this.transactionValue,
      });
    }
  }

  async updatePayrollTransaction(
    transactionId: string | undefined,
    index: number,
  ): Promise<void> {
    if (
      this.selectedPayrollCategoryId &&
      this.transactionValue &&
      transactionId
    ) {
      this.update.emit({
        id: this.selectedPayrollCategoryId,
        value: this.transactionValue,
        updateIndex: index,
        transactionId,
      });
    }
  }

  async deletePayrollTransaction(
    transactionId: string | undefined,
  ): Promise<void> {
    if (transactionId) {
      this.delete.emit(transactionId);
    }
  }

  compareFn = (selectedCategory: string, category: string): boolean =>
    selectedCategory === category;
}
