<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="placeholder || ('service.shift_orgunit' | translate)"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="organizational-unit-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name"
      [nzValue]="item.id"
      [attr.data-test-id]="
        'organizational-unit-filter:select:option:' + item.id
      "
    ></nz-option>
  </nz-select>
</div>
