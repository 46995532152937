import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { UserQualificationV2Attachment } from '@wilson/interfaces';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { DateFnsModule } from 'ngx-date-fns';

@Component({
  selector: 'wilson-user-qualification-v2-details-attachment-section',
  standalone: true,
  imports: [
    CommonModule,
    DateFnsModule,
    FontAwesomeModule,
    NzCollapseModule,
    TranslateModule,
  ],
  templateUrl:
    './user-qualification-v2-details-attachment-section.component.html',
  styleUrl: './user-qualification-v2-details-attachment-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserQualificationV2DetailsAttachmentSectionComponent {
  @Input() userQualificationAttachments!:
    | UserQualificationV2Attachment[]
    | undefined;

  faPaperClip = faPaperclip;
}
