import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterKey } from '../../filter.keys.type';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-organizational-unit-filter',
  templateUrl: './organizational-unit-filter.component.html',
  styleUrls: ['./organizational-unit-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationalUnitFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() placeholder!: string;
  @Input()
  set filterKey(value: FilterKey) {
    this.filterKeySubject.next(value);
  }

  private filterKeySubject!: BehaviorSubject<FilterKey>;

  constructor(
    protected readonly filterService: FilterService,
    private readonly store: Store,
    protected readonly parserService: MultiSelectParserService,
  ) {
    const subject = new BehaviorSubject<FilterKey>('orgUnit');
    super(subject.asObservable());
    this.filterKeySubject = subject;
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.store.select(AuthState.accessibleOrgUnits) as Observable<
      FilterOptions[]
    >;
  }
}
