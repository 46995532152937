import { BillingRule } from './billing-rules';
import { BillingRuleShiftMinimumRateActivityCategoryOption } from './shift-minimum-rate';

export interface BillingRuleTimeFrameMinimumRate extends BillingRule {
  calculatorName: 'TimeFrameMinimumRate';
  params: {
    pricePerUnit: number;
    timeFrameFillUpTo: number;
    shiftFillUpTo: number;
    activityCategoryOptions: BillingRuleTimeFrameMinimumRateActivityCategoryOption[];
    absenceCategories: string[];
    excludeHolidays: boolean;
    frequency: BillingRuleTimeFrameMinimumRateFrequency;
  };
}

export interface BillingRuleTimeFrameMinimumRateActivityCategoryOption
  extends BillingRuleShiftMinimumRateActivityCategoryOption {
  pricePerUnit: number;
}

export interface BillingRuleTimeFrameMinimumRateFrequency {
  unit: 'month' | 'week';
  number: number;
}

export function isTimeFrameMinimumRate(
  obj: BillingRule,
): obj is BillingRuleTimeFrameMinimumRate {
  return obj.calculatorName === 'TimeFrameMinimumRate';
}
