import { QualificationCategoryV2 } from '@wilson/interfaces';

export class ResetQualificationsV2Categories {
  static readonly type = '[QualificationsV2Category] reset state';
}

export class InitQualificationsV2Categories {
  static readonly type = '[QualificationsV2Category] init categories';
}

export class SetQualificationsV2Categories {
  static readonly type = '[QualificationsV2Category] set state to categories';
  constructor(public setCategories: QualificationCategoryV2[]) {}
}
