import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { PipesModule } from '@wilson/pipes';
import { QualificationCategoriesService } from '@wilson/qualification-categories';
import { ShiftPlanService } from '@wilson/shift-plan/services';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { AbsencesFilterComponent } from './filter-components/absences-filter/absences-filter.component';
import { AbsencesStatusFilterComponent } from './filter-components/absences-status-filter/absences-stauts-filter.component';
import { AcceptanceStatusFilterComponent } from './filter-components/acceptance-status-filter/acceptance-status-filter.component';
import { AcceptedJobsFilterComponent } from './filter-components/accepted-jobs-filter/accepted-jobs-filter.component';
import { ActivityAssignmentStatusFilterComponent } from './filter-components/activity-assignment-status-filter/activity-assignment-status-filter.component';
import { ActivityCategoryFilterComponent } from './filter-components/activity-category-filter/activity-category-filter.component';
import { ActivityOperationalStatusFilterComponent } from './filter-components/activity-operational-status-filter';
import { ActivityStatusFilterComponent } from './filter-components/activity-status-filter/activity-status-filter.component';
import { AgreementFilterComponent } from './filter-components/agreement-filter/agreement-filter.component';
import { BasicSearchFilterComponent } from './filter-components/basic-search-filter/basic-search-filter.component';
import { ClearFilterButtonComponent } from './filter-components/clear-filter-button/clear-filter-button.component';
import { ClearFilterComponent } from './filter-components/clear-filter/clear-filter.component';
import { ClientsFilterComponent } from './filter-components/clients-filter/clients-filter.component';
import { DateFilterComponent } from './filter-components/date-filter/date-filter.component';
import { DateRangeFilterComponent } from './filter-components/date-range-filter/date-range-filter.component';
import { DateRangeFilterService } from './filter-components/date-range-filter/date-range-filter.service';
import { DeviationFilterComponent } from './filter-components/deviation-filter/deviation-filter.component';
import { EmployeeOrganizationalUnitFilterComponent } from './filter-components/employee-organizational-unit-filter/employee-organizational-unit-filter.component';
import { FilterDividerComponent } from './filter-components/filter-divider/filter-divider.component';
import { FilterLabelComponent } from './filter-components/filter-label/filter-label.component';
import { InvoiceStatusFilterComponent } from './filter-components/invoice-status-filter/invoice-status-filter.component';
import { LabelFilterComponent } from './filter-components/labels-filter/labels-filter.component';
import { MonthFilterComponent } from './filter-components/month-filter/month-filter.component';
import { MonthRangeFilterComponent } from './filter-components/month-range-filter/month-range-filter.component';
import { OperationalStatusFilterComponent } from './filter-components/operational-status-filter/operational-status-filter.component';
import { OperationsBoardShiftStatusFilterComponent } from './filter-components/operations-board-shift-status-filter/operations-board-shift-status-filter.component';
import { OperationsBoardShiftStatusTimeframeFilterComponent } from './filter-components/operations-board-shift-status-timeframe-filter/operations-board-shift-status-timeframe-filter.component';
import { OperationsBoardShiftStatusTimeframeFilterPipe } from './filter-components/operations-board-shift-status-timeframe-filter/operations-board-shift-status-timeframe-filter.pipe';
import { OrganizationalUnitFilterComponent } from './filter-components/organizational-unit-filter/organizational-unit-filter.component';
import { PartnersFilterComponent } from './filter-components/partners-filter/partners-filter.component';
import { PriorityFilterComponent } from './filter-components/priority-filter/priority-filter.component';
import { ProcessingStatusFilterComponent } from './filter-components/processing-status-filter/processing-status-filter.component';
import { ProfessionFilterComponent } from './filter-components/profession-filter/profession-filter.component';
import { ProjectStatusFilterComponent } from './filter-components/project-status-filter/project-status-filter.component';
import { ProjectsFilterComponent } from './filter-components/projects-filter/projects-filter.component';
import { QualificationCategoryComponent } from './filter-components/qualification-category/qualification-category.component';
import { QualificationStatusComponent } from './filter-components/qualification-status/qualification-status.component';
import { QuickFilterComponent } from './filter-components/quick-filter/quick-filter.component';
import { ReleaseStatusFilterComponent } from './filter-components/release-status-filter/release-status-filter.component';
import { SearchFilterComponent } from './filter-components/search-filter/search-filter.component';
import { SegmentFilterComponent } from './filter-components/segment-filter/segment-filter.component';
import { ServiceActivitiesFilterComponent } from './filter-components/service-activities-filter/service-activities-filter.component';
import { ServicesFilterComponent } from './filter-components/services-filter/services-filter.component';
import { SharedActivityFilterComponent } from './filter-components/shared-activity-filter/shared-activity-filter.component';
import { ShiftActivityStatusFilterComponent } from './filter-components/shift-activity-status-filter/shift-activity-status-filter.component';
import { ShiftCategoryFilterComponent } from './filter-components/shift-category-filter/shift-category-filter.component';
import { ShiftConfirmDeclineFilterComponent } from './filter-components/shift-confirm-decline-filter/shift-confirm-decline-filter.component';
import { ShiftDateRangeFilterComponent } from './filter-components/shift-date-range-filter';
import { ShiftHeaderFilterComponent } from './filter-components/shift-header-filter/shift-header-filter.component';
import { ShiftPlanStatusFilterComponent } from './filter-components/shift-plan-status-filter/shift-plan-status-filter.component';
import { ShiftPlansFilterComponent } from './filter-components/shift-plans-filter/shift-plans-filter.component';
import { StayStatusFilterComponent } from './filter-components/stay-status-filter/stay-status-filter.component';
import { UpcomingUnstaffedServicesFilterComponent } from './filter-components/upcoming-unstaffed-services-filter/upcoming-unstaffed-services-filter.component';
import { UpcomingUnstaffedShiftFilterComponent } from './filter-components/upcoming-unstaffed-shift-filter/upcoming-unstaffed-shift-filter.component';
import { UserLabelFilterComponent } from './filter-components/user-labels-filter/user-labels-filter.component';
import { UserRoleFilterComponent } from './filter-components/user-role-filter/user-role-filter.component';
import { UsersListFilterComponent } from './filter-components/users-list-filter/users-list-filter.component';
import { WeekFilterComponent } from './filter-components/week-filter/week-filter.component';
import { FilterService } from './filter.service';
import { InvoiceActivityStatusFilterComponent } from './invoice-activity-status-filter/invoice-activity-status-filter.component';

@NgModule({
  imports: [
    AuthorizationModule,
    CommonModule,
    NzSelectModule,
    NzDatePickerModule,
    NzInputModule,
    FormsModule,
    TranslateModule,
    NzDropDownModule,
    NzToolTipModule,
    NzDividerModule,
    NzTreeSelectModule,
    FeatureFlagsModule,
    PipesModule,
    NzButtonModule,
    FilterLabelComponent,
    NzIconModule,
    FontAwesomeModule,
    NzTagModule,
    ShiftConfirmDeclineFilterComponent,
    NzSegmentedModule,
  ],
  declarations: [
    PartnersFilterComponent,
    DateFilterComponent,
    OrganizationalUnitFilterComponent,
    ClearFilterComponent,
    SearchFilterComponent,
    UserRoleFilterComponent,
    AbsencesFilterComponent,
    ReleaseStatusFilterComponent,
    QualificationCategoryComponent,
    QualificationStatusComponent,
    DeviationFilterComponent,
    DateRangeFilterComponent,
    ShiftDateRangeFilterComponent,
    MonthFilterComponent,
    MonthRangeFilterComponent,
    ProcessingStatusFilterComponent,
    AcceptanceStatusFilterComponent,
    WeekFilterComponent,
    UserRoleFilterComponent,
    ActivityStatusFilterComponent,
    OperationalStatusFilterComponent,
    UserRoleFilterComponent,
    QuickFilterComponent,
    UpcomingUnstaffedServicesFilterComponent,
    ShiftActivityStatusFilterComponent,
    UpcomingUnstaffedShiftFilterComponent,
    UsersListFilterComponent,
    ShiftCategoryFilterComponent,
    ShiftPlansFilterComponent,
    AgreementFilterComponent,
    EmployeeOrganizationalUnitFilterComponent,
    ShiftHeaderFilterComponent,
    AcceptedJobsFilterComponent,
    ServicesFilterComponent,
    ActivityCategoryFilterComponent,
    ProfessionFilterComponent,
    PriorityFilterComponent,
    BasicSearchFilterComponent,
    FilterDividerComponent,
    ClearFilterButtonComponent,
    ServiceActivitiesFilterComponent,
    ShiftPlanStatusFilterComponent,
    InvoiceActivityStatusFilterComponent,
    LabelFilterComponent,
    ActivityOperationalStatusFilterComponent,
    ActivityAssignmentStatusFilterComponent,
    UserLabelFilterComponent,
    OperationsBoardShiftStatusFilterComponent,
    OperationsBoardShiftStatusTimeframeFilterComponent,
    OperationsBoardShiftStatusTimeframeFilterPipe,
    ProjectStatusFilterComponent,
    InvoiceStatusFilterComponent,
    ClientsFilterComponent,
    ProjectsFilterComponent,
    SharedActivityFilterComponent,
    StayStatusFilterComponent,
    AbsencesStatusFilterComponent,
    SegmentFilterComponent,
    MonthRangeFilterComponent,
  ],
  exports: [
    PartnersFilterComponent,
    ServicesFilterComponent,
    DateFilterComponent,
    OrganizationalUnitFilterComponent,
    ClearFilterComponent,
    SearchFilterComponent,
    UserRoleFilterComponent,
    AbsencesFilterComponent,
    ReleaseStatusFilterComponent,
    QualificationCategoryComponent,
    QualificationStatusComponent,
    DeviationFilterComponent,
    DateRangeFilterComponent,
    ShiftDateRangeFilterComponent,
    MonthFilterComponent,
    ProcessingStatusFilterComponent,
    AcceptanceStatusFilterComponent,
    WeekFilterComponent,
    UserRoleFilterComponent,
    ActivityStatusFilterComponent,
    OperationalStatusFilterComponent,
    UserRoleFilterComponent,
    QuickFilterComponent,
    UpcomingUnstaffedServicesFilterComponent,
    ShiftActivityStatusFilterComponent,
    UpcomingUnstaffedShiftFilterComponent,
    UsersListFilterComponent,
    ShiftCategoryFilterComponent,
    ShiftPlansFilterComponent,
    AgreementFilterComponent,
    EmployeeOrganizationalUnitFilterComponent,
    ShiftHeaderFilterComponent,
    AcceptedJobsFilterComponent,
    ActivityCategoryFilterComponent,
    ProfessionFilterComponent,
    PriorityFilterComponent,
    BasicSearchFilterComponent,
    FilterDividerComponent,
    ClearFilterButtonComponent,
    ServiceActivitiesFilterComponent,
    ShiftPlanStatusFilterComponent,
    InvoiceActivityStatusFilterComponent,
    LabelFilterComponent,
    ActivityOperationalStatusFilterComponent,
    ActivityAssignmentStatusFilterComponent,
    UserLabelFilterComponent,
    OperationsBoardShiftStatusFilterComponent,
    OperationsBoardShiftStatusTimeframeFilterComponent,
    ProjectStatusFilterComponent,
    InvoiceStatusFilterComponent,
    ClientsFilterComponent,
    ProjectsFilterComponent,
    SharedActivityFilterComponent,
    ShiftConfirmDeclineFilterComponent,
    StayStatusFilterComponent,
    AbsencesStatusFilterComponent,
    SegmentFilterComponent,
    MonthRangeFilterComponent,
  ],
  providers: [
    FilterService,
    DateRangeFilterService,
    ShiftPlanService,
    QualificationCategoriesService,
  ],
})
export class FilterModule {}
