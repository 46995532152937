import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { ExternalUser } from '@wilson/clients/services';
import { ProfessionState } from '@wilson/profession/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ExternalCompanyUsersListService } from '../../external-company-users/external-company-users-service/external-company-users.service';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-set-shared-user-professions',
  templateUrl: './set-shared-user-professions.component.html',
  styleUrls: ['./set-shared-user-professions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetSharedUserProfessionsComponent {
  @Input()
  professionIds: string[] = [];
  @Input()
  user: ExternalUser;
  @Input()
  params: ICellRendererParams;
  public readonly professions$ = this.store.select(ProfessionState.professions);
  private selectedProfessionIds: string[];
  private isDropdownOpen: boolean;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly store: Store,
    private readonly externalCompanyUsersListService: ExternalCompanyUsersListService,
  ) {}

  professionsChanged(selectedProfessionIds: string[]): void {
    this.selectedProfessionIds = selectedProfessionIds;
    this.updateProfessions();
  }

  onOpenChange(isDropdownOpen: boolean): void {
    this.isDropdownOpen = isDropdownOpen;
    this.updateProfessions();
  }

  updateProfessions(): void {
    if (!this.isDropdownOpen && this.selectedProfessionIds) {
      this.externalCompanyUsersListService.updateSharedWithMeUser(
        this.params,
        this.user,
        { professionIds: this.selectedProfessionIds },
      );
    }
  }
}
