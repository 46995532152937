import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Agreement, Client } from '@wilson/clients/interfaces';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { LinkRendererComponent } from '@wilson/ag-grid/renderers';
import { PartnerRendererComponent } from '../renderers/partner-renderer/partner-renderer.component';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import { ClientAgreementsService } from '../client-agreements.service';
import { Observable, map } from 'rxjs';
import { GridService } from '@wilson/ag-grid/service';
import { ManyEntity } from '@wilson/base';
import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

export interface CopyAgreementsModalInput {
  agreement?: Agreement;
  clients: Observable<ManyEntity<Client>>;
}

@Component({
  selector: 'wilson-copy-agreements-modal',
  templateUrl: './copy-agreements-modal.component.html',
  styleUrls: ['./copy-agreements-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
})
export class CopyAgreementsModalComponent implements OnInit {
  clients$: Observable<Client[]>;

  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = {
    ...defaultGridOptionsFactory(),
    pagination: false,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  };
  public readonly components = {
    linkRendererComponent: LinkRendererComponent,
    partnerRendererComponent: PartnerRendererComponent,
  };

  public columnDefs: ColDef[] = [
    {
      resizable: false,
      sortable: false,
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      field: 'name',
      sort: 'asc',
      flex: 1,
      headerName: this.translate.instant('general.name'),
    },
    {
      field: 'partner',
      flex: 1,
      sortable: true,
      headerName: this.translate.instant('general.partner'),
      cellRenderer: 'partnerRendererComponent',
      valueGetter: this.clientAgreementsService.getPartnerValue,
      comparator: this.clientAgreementsService.partnerComparator,
    },
  ];
  constructor(
    @Inject(NZ_MODAL_DATA) private data: CopyAgreementsModalInput,
    public readonly clientAgreementsService: ClientAgreementsService,
    public readonly gridService: GridService<Client>,
    private readonly translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.clients$ = this.data.clients.pipe(map((res) => res.data));
  }

  getSelectedClients(): Client[] | undefined {
    return this.gridService.gridApi?.getSelectedRows();
  }
}
