import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuardFn } from '@wilson/auth/core';
import { ROUTES } from '@wilson/utils';

const routes: Routes = [
  {
    path: ROUTES.tabs,
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsModule),
    canActivate: [authGuardFn],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'shift',
    loadChildren: () =>
      import('./pages/shift/shift.module').then((m) => m.ShiftModule),
  },
  {
    path: 'service',
    loadChildren: () =>
      import('./pages/service/service.module').then((m) => m.ServiceModule),
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./pages/activity/activity.module').then((m) => m.ActivityModule),
  },
  {
    path: 'project',
    loadChildren: () =>
      import('./pages/project/project.module').then((m) => m.ProjectsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // mandatory for initial router event catching
      initialNavigation: 'enabledNonBlocking',
      // router debugging
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
