import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { ProjectsGateway } from '@wilson/api/gateway';

@Component({
  selector: 'wilson-projects-filter',
  templateUrl: './projects-filter.component.html',
  styleUrls: ['./projects-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() hideNoProjectOption = false;
  @Input() showLabel = false;
  @Input() label = null;
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly projectsGateway: ProjectsGateway,
  ) {
    super('projects');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.projectsGateway.getProjects({ limit: 0 }).pipe(
      map((projects) => {
        const sortedProjects = [...projects.data].sort((a, b) =>
          a.projectId > b.projectId ? 1 : -1,
        );

        return sortedProjects.map((project) => ({
          id: project.id.toString(),
          name: project.name.toString(),
          colorCode: project.colorCode,
          projectId: project.projectId,
          shortName: project.shortName,
        }));
      }),
    );
  }
}
