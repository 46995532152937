import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function discountValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const discountDays = formGroup.get('discountDays')?.value;
    const discountPercentage = formGroup.get('discountPercentage')?.value;

    if (discountDays && !discountPercentage) {
      return { discountPercentageRequired: true };
    }

    if (discountPercentage && !discountDays) {
      return { discountDaysRequired: true };
    }

    return null;
  };
}
