import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { ConvertToStandbyShiftActionComponent } from '../convert-to-standby-shift-action/convert-to-standby-shift-action.component';
import {
  Activity,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { DeleteShiftActionComponent } from '../delete-shift-action/delete-shift-action.component';
import { SaveAsTemplateActionComponent } from '../save-as-template-action/save-as-template-action.component';
import { AbilityModule } from '@casl/angular';
import { FillWithPlannedTimeActionComponent } from '../fill-with-planned-time-action/fill-with-planned-time-action.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DuplicateShiftActionComponent } from '../duplicate-shift-action/duplicate-shift-action.component';
import { MoveSingleShiftActionComponent } from '../move-single-shift-action/move-single-shift-action.component';
import { AssignSingleShiftActionComponent } from '../assign-single-shift-action/assign-single-shift-action.component';
import { ExportShiftActionComponent } from '../export-shift-action/export-shift-action.component';
import { MoveShiftsResponse } from '@wilson/api/gateway';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'wilson-more-shift-actions-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ExportShiftActionComponent,
    NzButtonModule,
    NzDropDownModule,
    TranslateModule,
    NzIconModule,
    FeatureFlagsModule,
    ConvertToStandbyShiftActionComponent,
    DeleteShiftActionComponent,
    SaveAsTemplateActionComponent,
    AbilityModule,
    FillWithPlannedTimeActionComponent,
    NzToolTipModule,
    DuplicateShiftActionComponent,
    MoveSingleShiftActionComponent,
    AssignSingleShiftActionComponent,
    NzToolTipModule,
    FontAwesomeModule,
  ],
  templateUrl: './more-shift-actions-dropdown.component.html',
  styleUrl: './more-shift-actions-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreShiftActionsDropdownComponent {
  @Input({
    required: true,
  })
  shift!: Shift & { activities: Activity[] };
  @Input({
    required: true,
  })
  disableSaveAsTemplate = false;

  @Input()
  duplicateShiftInNewTab = false;

  @Input()
  isMoveAllowed = false;

  @Input()
  renderMoveOption = false;

  @Input()
  renderAssignShift = false;

  @Input()
  disableAssignShift = false;

  @Input() disabled = false;

  @Input()
  showIconOnly = false;
  @Output()
  convertShiftToStandbySuccessful = new EventEmitter<Shift>();

  @Output()
  filledShiftWithPlannedTimeSuccessful = new EventEmitter();

  @Output()
  deleteTriggered = new EventEmitter();

  @Output() moveShiftSuccess = new EventEmitter<MoveShiftsResponse>();

  @Output()
  assignShiftSuccess = new EventEmitter();

  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;
  readonly faEllipsis = faEllipsis;
}
