export class GetSharedUserQualificationsV2Overview {
  static readonly type =
    '[SharedUserQualificationsV2] Get Shared User Qualifications';
  constructor(
    public readonly params: {
      pageNumber: number;
      pageSize: number;
      relations: string[];
      lang: string;
      userIds?: string[];
      qualificationCategoryIds?: string[];
      userOrganizationalUnitIds?: string[];
      statuses?: string[];
      searchText?: string;
    },
  ) {}
}

export class ResetSharedUserQualificationsV2OverviewDetails {
  static readonly type =
    '[SharedUserQualificationsV2] Reset SharedUserQualifications Details';
}

export class GetSharedUserQualificationsV2OverviewDetails {
  static readonly type =
    '[SharedUserQualificationsV2] Get SharedUser Qualifications details';
  constructor(public readonly userQualificationId: string) {}
}

export class OpenSharedUserQualificationV2OverviewDetailsDrawer {
  static readonly type =
    '[SharedUserQualificationsV2] Open Qualification Drawer';
  constructor(public readonly isOpen: boolean) {}
}
