import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridService } from '@wilson/ag-grid/service';
import { deepClone } from '@wilson/utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ChangeExternalUserNoteModalComponent } from '../change-note-modal/change-note-modal.component';
import {
  ExternalUser,
  SharedUsersWithYouService,
} from '@wilson/clients/services';

@Component({
  selector: 'wilson-user-note',
  templateUrl: './user-note.component.html',
  styleUrls: ['./user-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService, SharedUsersWithYouService],
})
export class UserNoteComponent {
  @Input() user: ExternalUser;

  constructor(
    private readonly nzModalService: NzModalService,
    private readonly translate: TranslateService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly sharedUsersWithYouService: SharedUsersWithYouService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  async openChangeNoteModal() {
    const modalRef: NzModalRef = this.nzModalService.create({
      nzTitle: this.translate.instant(
        'page.settings.client.further_information',
      ),
      nzContent: ChangeExternalUserNoteModalComponent,
      nzClosable: false,
      nzOnCancel: () => modalRef.destroy(),
      nzData: {
        clientNotes: this.user.clientNotes ? this.user.clientNotes : '',
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: () => {
            modalRef.destroy();
          },
        },
        {
          label: this.translate.instant('general.save'),
          type: 'primary',
          onClick: async (
            modalInstance: ChangeExternalUserNoteModalComponent,
          ) => {
            const result: string =
              modalInstance.noteForm.controls['note'].value;
            this.updateUserNote(result);
            this.cdRef.detectChanges();
            modalRef.destroy();
          },
        },
      ],
    });
  }

  updateUserNote(note: string) {
    note = note.trim();
    const oldClientNotes = deepClone(this.user.clientNotes);
    this.user.clientNotes = note;
    this.sharedUsersWithYouService
      .updateUser(this.user, { clientNotes: note })
      .then(() => {
        this.nzMessageService.success(
          this.translate.instant('popup.save.text'),
        );
      })
      .catch(() => {
        this.user.clientNotes = oldClientNotes;
        this.nzMessageService.error(this.translate.instant('general.error'));
        this.cdRef.detectChanges();
      });
  }
}
