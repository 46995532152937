import { UserQualificationV2WithQ } from '@wilson/interfaces';

export class ResetUserQualificationsV2 {
  static readonly type =
    '[UserQualificationsV2] Reset UserQualificationsV2 state';
}

export class SetUserQualificationsV2 {
  static readonly type =
    '[UserQualificationsV2] Set UserQualificationsV2 state';
  constructor(
    public readonly setUserQualifications: UserQualificationV2WithQ[],
  ) {}
}

export class UpsertUserQualificationsV2 {
  static readonly type =
    '[UserQualificationsV2] Upsert provided UserQualificationsV2';
  constructor(
    public readonly upsertUserQualifications: UserQualificationV2WithQ[],
  ) {}
}

export class GetOrFetchUserQualificationV2 {
  static readonly type =
    '[UserQualificationsV2] Get or Fetch UserQualificationsV2';
  constructor(public readonly userQualificationId: string) {}
}
