<ng-container *ngIf="rule">
  <wilson-activity-categories
    customLabel="agreement.rules.ActivityByCategoryFlatRate.activity_categories.label"
    customDescription="agreement.rules.ActivityByCategoryFlatRate.activity_categories.description"
    [activityCategories]="rule.params.activityCategories"
  ></wilson-activity-categories>
  <wilson-price-per-unit
    (updatePricePerUnit)="setPricePerUnit($event)"
    [pricePerUnit]="rule.params.pricePerUnit"
    customDescription="agreement.rules.ActivityByCategoryFlatRate.price_per_unit.description"
    customLabel="agreement.rules.ActivityByCategoryFlatRate.price_per_unit.label"
  ></wilson-price-per-unit>
</ng-container>
