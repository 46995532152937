<div>
  <span nz-typography *ngIf="showTitle">
    {{ 'page.project.filter_clients' | translate }}
  </span>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'page.project.filter_clients' | translate"
    [nzMaxTagCount]="1"
    [nzShowArrow]="true"
    nzAllowClear
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
  >
    <nz-option
      *ngFor="let client of options$ | async"
      [nzLabel]="client.name"
      [nzValue]="client.name"
    >
      {{ client.name }}
    </nz-option>
  </nz-select>
</div>
