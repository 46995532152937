import { BillingRule } from './billing-rules';

export interface BillingRuleFixedShiftCategoryRate extends BillingRule {
  calculatorName: 'FixedShiftCategoryRate';
  params: {
    shiftCategories: string[];
    pricePerUnit: number;
  };
}

export function isFixedShiftCategoryRate(
  obj: BillingRule,
): obj is BillingRuleFixedShiftCategoryRate {
  return obj.calculatorName === 'FixedShiftCategoryRate';
}
