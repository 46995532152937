import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserQualificationV2Status } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'wilson-qualification-v2-status-filter',
  templateUrl: './qualification-v2-status-filter.component.html',
  styleUrls: ['./qualification-v2-status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzSelectModule, TranslateModule, CommonModule, FormsModule],
})
export class QualificationV2StatusFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  public options: FilterOptions[] = [
    {
      name: `user_qualification_status.${UserQualificationV2Status.valid}`,
      id: UserQualificationV2Status.valid,
    },
    {
      name: `user_qualification_status.${UserQualificationV2Status.expiresSoon}`,
      id: UserQualificationV2Status.expiresSoon,
    },
    {
      name: `user_qualification_status.${UserQualificationV2Status.expired}`,
      id: UserQualificationV2Status.expired,
    },
  ];

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
  ) {
    super('userQualificationV2Status');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return of(this.options);
  }
}
