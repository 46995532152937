import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserQualificationV2 } from '@wilson/interfaces';
import { format, parseISO } from 'date-fns';
import { isInfiniteValidQualificationDateString } from '../functions/isInfiniteValidQualificationDate.fn';

@Pipe({
  name: 'userQualificationV2TransformValidTill',
  standalone: true,
})
export class UserQualificationV2TransformValidTillPipe
  implements PipeTransform
{
  constructor(private readonly translateService: TranslateService) {}

  transform(qualification: Pick<UserQualificationV2, 'validTill'>): unknown {
    return isInfiniteValidQualificationDateString(qualification.validTill)
      ? this.translateService.instant('page.qualifications.infinitely_valid')
      : format(parseISO(qualification.validTill), 'P');
  }
}
