import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wilson-page-frame-v2',
  templateUrl: './page-frame-v2.component.html',
  styleUrls: ['./page-frame-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class PageFrameV2Component {
  @Input() renderHeader = true;
  @Input() stickyHeader = false;
}
