import { Injectable } from '@angular/core';
import { GridService } from '@wilson/ag-grid/service';
import { ManyEntity } from '@wilson/base';
import { Client } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { ValueGetterParams } from 'ag-grid-community';
import { Observable, firstValueFrom, shareReplay } from 'rxjs';

@Injectable()
export class ClientAgreementsService {
  constructor(
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    public readonly gridService: GridService<Client>,
    private readonly clientsV2Service: ClientsV2Service,
  ) {}

  async getClients() {
    let clientsObservable: Observable<ManyEntity<Client>>;

    const userWorkApprovalFeatureFlagEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-user-work-approval-and-sharing-of-qualifications-wilp-4769',
      ),
    );

    if (userWorkApprovalFeatureFlagEnabled) {
      clientsObservable = this.clientsV2Service.findAll({
        relations: ['clientPartnership'],
        limit: 0,
      });
    } else {
      clientsObservable = this.clientsV2Service.findAll({
        limit: 0,
      });
    }

    return clientsObservable.pipe(shareReplay(1));
  }

  getPartnerValue(params: ValueGetterParams) {
    const client = params.data;
    return client;
  }

  partnerComparator(a: Client, b: Client): number {
    const client1 = a.clientPartnership?.partnershipId;
    const client2 = b.clientPartnership?.partnershipId;

    return client1 === undefined && client2 === undefined
      ? 0
      : client1 === undefined
      ? -1
      : client2 === undefined
      ? 1
      : client2.localeCompare(client1);
  }
}
