import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RuleLabelComponent } from '../rule-label/rule-label.component';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';

@Component({
  selector: 'wilson-activity-categories',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzTagModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './activity-categories.component.html',
  styleUrl: './activity-categories.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoriesComponent {
  @Input()
  activityCategories: string[];
  @Input()
  customLabel!: string;
  @Input()
  customDescription!: string;
}
