import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleLabelComponent } from '../rule-label/rule-label.component';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { BillingRuleTimeFrameMinimumRateFrequency } from '../../../interfaces/time-frame-minimum-rate';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-frequency',
  standalone: true,
  imports: [
    CommonModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
    NzTagModule,
    TranslateModule,
  ],
  templateUrl: './frequency.component.html',
  styleUrl: './frequency.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrequencyComponent {
  @Input() frequency: BillingRuleTimeFrameMinimumRateFrequency;
  @Input() customLabel!: string;
  @Input() customDescription!: string;
}
