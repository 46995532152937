import { Injectable } from '@angular/core';

@Injectable()
export class DateRangeFilterService {
  startDate: Date = undefined;

  shouldDisableDate(dateUnderEvaluation: Date, rangeInMilliseconds: number) {
    if (rangeInMilliseconds < 0 || !dateUnderEvaluation || !this.startDate) {
      return false;
    }

    const futureMillisecondsLimit =
      this.startDate.getTime() + rangeInMilliseconds;

    return dateUnderEvaluation.getTime() > futureMillisecondsLimit;
  }
}
