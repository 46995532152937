import { BillingRule } from './billing-rules';

export interface BillingRuleActivityHourRate extends BillingRule {
  calculatorName: 'ActivityHourRate';
  params: {
    pricePerHour: number;
    countAfterMinutes: number;
    roundUpAfterMinutes: number;
    roundUpAfter: number;
  };
}

export function isActivityHourRate(
  obj: BillingRule,
): obj is BillingRuleActivityHourRate {
  return obj.calculatorName === 'ActivityHourRate';
}
