import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wilson-filter-divider',
  templateUrl: './filter-divider.component.html',
  styleUrls: ['./filter-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDividerComponent {
  @Input() filterTitleTranslationKey = 'filter.title';
}
