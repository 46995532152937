<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="
      placeholder ||
      ('page.shift_disposition.lbl_filter_absence_category' | translate)
    "
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    data-test-id="absences-filter:select"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="'enum.absence_category.' + item.name | translate"
      [nzValue]="item.id"
      [attr.data-test-id]="'absences-filter:select:option:' + item.id"
    ></nz-option>
  </nz-select>
</div>
