import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExternalUser } from '@wilson/clients/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-set-professions-renderer',
  template: `<wilson-set-shared-user-professions
      *ngIf="professionIds && user && params; else noProfessionIds"
      [professionIds]="professionIds"
      [user]="user"
      [params]="params"
    ></wilson-set-shared-user-professions>
    <ng-template #noProfessionIds>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetProfessionsRendererComponent
  implements ICellRendererAngularComp
{
  professionIds: string[];
  user: ExternalUser;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.setValuesToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setValuesToDisplay(params);
    return true;
  }

  setValuesToDisplay(params: ICellRendererParams): void {
    this.params = params;
    this.user = params?.data;
    this.professionIds = params?.data?.professionIds;
  }
}
