import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { AbilityModule } from '@casl/angular';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { AuthorizationModule } from '@wilson/authorization';
import { ShiftDeletionEvaluatorPipe } from './shift-deletion-evaluator.pipe';
import { IsShiftSubmittedOrAcceptedPipe } from '@wilson/non-domain-specific/shift-helpers/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {
  DeleteDialogeHelperService,
  DeleteDialogueModule,
  DeletePopUpParams,
} from '@wilson/shared-components';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'wilson-delete-shift-action',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    TranslateModule,
    AbilityModule,
    AuthorizationModule,
    NzToolTipModule,
    ShiftDeletionEvaluatorPipe,
    IsShiftSubmittedOrAcceptedPipe,
    DeleteDialogueModule,
  ],
  providers: [],
  templateUrl: './delete-shift-action.component.html',
  styleUrl: './delete-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteShiftActionComponent {
  @Output()
  triggered = new EventEmitter<void>();

  @Input({ required: true }) shift!: Shift | null;

  protected translate = inject(TranslateService);
  protected deleteDialogeHelperService = inject(DeleteDialogeHelperService);
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  protected async deleteButtonClicked(
    isShiftSubmittedOrAccepted: boolean,
  ): Promise<void> {
    if (isShiftSubmittedOrAccepted) {
      await this.openDeletePopUp();
    } else {
      this.triggered.emit();
    }
  }

  private async openDeletePopUp(): Promise<void> {
    const deleteParams: DeletePopUpParams = {
      title: this.translate.instant('page.shifts.delete'),
      message: `${this.translate.instant(
        'modalDelete.confirmText.multipleDelete',
      )} ${this.translate.instant(
        'modalDelete.objectName.multipleDelete',
      )} <b>${this.shift?.name}</b>`,
      objectToDeleteName: this.shift?.name as string,
      twoStep: true,
      options: {
        customTexts: true,
        alertMessage: this.translate.instant('page.shift.delete_by_admin'),
      },
    };

    const modalResults = await firstValueFrom(
      this.deleteDialogeHelperService.openDeletePopUp(deleteParams),
    );
    if (modalResults) {
      this.triggered.emit();
    }
  }
}
