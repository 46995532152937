import { BillingRule } from './billing-rules';

export interface BillingRuleActivityByWagonNumber extends BillingRule {
  calculatorName: 'ActivityByWagonNumber';
  params: {
    minimumAmount: number;
    pricePerUnit: number;
  };
}

export function isActivityByWagonNumber(
  obj: BillingRule,
): obj is BillingRuleActivityByWagonNumber {
  return obj.calculatorName === 'ActivityByWagonNumber';
}
