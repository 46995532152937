import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RolesService } from '@wilson/authorization';
import { Observable } from 'rxjs';
import { FilterOptions } from '../../options.interface';
import { FilterService } from '../../filter.service';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';

@Component({
  selector: 'wilson-user-role-filter',
  templateUrl: './user-role-filter.component.html',
  styleUrls: ['./user-role-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() placeholder!: string;

  constructor(
    private readonly roleService: RolesService,
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
  ) {
    super('role');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.roleService.getRoles() as Observable<FilterOptions[]>;
  }
}
