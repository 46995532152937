<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'account.status' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name | translate"
      [nzValue]="item.id"
    ></nz-option>
  </nz-select>
</div>
