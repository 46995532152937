import { BillingRule } from './billing-rules';

export interface BillingRuleActivityByTonnage extends BillingRule {
  calculatorName: 'ActivityByTonnage';
  params: {
    minimumAmount: number;
    pricePerUnit: number;
  };
}

export function isActivityByTonnage(
  obj: BillingRule,
): obj is BillingRuleActivityByTonnage {
  return obj.calculatorName === 'ActivityByTonnage';
}
