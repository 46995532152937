import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { Observable, map, of } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { ShiftConfirmationStatus } from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-confirm-decline-filter',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFlagsModule,
    TranslateModule,
    NzSelectModule,
    FormsModule,
  ],
  templateUrl: './shift-confirm-decline-filter.component.html',
  styleUrl: './shift-confirm-decline-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftConfirmDeclineFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showLabel = false;
  @Input() placeholder!: string;
  public canConfirmShift$ = this.store
    .select(AuthState.mobileSettings)
    .pipe(map((res) => res?.canConfirmShift));
  public canDeclineShift$ = this.store
    .select(AuthState.mobileSettings)
    .pipe(map((res) => res?.canDeclineShift));
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
  ) {
    super('confirmedDeclined');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return of([
      {
        id: ShiftConfirmationStatus.Confirmed,
        name: this.translateService.instant('shift.filter.confirmed_at'),
      },
      {
        id: ShiftConfirmationStatus.Declined,
        name: this.translateService.instant('shift.filter.declined_at'),
      },
    ]);
  }
}
