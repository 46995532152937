import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountService } from '@wilson/account';
import { NO_USER_CONSTANT } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-users-list-filter',
  templateUrl: './users-list-filter.component.html',
  styleUrls: ['./users-list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showNoAssignment = false;
  @Input() showLabel = false;
  @Input() placeholder!: string;
  public noUserConstant = NO_USER_CONSTANT;

  constructor(
    protected readonly filterService: FilterService,
    private readonly accountService: AccountService,
    protected readonly parserService: MultiSelectParserService,
  ) {
    super('users');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.accountService.getUsers().pipe(
      map((users) => {
        const filterOptionUsers: FilterOptions[] = users.map((user) => {
          return {
            id: user.id as string,
            name: user.firstName + ' ' + user.lastName,
          };
        });
        filterOptionUsers.sort((a, b) => a.name.localeCompare(b.name));
        return filterOptionUsers;
      }),
      catchError(() => of([])),
    );
  }
}
