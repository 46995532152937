import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnershipGateway } from '@wilson/api/gateway';
import { map, Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-partners-filter',
  templateUrl: './partners-filter.component.html',
  styleUrls: ['./partners-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnersFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input()
  label!: string;

  @Input() showLabel = true;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly partnershipGateway: PartnershipGateway,
  ) {
    super('partners');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.partnershipGateway.getAll().pipe(
      map((partners) => {
        return partners.map((partner) => {
          return {
            id: partner.partnerOrganizationalUnit.id,
            name: partner.partnerOrganizationalUnit.name,
          };
        });
      }),
    );
  }
}
