import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  UserQualificationV2,
  UserQualificationV2WithRelation,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

interface GetUserQualificationsParams {
  pageNumber: number;
  pageSize: number;
  relations: string[];
  userIds?: string[];
  qualificationIds?: string[];
  organizationalUnitId?: string[];
  status?: string[];
  search?: string;
  lang: string;
  userQualificationId?: string;
  order?: Record<string, 'asc' | 'desc'>;
}

export interface UserQualificationsResponse {
  data: UserQualificationV2[];
  pagination: {
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    numberOfPages: number;
  };
}

export interface UserQualificationsWithRelationsResponse {
  data: UserQualificationV2WithRelation[];
  pagination: {
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    numberOfPages: number;
  };
}

export interface BulkCreateUserQualificationDto {
  items: CreateUserQualificationDto[];
}

export interface CreateUserQualificationDto {
  userId: string;
  qualificationId: string;
  acquiredAt: string;
  validFrom: string;
  validTill: string;
  confirmedByUser?: boolean;
  notes?: string | null;
}

export interface BulkUpdateUserQualificationDto {
  items: UpdateUserQualificationDto[];
}

export interface UpdateUserQualificationDto {
  id: string;
  acquiredAt?: string;
  validFrom?: string;
  validTill?: string;
  confirmedByUser?: boolean;
  notes?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class UserQualificationsGateway {
  protected readonly path = 'v2/users';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getUserQualifications(
    options: GetUserQualificationsParams,
  ): Observable<UserQualificationsWithRelationsResponse> {
    const { pageNumber, pageSize, relations, lang, order, ...filters } =
      options;

    const queryParams: {
      pageNumber: number;
      pageSize: number;
      relations?: string[];
      lang: string;
      order: Record<string, 'asc' | 'desc'> | undefined;
      query: {
        id?: string | undefined;
        userIds?: string[];
        qualificationIds?: string[];
        organizationalUnitId?: string[];
        status?: string[];
        search?: string;
        userQualificationId?: string;
      };
    } = {
      pageNumber,
      pageSize,
      lang,
      order,
      query: {
        ...filters,
        ...(options.userQualificationId
          ? { id: options.userQualificationId }
          : {}),
      },
    };

    if (relations.length) {
      queryParams.relations = relations;
    }

    const queryString = stringify(queryParams, {
      arrayFormat: 'brackets',
      addQueryPrefix: true,
    });

    return this.http.get<UserQualificationsWithRelationsResponse>(
      `${this.config.host}/${this.path}/qualifications${queryString}`,
    );
  }

  getUserQualificationsDetails(
    userQualificationId: string,
    userId: string,
  ): Observable<UserQualificationsWithRelationsResponse> {
    const queryParams = {
      pageNumber: '1',
      pageSize: '1',
      relations: [
        'qualification',
        'userQualificationHistories',
        'userQualificationAttachments',
      ],
      query: {
        id: userQualificationId,
        userIds: [userId],
      },
    };

    const queryString = stringify(queryParams, {
      arrayFormat: 'brackets',
      addQueryPrefix: true,
    });

    return this.http.get<UserQualificationsWithRelationsResponse>(
      `${this.config.host}/${this.path}/qualifications${queryString}`,
    );
  }

  createUserQualifications(
    userQualificationsToCreate: CreateUserQualificationDto[],
  ): Observable<UserQualificationV2[]> {
    const payload = { items: userQualificationsToCreate };

    return this.http.post<UserQualificationV2[]>(
      `${this.config.host}/v2/users/qualifications`,
      payload,
    );
  }

  deleteQualifications(
    qualifications: UserQualificationV2[],
  ): Observable<{ count: number }> {
    const ids = qualifications.map((q) => q.id);
    const queryParams = stringify(
      { query: { ids } },
      { arrayFormat: 'brackets', addQueryPrefix: true },
    );

    return this.http.delete<{ count: number }>(
      `${this.config.host}/${this.path}/qualifications${queryParams}`,
    );
  }

  updateUserQualifications(
    userQualificationsToUpdate: UpdateUserQualificationDto[],
  ) {
    const payload = { items: userQualificationsToUpdate };

    return this.http.patch(
      `${this.config.host}/v2/users/qualifications`,
      payload,
    );
  }

  exportQualifications(
    userIds: string[],
    lang: string,
  ): Observable<ArrayBuffer> {
    const payload = {
      userIds,
      lang,
    };

    return this.http.post(
      `${this.config.host}/v2/users/qualifications/csv`,
      payload,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  exportZipQualifications(
    userId: string,
    lang: string,
  ): Observable<ArrayBuffer> {
    const queryParams = stringify({ lang }, { addQueryPrefix: true });

    return this.http.get(
      `${this.config.host}/v2/users/${userId}/qualifications/pdf/zip${queryParams}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  exportComplementaryCertificate(
    userId: string,
    lang: string,
  ): Observable<ArrayBuffer> {
    const queryParams = stringify({ lang }, { addQueryPrefix: true });

    return this.http.get(
      `${this.config.host}/v2/users/${userId}/qualifications/certificate${queryParams}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }
}
