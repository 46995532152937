<ng-container
  *ngIf="
    (FeatureName.WilsonShare | featurePure | async) &&
    ('portal-user-work-approval-and-sharing-of-qualifications-wilp-4769'
      | featureFlagPure
      | async) &&
    (users$ | async) as users
  "
>
  <div class="row mt-4">
    <div class="col">
      <label class="form-label fw-normal d-flex align-items-center">
        {{
          'client.shard_by_me.table.header'
            | translate : { partnerName: partnerName }
        }}
      </label>
    </div>
  </div>
  <div class="row mt-1">
    <ag-grid-angular
      (gridReady)="gridService.onGridReady($event)"
      [components]="components"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [rowData]="users"
      [columnDefs]="columnDefs"
      class="ag-theme-wilson"
    >
    </ag-grid-angular>
  </div>
</ng-container>
