import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedUserApprovalStatus } from '@wilson/clients/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-user-approval-status-renderer',
  template: `
    <span *ngIf="approvalStatus; else noApprovalStatus">
      <wilson-display-shared-user-approval
        [status]="approvalStatus"
        [providerView]="true"
      ></wilson-display-shared-user-approval>
    </span>
    <ng-template #noApprovalStatus>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserApprovalStatusRendererComponent
  implements ICellRendererAngularComp
{
  approvalStatus: SharedUserApprovalStatus;

  agInit(params: ICellRendererParams): void {
    this.approvalStatus = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.approvalStatus = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.data?.sharedUsers?.[0]?.approvalStatus;
  }
}
