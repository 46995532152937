import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MoveShiftsResponse } from '@wilson/api/gateway';
import { AuthorizationModule } from '@wilson/authorization';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { MoveShiftHelperService } from '@wilson/non-domain-specific/shift-helpers/core';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
@Component({
  selector: 'wilson-move-single-shift-action',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    NzMenuModule,
    AuthorizationModule,
  ],
  templateUrl: './move-single-shift-action.component.html',
  styleUrl: './move-single-shift-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveSingleShiftActionComponent {
  @Input({
    required: true,
  })
  isMoveAllowed = false;

  @Input({
    required: true,
  })
  shift!: Shift;

  @Output() moveShiftSuccess = new EventEmitter<MoveShiftsResponse>();

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    private readonly moveShiftHelperService: MoveShiftHelperService,
  ) {}

  async triggerDurationSelectionModal() {
    const result = await this.moveShiftHelperService.openDurationSelectionModal(
      [this.shift],
    );
    if (result) {
      this.moveShiftSuccess.emit(result);
    }
  }
}
