import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RuleLabelComponent } from '../rule-label/rule-label.component';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { AgreementsFormService } from '../../../../agreements-form.service';
import { Subject, takeUntil } from 'rxjs';
import { Currency, CurrencySymbol } from '@wilson/invoicing/interfaces';

@Component({
  selector: 'wilson-price-per-hour',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './price-per-hour.component.html',
  styleUrl: './price-per-hour.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePerHourComponent implements OnInit, OnDestroy {
  @Input()
  pricePerHour: number;
  @Input()
  customLabel!: string;
  @Input()
  customDescription!: string;
  @Output()
  updatePricePerHour = new EventEmitter<number>();
  destroyed$ = new Subject<void>();
  currencySymbol = CurrencySymbol.EUR;

  constructor(
    private readonly agreementsFormService: AgreementsFormService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.agreementsFormService.form
      .get('currency')
      .value$.pipe(takeUntil(this.destroyed$))
      .subscribe((currency) => {
        switch (currency) {
          case Currency.USD:
            this.currencySymbol = CurrencySymbol.USD;
            break;
          case Currency.CHF:
            this.currencySymbol = CurrencySymbol.CHF;
            break;
          case Currency.EUR:
          default:
            this.currencySymbol = CurrencySymbol.EUR;
            break;
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setPricePerHour(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.value) {
      this.pricePerHour = parseFloat(input.value);
      this.updatePricePerHour.emit(this.pricePerHour);
    }
  }
}
