<wilson-filter-label [label]="label">
  <nz-input-group [nzSuffix]="suffixIconSearch">
    <input
      nz-input
      type="text"
      [placeholder]="placeholder || 'account.search' | translate"
      [ngModel]="value"
      (ngModelChange)="searchTermSubject.next($event)"
      (focus)="isFocusing.emit(true)"
      (blur)="isFocusing.emit(false)"
      data-test-id="wilson-basic-search-filter:input"
    />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <ng-container *ngIf="value; else searchIcon">
      <span
        nz-icon
        nzTheme="outline"
        nzType="close-circle"
        *ngIf="value"
        class="xmark-icon"
        (click)="inputChanged.emit('')"
      ></span>
    </ng-container>
    <ng-template #searchIcon>
      <i class="fas fa-search search-icon"></i>
    </ng-template>
  </ng-template>
</wilson-filter-label>
