@let hasClientUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Client | async); @let hasClientDeletePermission =
!!(RoleAction.Delete | ablePure : RolePermissionSubject.Client | async); @let
hasAdministrationDeletePermission = !!(RoleAction.Delete | ablePure :
RolePermissionSubject.Administration | async); @let hasSharedUserReadPermission
= !!(RoleAction.Read | ablePure : RolePermissionSubject.SharedUser | async);

<wilson-page-frame>
  <ng-container mainContent>
    <wilson-clients-form
      *ngIf="client$ | async as client"
      [client]="client"
    ></wilson-clients-form>
    <div class="mt-4">
      <wilson-agreements-grid
        *ngIf="FeatureName.Billing | featurePure | async"
        [searchString]="searchString"
      ></wilson-agreements-grid>
    </div>
    @if((FeatureName.WilsonShare| featurePure | async) &&
    hasSharedUserReadPermission) {
    <wilson-external-company-users
      *ngIf="client$ | async as client"
      [client]="client"
    ></wilson-external-company-users>
    <wilson-provider-share-users-list></wilson-provider-share-users-list>
    }
  </ng-container>

  <button
    *ngIf="{ isSaving: clientsFormService.isSaving$ | async } as data"
    nz-button
    nzType="primary"
    class="text-dark p-1 w-100 mb-2 fw-normal"
    [disabled]="
      !hasClientUpdatePermission ||
      data.isSaving ||
      !clientsFormService.form.dirty ||
      clientsFormService.form.invalid
    "
    nz-tooltip
    [nzTooltipTitle]="
      !hasClientUpdatePermission ? ('general.forbidden_action' | translate) : ''
    "
    (click)="save()"
  >
    <span
      class="spinner-border spinner-border-sm me-1"
      role="status"
      aria-hidden="true"
      *ngIf="data.isSaving"
    ></span>
    {{ 'general.save' | translate }}
  </button>
  <button
    nz-button
    nzType="default"
    class="text-danger p-1 w-100 mb-2 fw-normal"
    (click)="deleteClient()"
    [disabled]="
      !hasAdministrationDeletePermission || !hasClientDeletePermission
    "
    nz-tooltip
    [nzTooltipTitle]="
      !hasAdministrationDeletePermission || !hasClientDeletePermission
        ? ('general.forbidden_action' | translate)
        : ''
    "
  >
    {{ 'general.delete' | translate }}
  </button>

  <ng-container *ngIf="FeatureName.Billing | featurePure | async">
    <wilson-filter-divider></wilson-filter-divider>

    <div class="form-text">
      {{ 'general.agreement' | translate }}
    </div>
    <wilson-search-filter
      (modelChange)="searchString = $event"
    ></wilson-search-filter>
  </ng-container>
</wilson-page-frame>
