<ng-container *ngIf="frequency && frequency.unit && frequency.number">
  <wilson-rule-label [customLabel]="customLabel"></wilson-rule-label>
  <wilson-rule-description
    [customDescription]="customDescription"
  ></wilson-rule-description>
  <nz-tag>{{
    'agreement.rules.TimeFrameMinimumRate.frequency.unit'
      | translate : { unit: frequency.unit }
  }}</nz-tag>
  <nz-tag>{{
    'agreement.rules.TimeFrameMinimumRate.frequency.interval'
      | translate : { interval: frequency.number }
  }}</nz-tag>
</ng-container>
