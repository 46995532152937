import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-view-assigned-professions-renderer',
  template: `
    <wilson-view-shared-user-professions
      *ngIf="professionIds && professionIds.length; else noProfessionIds"
      [professionIds]="professionIds"
    ></wilson-view-shared-user-professions>
    <ng-template #noProfessionIds>
      <span [style.color]="'var(--grey-6)'">{{
        'general.n/a' | translate
      }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAssignedProfessionsRendererComponent
  implements ICellRendererAngularComp
{
  professionIds: string[];

  agInit(params: ICellRendererParams): void {
    this.setValuesToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setValuesToDisplay(params);
    return true;
  }

  setValuesToDisplay(params: ICellRendererParams): void {
    this.professionIds = params?.data?.sharedUsers?.[0]?.professionIds;
  }
}
