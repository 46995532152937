import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { ClientFormService } from '@wilson/forms';
import { discountValidator } from '@wilson/invoicing/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { conditionalAddressValidator } from './client-address-form-validator';

@Injectable()
export class ClientsFormService extends ClientFormService<Client> {
  public readonly form = new FormGroup<ControlsOf<Client>>(
    {
      name: new FormControl<Client['name']>('', [Validators.required]),
      email: new FormControl<Client['email']>('', [
        Validators.required,
        Validators.email,
      ]),
      description: new FormControl<Client['description']>(null),
      invoiceAddress: new FormControl<Client['invoiceAddress']>(null),
      taxId: new FormControl<Client['taxId']>(null),
      clientNumber: new FormControl<Client['clientNumber']>(null),
      contactPersonProvider: new FormControl<Client['contactPersonProvider']>(
        null,
      ),
      contactPersonClient: new FormControl<Client['contactPersonClient']>(''),
      partnershipId: new FormControl<Client['partnershipId']>(''),
      canEditActivity: new FormControl<Client['canEditActivity']>(false),
      canSplitActivity: new FormControl<Client['canSplitActivity']>(false),
      invoiceDefaultText: new FormControl<Client['invoiceDefaultText']>(''),
      bankAccountId: new FormControl<Client['bankAccountId']>(null),
      daysToPayInvoice: new FormControl<Client['daysToPayInvoice']>(0),
      discountDays: new FormControl<Client['discountDays']>(0),
      discountPercentage: new FormControl<Client['discountPercentage']>(0),
      debtorNumber: new FormControl<Client['debtorNumber']>(null),
      invoiceText: new FormControl<Client['invoiceText']>(null),
      invoiceLogo: new FormControl<Client['invoiceLogo']>(null),
      vendorNumberAtCustomer: new FormControl<Client['vendorNumberAtCustomer']>(
        null,
      ),
      eInvoiceBuyerReference: new FormControl<Client['eInvoiceBuyerReference']>(
        null,
      ),
      address: new FormGroup({
        street: new FormControl<string>(''),
        city: new FormControl<string>(''),
        postCode: new FormControl<string>(''),
        country: new FormControl<string>(''),
        state: new FormControl<string>(''),
      }),
    },
    { validators: [discountValidator()] },
  );

  private readonly refreshSubject = new BehaviorSubject<Date>(new Date());
  public readonly isRefresh$ = this.refreshSubject.asObservable();

  constructor(
    protected readonly datasource: ClientsV2Service,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly modal: NzModalService,
    private readonly nzMessageService: NzMessageService,
  ) {
    super();
    this.form.get('address')?.setValidators(conditionalAddressValidator());
  }

  public submitForm(): Promise<void | Client | Client[]> {
    return this.submit()
      .then((res) => {
        this.nzMessageService.success(
          this.translate.instant('popup.save.text'),
        );
        this.router.navigateByUrl(`/settings/clients/${this.id ?? res.id}`);
        return res;
      })
      .catch((e) => {
        this.nzMessageService.error(
          this.translate.instant('general.something_went_wrong'),
        );
        this.router.navigateByUrl(
          this.id ? `/settings/clients/${this.id}` : '/settings/clients',
        );
      });
  }

  public confirmDeleteClient(client: Client) {
    this.modal.confirm({
      nzTitle: this.translate.instant('client.modal.delete.title'),
      nzContent: this.translate.instant('client.modal.delete.description'),
      nzOkDanger: true,
      nzCentered: true,
      nzCancelText: this.translate.instant('general.cancel'),
      nzOkText: this.translate.instant('general.delete'),
      nzOnOk: () => this.deleteClient(client),
    });
  }

  private async deleteClient(client: Client) {
    if (client.id) {
      try {
        await firstValueFrom(this.datasource.delete(client.id));
        this.refreshSubject.next(new Date());
        this.router.navigateByUrl('/settings/clients');
      } catch {
        this.nzMessageService.error(this.translate.instant('general.error'));
      }
    }
  }
}
