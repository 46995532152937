import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import * as Sentry from '@sentry/angular';
import {
  UserQualificationV2Status,
  UserQualificationV2WithQ,
} from '@wilson/interfaces';
import { LangState } from '@wilson/languages';
import { UserQualificationsGateway } from '@wilson/qualification-v2/common';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  GetOrFetchUserQualificationV2,
  ResetUserQualificationsV2,
  SetUserQualificationsV2,
  UpsertUserQualificationsV2,
} from './user-qualifications-v2.action';

const USER_QUALIFICATIONS_V2_STATE = 'userQualificationsV2';
const defaults: UserQualificationsV2StateModel = {
  userQualifications: [],
  loading: false,
};

export interface UserQualificationsV2StateModel {
  userQualifications: UserQualificationV2WithQ[];
  loading: boolean;
}

@State<UserQualificationsV2StateModel>({
  name: USER_QUALIFICATIONS_V2_STATE,
  defaults: defaults,
})
@Injectable()
export class UserQualificationsV2State implements NgxsAfterBootstrap {
  constructor(
    private readonly storage: Storage,
    private readonly store: Store,
    private readonly userQualificationsGateway: UserQualificationsGateway,
  ) {}

  async ngxsAfterBootstrap(
    ctx: StateContext<UserQualificationsV2StateModel>,
  ): Promise<void> {
    const storageState = await this.storage.get(USER_QUALIFICATIONS_V2_STATE);
    if (storageState) {
      ctx.patchState({
        userQualifications: storageState,
      });
    }
  }

  @Selector()
  static isLoading(state: UserQualificationsV2StateModel): boolean {
    return state.loading;
  }

  @Selector()
  static userQualifications(
    state: UserQualificationsV2StateModel,
  ): UserQualificationV2WithQ[] {
    return state.userQualifications;
  }

  static userQualificationsByUser(
    userId: string,
  ): (state: UserQualificationsV2StateModel) => UserQualificationV2WithQ[] {
    return createSelector(
      [UserQualificationsV2State],
      (state: UserQualificationsV2StateModel) => {
        return state.userQualifications.filter(
          (userQual) => userQual.userId === userId,
        );
      },
    );
  }

  static unconfirmedUserQualificationsByUser(
    userId: string,
  ): (state: UserQualificationsV2StateModel) => UserQualificationV2WithQ[] {
    return createSelector(
      [UserQualificationsV2State],
      (state: UserQualificationsV2StateModel) => {
        return state.userQualifications.filter(
          (userQual) =>
            userQual.userId === userId &&
            userQual.confirmedByUser === false &&
            userQual.status !== UserQualificationV2Status.expired,
        );
      },
    );
  }

  static userQualificationById(
    userQualificationId: string,
  ): (
    state: UserQualificationsV2StateModel,
  ) => UserQualificationV2WithQ | undefined {
    return createSelector(
      [UserQualificationsV2State],
      (state: UserQualificationsV2StateModel) => {
        return state.userQualifications.find(
          (userQual) => userQual.id === userQualificationId,
        );
      },
    );
  }

  static userQualificationByIdExists(
    userQualificationId: string,
  ): (state: UserQualificationsV2StateModel) => boolean {
    return createSelector(
      [UserQualificationsV2State],
      (state: UserQualificationsV2StateModel) => {
        return state.userQualifications.some(
          (userQual) => userQual.id === userQualificationId,
        );
      },
    );
  }

  @Action(ResetUserQualificationsV2)
  resetUserQualificationsV2(
    ctx: StateContext<UserQualificationsV2StateModel>,
  ): void {
    ctx.setState(defaults);
    this.storage.set(USER_QUALIFICATIONS_V2_STATE, defaults.userQualifications);
  }

  @Action(SetUserQualificationsV2)
  setUserQualificationsV2(
    { patchState, getState }: StateContext<UserQualificationsV2StateModel>,
    { setUserQualifications }: SetUserQualificationsV2,
  ): void {
    patchState({
      userQualifications: setUserQualifications,
    });
    this.storage.set(
      USER_QUALIFICATIONS_V2_STATE,
      getState().userQualifications,
    );
  }

  @Action(UpsertUserQualificationsV2)
  upsertUserQualificationsV2(
    { setState, getState }: StateContext<UserQualificationsV2StateModel>,
    { upsertUserQualifications }: UpsertUserQualificationsV2,
  ): void {
    setState(
      patch({
        userQualifications: (
          existingUserQualifications: ReadonlyArray<UserQualificationV2WithQ>,
        ) => {
          const updatedAndExistingUserQualifications =
            existingUserQualifications.map((existingUserQual) => {
              const newUserQualification = upsertUserQualifications.find(
                (upsertQual) => upsertQual.id === existingUserQual.id,
              );
              return newUserQualification
                ? newUserQualification
                : existingUserQual;
            });

          const newUserQualifications = upsertUserQualifications.filter(
            (upsertUserQualification) =>
              !existingUserQualifications.some(
                (existingUserQual) =>
                  existingUserQual.id === upsertUserQualification.id,
              ),
          );

          return [
            ...updatedAndExistingUserQualifications,
            ...newUserQualifications,
          ];
        },
      }),
    );
    this.storage.set(
      USER_QUALIFICATIONS_V2_STATE,
      getState().userQualifications,
    );
  }

  @Action(GetOrFetchUserQualificationV2)
  getOrFetchUserQualificationV2(
    { setState, getState }: StateContext<UserQualificationsV2StateModel>,
    action: GetOrFetchUserQualificationV2,
  ): Observable<UserQualificationV2WithQ | undefined> {
    return this.store.select(UserQualificationsV2State.isLoading).pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => {
        if (
          UserQualificationsV2State.userQualificationByIdExists(
            action.userQualificationId,
          )(getState())
        ) {
          const existingUserQualifications =
            UserQualificationsV2State.userQualificationById(
              action.userQualificationId,
            )(getState());
          return of(existingUserQualifications);
        }

        setState(
          patch<UserQualificationsV2StateModel>({
            loading: true,
          }),
        );

        return this.userQualificationsGateway
          .getUserQualifications({
            pageNumber: 1,
            pageSize: 1,
            relations: ['qualification'],
            lang: this.store.selectSnapshot(LangState.getBackendLang),
            userQualificationId: action.userQualificationId,
          })
          .pipe(
            map((response) => {
              return response.data[0] as UserQualificationV2WithQ;
            }),
            tap((userQualification) => {
              setState(
                patch<UserQualificationsV2StateModel>({
                  userQualifications: append<UserQualificationV2WithQ>([
                    userQualification,
                  ]),
                  loading: false,
                }),
              );
            }),
            catchError((error) => {
              Sentry.captureException(error);
              setState(
                patch<UserQualificationsV2StateModel>({
                  loading: false,
                }),
              );
              return of(undefined);
            }),
          );
      }),
    );
  }
}
