import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RuleDescriptionComponent } from '../rule-description/rule-description.component';
import { RuleLabelComponent } from '../rule-label/rule-label.component';
import { CurrencySymbol, Currency } from '@wilson/invoicing/interfaces';
import { Subject, takeUntil } from 'rxjs';
import { AgreementsFormService } from '../../../../agreements-form.service';

export interface UpdatePricePerUnit {
  pricePerUnit: number;
  index?: number;
}

@Component({
  selector: 'wilson-price-per-unit',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    RuleLabelComponent,
    RuleDescriptionComponent,
  ],
  templateUrl: './price-per-unit.component.html',
  styleUrl: './price-per-unit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePerUnitComponent implements OnInit, OnDestroy {
  @Input()
  pricePerUnit: number;
  @Input()
  index!: number;
  @Input()
  customLabel!: string;
  @Input()
  customDescription!: string;
  @Output()
  updatePricePerUnit = new EventEmitter<UpdatePricePerUnit>();
  destroyed$ = new Subject<void>();
  currencySymbol = CurrencySymbol.EUR;

  constructor(
    private readonly agreementsFormService: AgreementsFormService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.agreementsFormService.form
      .get('currency')
      .value$.pipe(takeUntil(this.destroyed$))
      .subscribe((currency) => {
        switch (currency) {
          case Currency.USD:
            this.currencySymbol = CurrencySymbol.USD;
            break;
          case Currency.CHF:
            this.currencySymbol = CurrencySymbol.CHF;
            break;
          case Currency.EUR:
          default:
            this.currencySymbol = CurrencySymbol.EUR;
            break;
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setPricePerUnit(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.value) {
      this.pricePerUnit = parseFloat(input.value);
      this.updatePricePerUnit.emit({
        pricePerUnit: this.pricePerUnit,
        index: this.index,
      });
    }
  }
}
