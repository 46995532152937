<div role="button" (click)="openChangeNoteModal()">
  <span
    *ngIf="user.clientNotes && user.clientNotes.trim(); else noClientNote"
    class="text-dark"
  >
    {{ user.clientNotes }}
  </span>
  <ng-template #noClientNote>
    <span [style.color]="'var(--grey-6)'">{{ 'general.n/a' | translate }}</span>
  </ng-template>
</div>
