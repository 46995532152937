import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Currency, CurrencySymbol } from '@wilson/invoicing/interfaces';

@Component({
  selector: 'wilson-currency',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency.component.html',
  styleUrl: './currency.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent {
  @Input() currency = '';
  CurrencySymbol = CurrencySymbol;
  Currency = Currency;
}
