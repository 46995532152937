import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ExternalUser } from '@wilson/clients/services';
import {
  ExternalUsersState,
  FetchAndSetAllExternalUsers,
} from '@wilson/clients/states';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'wilson-users-select',
  standalone: true,
  imports: [CommonModule, TranslateModule, NzSelectModule, FormsModule],
  templateUrl: './external-users-select.component.html',
  styleUrl: './external-users-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExternalUsersSelectComponent),
      multi: true,
    },
  ],
})
export class ExternalUsersSelectComponent
  implements OnInit, ControlValueAccessor
{
  protected userOptions$!: Observable<ExternalUser[]>;
  protected isDisabled = false;

  @Input()
  hideLabel = false;

  @Input()
  label!: string;

  @Input()
  isRequired!: boolean;

  @Input()
  hideSelected = false;

  @Input()
  listOfSelected: string[] = [];

  @Output() selectedUserIdChange = new EventEmitter<string>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchAndSetAllExternalUsers());
    this.userOptions$ = this.store.select(ExternalUsersState.users).pipe(
      map((externalUsers: ExternalUser[]) => {
        const sortedExternalUsers = [...externalUsers].sort((a, b) => {
          const aName = a.user.firstName + ' ' + a.user.lastName;
          const bName = b.user.firstName + ' ' + b.user.lastName;
          return aName.localeCompare(bName);
        });
        return sortedExternalUsers;
      }),
    );
  }

  public selectedUserId = '';

  protected updateSelectedUserId(id: string) {
    this.selectedUserId = id;
    this.selectedUserIdChange.emit(this.selectedUserId);
    this.propagateChange(id);
  }

  writeValue(value: string) {
    this.selectedUserId = value;
    this.selectedUserIdChange.emit(this.selectedUserId);
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line
  propagateChange: (value: string) => void = () => {};

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cdr.detectChanges();
  }

  public isSelected(value: string | undefined): boolean {
    if (this.hideSelected && value) {
      return this.listOfSelected.indexOf(value) !== -1;
    }
    return false;
  }
}
