@let hasClientCreatePermission = !!(RoleAction.Create | ablePure :
RolePermissionSubject.Client | async);

<wilson-page-frame>
  <ag-grid-angular
    mainContent
    class="ag-theme-wilson"
    [gridOptions]="gridOptions"
    [defaultColDef]="defaultColDef"
    [components]="components"
    [rowData]="data$ | async"
    [columnDefs]="columnDefs$ | async"
    (gridReady)="gridService.onGridReady($event)"
  ></ag-grid-angular>

  <div class="d-flex flex-column">
    <button
      nz-button
      nzType="primary"
      class="text-dark fw-normal p-1 w-100 mb-2"
      [routerLink]="'new'"
      routerLinkActive="router-link-active"
      [disabled]="!hasClientCreatePermission"
      nz-tooltip
      [nzTooltipTitle]="
        !hasClientCreatePermission
          ? ('general.forbidden_action' | translate)
          : ''
      "
    >
      {{ 'general.add' | translate }}
    </button>
    <wilson-filter-divider></wilson-filter-divider>
    <wilson-search-filter
      class="mb-4"
      (modelChange)="gridService.setQuickFilter($event)"
    ></wilson-search-filter>
  </div>
</wilson-page-frame>
