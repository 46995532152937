import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ExternalUser,
  SharedUserApprovalStatus,
} from '@wilson/clients/services';
import { ICellRendererParams } from 'ag-grid-community';
import { ExternalCompanyUsersListService } from '../../external-company-users/external-company-users-service/external-company-users.service';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-set-shared-user-approval',
  templateUrl: './set-shared-user-approval.component.html',
  styleUrls: ['./set-shared-user-approval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetSharedUserApprovalComponent {
  @Input()
  approvalStatus: SharedUserApprovalStatus;
  @Input()
  user: ExternalUser;
  @Input()
  params: ICellRendererParams;
  sharedUserApprovalStatuses = Object.values(SharedUserApprovalStatus);

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly externalCompanyUsersListService: ExternalCompanyUsersListService,
  ) {}

  approvalChanged(newApprovalStatus: SharedUserApprovalStatus): void {
    this.externalCompanyUsersListService.updateSharedWithMeUser(
      this.params,
      this.user,
      { approvalStatus: newApprovalStatus },
    );
  }
}
