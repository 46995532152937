import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QualificationV2OverviewComponent } from './components/qualification-v2-overview/qualification-v2-overview.component';
import { NgxsModule } from '@ngxs/store';
import { UserQualificationsV2OverviewState } from './states/user-qualifications-v2-overview/user-qualifications-v2-overview.state';
import { SharedUserQualificationsV2OverviewComponent } from './components/shared-user-qualifications-v2-overview/shared-user-qualifications-v2-overview.component';
import { QualificationsV2State } from './states/qualifications-v2/qualifications-v2.state';
import { SharedUserQualificationsV2OverviewState } from './states/shared-user-qualifications-v2-overview/shared-user-qualifications-v2-overview.state';

export interface QualificationV2ConfigModule {
  csvBoxQualificationV2SheetLicense: string;
  csvBoxRouteQualificationV2SheetLicense: string;
}

export const QUALIFICATION_V2_CONFIG =
  new InjectionToken<QualificationV2ConfigModule>('QUALIFICATION_V2_CONFIG');

const routes: Routes = [
  {
    path: '',
    component: QualificationV2OverviewComponent,
    data: {
      breadcrumb: 'page.qualification_management.title',
    },
  },
  {
    path: 'shared',
    component: SharedUserQualificationsV2OverviewComponent,
    data: {
      breadcrumb: 'page.shared-users-qualifications.overview',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NgxsModule.forFeature([
      UserQualificationsV2OverviewState,
      QualificationsV2State,
      SharedUserQualificationsV2OverviewState,
    ]),
  ],
  exports: [RouterModule],
})
export class QualificationsV2CoreModule {}
