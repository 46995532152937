import { ExternalUser, UserSharedByMe } from '@wilson/clients/services';

export const approvedAtComparator = (
  valueA: UserSharedByMe | ExternalUser,
  valueB: UserSharedByMe | ExternalUser,
  nodeA: unknown,
  nodeB: unknown,
  isDescending: boolean,
) => {
  const approvedAtA =
    'sharedUsers' in valueA
      ? valueA.sharedUsers?.[0]?.approvedAt
      : valueA.approvedAt;
  const approvedAtB =
    'sharedUsers' in valueB
      ? valueB.sharedUsers?.[0]?.approvedAt
      : valueB.approvedAt;

  if (!approvedAtA && !approvedAtB) return 0;

  // Put null or undefined sharedAt values at the end for both ascending and descending sorts
  if (!approvedAtA) return isDescending ? -1 : 1;
  if (!approvedAtB) return isDescending ? 1 : -1;

  return approvedAtA.localeCompare(approvedAtB);
};
