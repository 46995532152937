import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BillingRuleFixedShiftCategoryRate } from '../../interfaces/fixed-shift-category-rate';
import { ShiftCategoriesComponent } from '../partials/shift-categories/shift-categories.component';
import {
  PricePerUnitComponent,
  UpdatePricePerUnit,
} from '../partials/price-per-unit/price-per-unit.component';

@Component({
  selector: 'wilson-fixed-shift-category-rate',
  standalone: true,
  imports: [CommonModule, ShiftCategoriesComponent, PricePerUnitComponent],
  templateUrl: './fixed-shift-category-rate.component.html',
  styleUrl: './fixed-shift-category-rate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedShiftCategoryRateComponent {
  @Input() rule: BillingRuleFixedShiftCategoryRate;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleFixedShiftCategoryRate;
    index: number;
  }>();

  setPricePerUnit(update: UpdatePricePerUnit) {
    this.rule.params.pricePerUnit = update.pricePerUnit;
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }
}
