import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  UserQualificationV2,
  UserQualificationV2WithRelation,
} from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetUserQualificationsV2Overview,
  GetUserQualificationsV2OverviewDetails,
  OpenUserQualificationV2OverviewDetailsDrawer,
  ResetUserQualificationsV2OverviewDetails,
} from './user-qualifications-v2-overview.action';
import {
  UserQualificationsGateway,
  UserQualificationsResponse,
  UserQualificationsWithRelationsResponse,
} from '@wilson/qualification-v2/common';

export interface UserQualificationsV2OverviewStateModel {
  selectedQualificationDetails: UserQualificationV2WithRelation | null;
  qualifications: UserQualificationV2[];
  totalRecords: number;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  isDrawerVisible: boolean;
}

@State<UserQualificationsV2OverviewStateModel>({
  name: 'userQualificationsV2Overview',
  defaults: {
    selectedQualificationDetails: null,
    qualifications: [],
    totalRecords: 0,
    loading: false,
    currentPage: 1,
    pageSize: 10,
    isDrawerVisible: false,
  },
})
@Injectable()
export class UserQualificationsV2OverviewState {
  constructor(private userQualificationsGateway: UserQualificationsGateway) {}

  @Selector()
  static getQualifications(
    state: UserQualificationsV2OverviewStateModel,
  ): UserQualificationV2[] {
    return state.qualifications;
  }

  @Selector()
  static getTotalRecords(
    state: UserQualificationsV2OverviewStateModel,
  ): number {
    return state.totalRecords;
  }

  @Selector()
  static getPageSize(state: UserQualificationsV2OverviewStateModel): number {
    return state.pageSize ?? 10;
  }

  @Selector()
  static isLoading(state: UserQualificationsV2OverviewStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static userQualificationById(id: string) {
    return (
      state: UserQualificationsV2OverviewStateModel,
    ): UserQualificationV2 | undefined => {
      return state.qualifications.find(
        (qualification) => qualification.id === id,
      );
    };
  }

  @Selector()
  static userQualificationByIdExists(id: string) {
    return (state: UserQualificationsV2OverviewStateModel): boolean => {
      return state.qualifications.some(
        (qualification) => qualification.id === id,
      );
    };
  }

  @Selector()
  static getQualificationDetails(
    state: UserQualificationsV2OverviewStateModel,
  ): UserQualificationV2WithRelation | null {
    return state.selectedQualificationDetails || null;
  }

  @Selector()
  static isDrawerVisible(
    state: UserQualificationsV2OverviewStateModel,
  ): boolean {
    return state.isDrawerVisible;
  }

  @Action(GetUserQualificationsV2Overview)
  getUserQualifications(
    { patchState }: StateContext<UserQualificationsV2OverviewStateModel>,
    { params }: GetUserQualificationsV2Overview,
  ): Observable<UserQualificationsResponse> {
    patchState({ loading: true });

    return this.userQualificationsGateway.getUserQualifications(params).pipe(
      tap((response) => {
        patchState({
          qualifications: response.data,
          totalRecords: response.pagination.totalRecords,
          currentPage: params.pageNumber,
          pageSize: params.pageSize,
          loading: false,
        });
      }),
      catchError((error) => {
        patchState({ loading: false });
        throw error;
      }),
    );
  }

  @Action(GetUserQualificationsV2OverviewDetails)
  getUserQualificationsV2Details(
    {
      patchState,
      dispatch,
    }: StateContext<UserQualificationsV2OverviewStateModel>,
    { userQualificationId, userId }: GetUserQualificationsV2OverviewDetails,
  ): Observable<UserQualificationsWithRelationsResponse> {
    patchState({ loading: true });

    return this.userQualificationsGateway
      .getUserQualificationsDetails(userQualificationId, userId)
      .pipe(
        tap((response) => {
          patchState({
            selectedQualificationDetails: response.data[0] || null,
            loading: false,
          });
        }),
        tap(() => {
          dispatch(new OpenUserQualificationV2OverviewDetailsDrawer(true));
        }),
        catchError((error) => {
          patchState({ loading: false });
          throw error;
        }),
      );
  }

  @Action(ResetUserQualificationsV2OverviewDetails)
  resetUserQualificationsV2Details(
    ctx: StateContext<UserQualificationsV2OverviewStateModel>,
  ): void {
    ctx.patchState({
      selectedQualificationDetails: null,
    });
  }

  @Action(OpenUserQualificationV2OverviewDetailsDrawer)
  openDrawer(
    { patchState }: StateContext<UserQualificationsV2OverviewStateModel>,
    { isOpen }: OpenUserQualificationV2OverviewDetailsDrawer,
  ): void {
    patchState({
      isDrawerVisible: isOpen,
    });
  }
}
