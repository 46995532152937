import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  faArrowCircleRight,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

import { SharedUserApprovalStatus } from '@wilson/clients/services';

@Component({
  selector: 'wilson-display-shared-user-approval',
  templateUrl: './display-shared-user-approval.component.html',
  styleUrls: ['./display-shared-user-approval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplaySharedUserApprovalComponent {
  @Input() status: SharedUserApprovalStatus;
  @Input() providerView = false;
  faIcons = {
    faCheckCircle,
    faTimesCircle,
    faArrowCircleRight,
  };
  SharedUserApprovalStatus = SharedUserApprovalStatus;
}
