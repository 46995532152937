@let hasShiftCreatePermissions = (RoleAction.Create | ablePure :
RolePermissionSubject.Shift | async);
<li
  nz-menu-item
  nz-tooltip
  [nzTooltipTitle]="
    !hasShiftCreatePermissions ? ('general.forbidden_action' | translate) : ''
  "
  [nzDisabled]="disabled || !hasShiftCreatePermissions"
  data-test-id="wilson-save-as-template-action:button"
  (click)="saveAsTemplate(disabled)"
>
  {{ 'shift.page.save_as_template' | translate }}
</li>
