@let hasClientUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Client | async); @let hasWilsonShareReadPermission =
!!(RoleAction.Read | ablePure : RolePermissionSubject.WilsonShare | async); @let
hasWilsonShareUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.WilsonShare | async); @let forbiddenMessage =
'general.forbidden_action' | translate;

<form class="row mb-3 ps-2" nz-form [formGroup]="clientsFormService.form">
  <div class="col-12 mb-2">
    <span class="font dark fw-normal">
      {{ 'page.invoicing.customer_information' | translate }}
    </span>
  </div>
  <div class="col-4">
    <div class="col-12 mb-1 mt-2">
      <div
        class="form-text"
        [ngClass]="{
          'text-danger':
            clientsFormService.form.controls['name'] | formControlInvalid
        }"
      >
        {{ 'page.invoicing.client_name' | translate }} *
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="name"
        [class.is-invalid]="
          clientsFormService.form.controls['name'] | formControlInvalid
        "
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div
        class="form-text"
        [ngClass]="{
          'text-danger':
            clientsFormService.form.controls['email'] | formControlInvalid
        }"
      >
        {{ 'page.invoicing.client_email' | translate }} *
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="email"
        [class.is-invalid]="
          clientsFormService.form.controls['email'] | formControlInvalid
        "
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.invoicing.tax_id' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="taxId"
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <ng-container
      *ngIf="
        (FeatureName.WilsonShare | featurePure | async) &&
        ('portal-user-work-approval-and-sharing-of-qualifications-wilp-4769'
          | featureFlagPure
          | async)
      "
    >
      <div class="form-text mt-2">
        {{ 'wilson.share.partner.name' | translate }}
      </div>
      <div class="col-12 mb-1" *ngIf="partners$ | async as partners">
        <nz-select
          formControlName="partnershipId"
          [nzPlaceHolder]="'wilson.share.partner.name' | translate"
          [nzMaxTagCount]="1"
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          [nzDisabled]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
          "
          nz-tooltip
          [nzTooltipTitle]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
              ? forbiddenMessage
              : ''
          "
        >
          <nz-option
            *ngFor="let partner of partners"
            [nzValue]="partner.id"
            [nzLabel]="partner.partnerOrganizationalUnit.name"
          >
          </nz-option>
        </nz-select>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        (FeatureName.WilsonShare | featurePure | async) &&
        ('portal-allow-edit-share-activity-wilp-5570'
          | featureFlagPure
          | async) &&
        clientsFormService.form.controls['partnershipId']?.value
      "
    >
      <div class="form-text mt-2">
        {{ 'wilson.share.partner.allow_activity_editing' | translate }}
      </div>
      <div class="col-12 mb-1">
        <nz-switch
          formControlName="canEditActivity"
          [nzDisabled]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
          "
          nz-tooltip
          [nzTooltipTitle]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
              ? forbiddenMessage
              : ''
          "
        ></nz-switch>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        (FeatureName.WilsonShare | featurePure | async) &&
        ('portal-allow-split-share-activity-wilp-5571'
          | featureFlagPure
          | async) &&
        clientsFormService.form.controls['partnershipId']?.value
      "
    >
      <div class="form-text mt-2">
        {{ 'wilson.share.partner.allow_activity_splitting' | translate }}
      </div>
      <div class="col-12 mb-1">
        <nz-switch
          formControlName="canSplitActivity"
          [nzDisabled]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
          "
          nz-tooltip
          [nzTooltipTitle]="
            !hasWilsonShareReadPermission ||
            !hasWilsonShareUpdatePermission ||
            !hasClientUpdatePermission
              ? forbiddenMessage
              : ''
          "
        ></nz-switch>
      </div>
    </ng-container>
    <div class="col-12 mt-2">
      <div>
        {{ 'page.general_settings.invoice_logo' | translate }}
      </div>
      <div class="row mt-2">
        <nz-upload
          class="avatar-uploader"
          nzName="invoiceLogo"
          nzListType="picture-card"
          [nzShowUploadList]="true"
          [nzBeforeUpload]="beforeUpload"
          [nzAccept]="
            'image/jpeg,image/png,image/jpg,image/gif,image/svg+xml,image/webp'
          "
          [nzDisabled]="!hasClientUpdatePermission"
          nz-tooltip
          [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        >
          @if (!clientsFormService.form.get('invoiceLogo').value) {
          <span
            class="upload-icon"
            nz-icon
            [nzType]="loading ? 'loading' : 'plus'"
          ></span>
          <div class="ant-upload-text">{{ 'general.upload' | translate }}</div>
          } @else {
          <div class="image-container">
            <img
              [src]="clientsFormService.form.get('invoiceLogo').value"
              class="company-logo"
            />
            <div class="overlay">
              <fa-icon
                [icon]="faTrash"
                class="trash-icon"
                [class.cursor-pointer]="hasClientUpdatePermission"
                (click)="hasClientUpdatePermission && removeInvoiceLogo($event)"
              ></fa-icon>
            </div>
          </div>
          }
        </nz-upload>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.invoicing.client_number' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="clientNumber"
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.invoicing.contact_person_client' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="contactPersonClient"
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.invoicing.contact_person_provider' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="contactPersonProvider"
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <wilson-client-address [form]="clientsFormService.form.controls['address']">
    </wilson-client-address>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.client.invoice_address' | translate }}
      </div>
      <textarea
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="invoiceAddress"
        type="text"
        rows="2"
        [placeholder]="'general.input' | translate"
      ></textarea>
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.client.further_comments' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="invoiceDefaultText"
      />
    </div>
    <div class="col-12 mb-1 mt-2" *ngIf="bankDetails$ | async as bankDetails">
      <div class="form-text">
        {{ 'page.client.bank_details' | translate }}
      </div>
      <nz-select
        formControlName="bankAccountId"
        [nzPlaceHolder]="'page.client.bank_account' | translate"
        [nzMaxTagCount]="1"
        nzShowSearch
        nzAllowClear
        [nzShowArrow]="true"
        [nzDisabled]="!hasClientUpdatePermission"
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
      >
        <nz-option
          *ngFor="let bank of bankDetails"
          [nzValue]="bank.id"
          [nzLabel]="bank.bankName"
        >
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="col-4">
    <div class="d-flex gap-2 justify-content-between">
      <div class="mb-1 flex-fill mt-2">
        <div class="form-text">
          {{ 'page.invoicing.discount_days' | translate }}
        </div>
        <input
          nz-input
          nz-tooltip
          [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
          class="rounded-0"
          [class.is-invalid]="
            clientsFormService.form.hasError('discountDaysRequired') ||
            clientsFormService.form.controls['discountDays'].invalid
          "
          formControlName="discountDays"
          type="number"
          [placeholder]="'general.input' | translate"
        />
      </div>
      <div class="mb-1 flex-fill mt-2">
        <div class="form-text">
          {{ 'page.invoicing.discount_percentage' | translate }}
        </div>
        <input
          nz-input
          nz-tooltip
          [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
          class="rounded-0"
          [class.is-invalid]="
            clientsFormService.form.hasError('discountPercentageRequired') ||
            clientsFormService.form.controls['discountPercentage'].invalid
          "
          formControlName="discountPercentage"
          type="number"
          [placeholder]="'general.input' | translate"
        />
      </div>
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.invoicing.days_to_pay_invoice' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="daysToPayInvoice"
        type="number"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.client.debtor_number' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="debtorNumber"
        type="text"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <p class="e-invoice">{{ 'page.client.e_invoice' | translate }}:</p>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.client.eInvoiceBuyerReference' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="eInvoiceBuyerReference"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'page.client.vendor_number' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="vendorNumberAtCustomer"
        [placeholder]="'general.input' | translate"
      />
    </div>
    <div class="col-12 mb-1 mt-2">
      <div class="form-text">
        {{ 'general.description' | translate }}
      </div>
      <input
        nz-input
        nz-tooltip
        [nzTooltipTitle]="!hasClientUpdatePermission ? forbiddenMessage : ''"
        class="rounded-0"
        formControlName="description"
        [placeholder]="'general.description' | translate"
      />
    </div>
  </div>
</form>
