import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '@wilson/account';
import { ClientPartnership } from '@wilson/clients/interfaces';
import {
  ClientsV2Service,
  ExternalUsersService,
} from '@wilson/clients/services';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  mergeAll,
  mergeMap,
  of,
  switchMap,
  tap,
  toArray,
} from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-shared-user-filter',
  standalone: true,
  imports: [CommonModule, NzSelectModule, TranslateModule, FormsModule],
  templateUrl: './shared-user-filter.component.html',
  styleUrl: './shared-user-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUserFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showLabel = false;
  @Input() placeholder!: string;

  constructor(
    protected readonly filterService: FilterService,
    private readonly accountService: AccountService,
    protected readonly parserService: MultiSelectParserService,
    protected readonly clientsV2Service: ClientsV2Service,
    protected readonly externalUsersService: ExternalUsersService,
  ) {
    super('sharedUsers');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.clientsV2Service
      .findAll({
        relations: ['clientPartnership'],
        limit: 0,
      })
      .pipe(
        map((clients) => {
          return clients.data
            .filter(
              (client) =>
                client.id !== undefined &&
                client.clientPartnership &&
                client.clientPartnership.partnershipId,
            )
            .map(
              (client) =>
                (client.clientPartnership as ClientPartnership).partnershipId,
            );
        }),
        switchMap((clientIds) => {
          return forkJoin(
            clientIds.map((id) => this.externalUsersService.getUser(id)),
          ).pipe(mergeAll());
        }),
        map((externalUsers) => {
          const filterOptionUsers: FilterOptions[] = externalUsers.map(
            (externalUser) => {
              return {
                id: externalUser.userId as string,
                name:
                  externalUser.user.firstName +
                  ' ' +
                  externalUser.user.lastName,
              };
            },
          );
          filterOptionUsers.sort((a, b) => a.name.localeCompare(b.name));
          return filterOptionUsers;
        }),
        catchError(() => of([])),
      );
  }
}
