import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  QualificationCategoryV2,
  QualificationV2,
  UserQualificationV2,
  UserQualificationV2WithQ,
  UserQualificationV2WithRelation,
} from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { InitQualificationsV2Categories } from '../states/qualifications-v2-category/qualifications-v2-category.action';
import { QualificationsV2CategoriesState } from '../states/qualifications-v2-category/qualifications-v2-category.state';
import { GetOrFetchQualificationV2 } from '../states/qualifications-v2/qualifications-v2.action';
import { QualificationsV2State } from '../states/qualifications-v2/qualifications-v2.state';

@Pipe({
  name: 'qualificationV2CategoryName',
  standalone: true,
})
export class QualificationV2CategoryNamePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  transform(
    payload:
      | Pick<UserQualificationV2WithRelation, 'qualification'>
      | Pick<UserQualificationV2WithQ, 'qualification'>
      | Pick<QualificationV2, 'qualificationCategoryId'>
      | Pick<UserQualificationV2, 'qualificationId'>
      | Pick<QualificationCategoryV2, 'id'>
      | string,
    emptyStringOnError = false,
  ): Observable<string> {
    let categoryId$: Observable<string>;

    if (!payload) {
      return of(this.handleError(emptyStringOnError));
    } else if (typeof payload === 'string') {
      categoryId$ = of(payload);
    } else if (typeof payload === 'object') {
      if (
        'qualification' in payload &&
        payload.qualification?.qualificationCategoryId
      ) {
        categoryId$ = of(payload.qualification.qualificationCategoryId);
      } else if (
        'qualificationCategoryId' in payload &&
        payload.qualificationCategoryId
      ) {
        categoryId$ = of(payload.qualificationCategoryId);
      } else if ('qualificationId' in payload && payload.qualificationId) {
        categoryId$ = this.store
          .dispatch(new GetOrFetchQualificationV2(payload.qualificationId))
          .pipe(
            switchMap(() =>
              this.store
                .select(
                  QualificationsV2State.qualificationById(
                    payload.qualificationId,
                  ),
                )
                .pipe(
                  filter((quali) => !!quali),
                  map((quali) => quali.qualificationCategoryId),
                ),
            ),
          );
      } else if ('id' in payload && payload.id) {
        categoryId$ = of(payload.id);
      } else {
        return of(this.handleError(emptyStringOnError));
      }
    } else {
      return of(this.handleError(emptyStringOnError));
    }

    return categoryId$.pipe(
      switchMap((categoryId) =>
        combineLatest([
          this.store.select(
            QualificationsV2CategoriesState.categoryById(categoryId),
          ),
          this.store.select(QualificationsV2CategoriesState.isLoading),
          this.store.select(QualificationsV2CategoriesState.hasInitialized),
        ]).pipe(
          tap(([category, isLoading, hasInitialized]) => {
            if (
              category === undefined &&
              isLoading === false &&
              hasInitialized === false
            ) {
              this.store.dispatch(new InitQualificationsV2Categories());
            }
          }),
          map(([category]) => category),
          map((category) =>
            category
              ? this.transformMasterDataTranslationService.transform([
                  category,
                ])[0].translatedName
              : this.handleError(emptyStringOnError),
          ),
          catchError(() => {
            return this.handleError(emptyStringOnError);
          }),
        ),
      ),
    );
  }

  private handleError(emptyStringOnError: boolean): string {
    return emptyStringOnError
      ? ''
      : this.translateService.instant('general.n/a');
  }
}
