import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnyAbility } from '@casl/ability';
import { AblePurePipe } from '@casl/angular';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  IconActionRendererComponent,
  IconActionRendererParams,
  LinkRendererComponent,
} from '@wilson/ag-grid/renderers';
import { GridService } from '@wilson/ag-grid/service';
import { FeaturePurePipe } from '@wilson/authorization';
import { Client } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  FeatureName,
  LinkRendererParams,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { ColDef } from 'ag-grid-community';
import {
  Observable,
  combineLatest,
  firstValueFrom,
  map,
  switchMap,
} from 'rxjs';
import { ClientAgreementsService } from '../client-agreements.service';
import { ClientsFormService } from '../clients-form.service';
import { PartnerRendererComponent } from '../renderers/partner-renderer/partner-renderer.component';

@Component({
  selector: 'wilson-clients-overview',
  templateUrl: './clients-overview.component.html',
  styleUrls: ['./clients-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService, ClientsFormService],
})
export class ClientsOverviewComponent implements OnInit {
  public data$: Observable<Client[]>;
  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = defaultGridOptionsFactory();
  public readonly components = {
    LinkRendererComponent: LinkRendererComponent,
    PartnerRendererComponent: PartnerRendererComponent,
    IconActionRendererComponent: IconActionRendererComponent,
  };
  public readonly clientLinkRendererParams: LinkRendererParams<Client> = {
    link: {
      link: (entity) => `/settings/clients/${entity.id}`,
      linkClass: () => 'link-dark',
    },
  };
  public readonly actionParams: IconActionRendererParams<Client> = {
    icons: [
      {
        faIcon: faTrash,
        iconSize: 'lg',
        nzDanger: true,
        action: (client) => this.clientsFormService.confirmDeleteClient(client),
        hasPermission$: this.ablePurePipe.transform(
          RoleAction.Delete,
          RolePermissionSubject.Client,
        ),
      },
    ],
    isOnlyVisibleOnHover: false,
    buttonType: 'link',
  };
  private userWorkApprovalFeatureFlagEnabled: boolean;
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  protected columnDefs$: Observable<ColDef[]> = combineLatest([
    this.ablePurePipe.transform(RoleAction.Read, RolePermissionSubject.Client),
    this.featurePurePipe.transform(FeatureName.WilsonShare),
    this.featureFlagPurePipe.transform(
      'portal-user-work-approval-and-sharing-of-qualifications-wilp-4769',
    ),
  ]).pipe(
    map(([canReadClient, wilsonShareEnabled, featureFlagEnabled]) => {
      return [
        {
          field: 'clientNumber',
          flex: 1,
          headerName: this.translate.instant('client.client_number'),
        },
        {
          field: 'name',
          sort: 'asc',
          flex: 1,
          headerName: this.translate.instant('general.name'),
          cellRenderer: 'LinkRendererComponent',
          cellRendererParams: this.clientLinkRendererParams,
        },
        ...(canReadClient && wilsonShareEnabled && featureFlagEnabled
          ? [
              {
                field: 'partner',
                flex: 1,
                headerName: this.translate.instant('general.partner'),
                cellRenderer: 'PartnerRendererComponent',
                valueGetter: this.clientAgreementsService.getPartnerValue,
                comparator: this.clientAgreementsService.partnerComparator,
              },
            ]
          : []),
        {
          field: 'email',
          flex: 1,
          headerName: this.translate.instant('account.email'),
        },
        {
          field: 'description',
          flex: 1,
          headerName: this.translate.instant('general.description'),
        },
        {
          cellRenderer: 'IconActionRendererComponent',
          cellRendererParams: this.actionParams,
          cellStyle: { textAlign: 'center' },
          width: 106,
          resizable: false,
          sortable: false,
        },
      ];
    }),
  );

  constructor(
    private readonly clientsV2Service: ClientsV2Service,
    public readonly gridService: GridService<Client>,
    private readonly clientsFormService: ClientsFormService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    public readonly clientAgreementsService: ClientAgreementsService,
    private readonly translate: TranslateService,
    private readonly ablePurePipe: AblePurePipe<AnyAbility>,
    private readonly featurePurePipe: FeaturePurePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userWorkApprovalFeatureFlagEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-user-work-approval-and-sharing-of-qualifications-wilp-4769',
      ),
    );
    this.data$ = this.clientsFormService.isRefresh$.pipe(
      switchMap(() => {
        this.gridService.tapLoad();
        if (this.userWorkApprovalFeatureFlagEnabled) {
          return this.clientsV2Service.findAll({
            relations: ['clientPartnership'],
            limit: 0,
          });
        } else {
          return this.clientsV2Service.findAll({
            limit: 0,
          });
        }
      }),
      this.gridService.catchErrorContinueWithManyEntity(),
      map((res) => res.data),
      this.gridService.finalizeLoad(),
    );
  }
}
