<div>
  <nz-select
    nzMode="multiple"
    [nzPlaceHolder]="'page.qualifications.category' | translate"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
  >
    <nz-option
      *ngFor="let item of options$ | async"
      [nzLabel]="item.name"
      [nzValue]="item.value"
    ></nz-option>
  </nz-select>
</div>
