import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Profession } from '@wilson/interfaces';
import { ProfessionHelperService } from '@wilson/profession/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterKey } from '../../filter.keys.type';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-profession-filter',
  templateUrl: './profession-filter.component.html',
  styleUrls: ['./profession-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() labelVisible = true;
  @Input() placeholder!: string;
  @Input()
  set filterKey(value: FilterKey) {
    this.filterKeySubject.next(value);
  }

  private filterKeySubject!: BehaviorSubject<FilterKey>;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    public professionHelperService: ProfessionHelperService,
  ) {
    const subject = new BehaviorSubject<FilterKey>('profession');
    super(subject.asObservable());
    this.filterKeySubject = subject;
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.professionHelperService.getProfessions().pipe(
      map((professions: Profession[]) => {
        return professions.map((profession) => {
          return {
            id: profession.id as string,
            name: `profession.${profession.id}`,
          };
        });
      }),
    );
  }
}
