import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';
import { FilterOptions } from '../../options.interface';
import { map } from 'rxjs/operators';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { Observable } from 'rxjs';
import { QualificationCategoryV2Service } from '@wilson/api/gateway';

@Component({
  selector: 'wilson-qualification-v2-category-filter',
  templateUrl: './qualification-v2-category-filter.component.html',
  styleUrls: ['./qualification-v2-category-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzSelectModule, TranslateModule, CommonModule, FormsModule],
  providers: [TransformMasterDataTranslationService],
})
export class QualificationV2CategoryFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly qualificationv2CategoryService: QualificationCategoryV2Service,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {
    super('qualificationV2Category');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.qualificationv2CategoryService.getCategories().pipe(
      map((categories) => {
        const transformedCategories = this.transformMasterDataTranslationService
          .transform(categories)
          .map((category) => ({
            id: category.id,
            name: category.translatedName,
            value: category.id,
          }));
        return transformedCategories;
      }),
    );
  }
}
