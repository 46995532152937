import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-rule-label',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './rule-label.component.html',
  styleUrl: './rule-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleLabelComponent {
  @Input() customLabel!: string;
}
