@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async); @let
hasWorkTimeManagementUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.WorkTimeManagement | async);
<ng-container
  *ngIf="{
    isDisabled:
      (!!shiftId && !canShiftBeTimeAdjusted) ||
      !(hasShiftUpdatePermissions && hasWorkTimeManagementUpdatePermissions)
  } as data"
>
  <li
    nz-menu-item
    nz-tooltip
    [nzTooltipTitle]="
      !(hasShiftUpdatePermissions && hasWorkTimeManagementUpdatePermissions)
        ? ('general.forbidden_action' | translate)
        : ''
    "
    [nzDisabled]="data.isDisabled"
    data-test-id="wilson-fill-with-planned-time-action:button"
    (click)="fillShiftWithPlannedTime(data.isDisabled)"
  >
    {{ 'page.work_time_management.fill_with_planned_times' | translate }}
  </li>
</ng-container>
