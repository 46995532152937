<ng-container *ngIf="rule">
  <wilson-shift-categories
    [shiftCategories]="rule.params.shiftCategories"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.shift_categories.description"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.shift_categories.label"
  ></wilson-shift-categories>
  <wilson-activity-categories
    [activityCategories]="rule.params.activityCategories"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.activity_categories.description"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.activity_categories.label"
  ></wilson-activity-categories>
  <wilson-price-per-unit
    (updatePricePerUnit)="setPricePerUnit($event)"
    [pricePerUnit]="rule.params.pricePerUnit"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.price_per_unit.description"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.price_per_unit.label"
  ></wilson-price-per-unit>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.countAfterMinutes"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.count_after_minutes.label"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.count_after_minutes.description"
  ></wilson-round-up-after>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.roundUpAfterMinutes"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.round_up_after_minutes.label"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.round_up_after_minutes.description"
  ></wilson-round-up-after>
  <wilson-round-up-after
    [roundUpAfter]="rule.params.roundUpAfter"
    customLabel="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.round_up_after.label"
    customDescription="agreement.rules.ActivitBonusByHourShiftAndActivityCategory.round_up_after.description"
  ></wilson-round-up-after>
</ng-container>
