import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { BillingRuleActivityByCategoryFlatRate } from '../../interfaces/activity-by-category-flat-rate';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ActivityCategoriesComponent } from '../partials/activity-categories/activity-categories.component';
import {
  PricePerUnitComponent,
  UpdatePricePerUnit,
} from '../partials/price-per-unit/price-per-unit.component';

@Component({
  selector: 'wilson-activity-by-category-flat-rate',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzTagModule,
    NzTagModule,
    NzInputModule,
    ActivityCategoriesComponent,
    PricePerUnitComponent,
  ],
  templateUrl: './activity-by-category-flat-rate.component.html',
  styleUrl: './activity-by-category-flat-rate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityByCategoryFlatRateComponent {
  @Input() rule: BillingRuleActivityByCategoryFlatRate;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleActivityByCategoryFlatRate;
    index: number;
  }>();

  setPricePerUnit(update: UpdatePricePerUnit) {
    this.rule.params.pricePerUnit = update.pricePerUnit;
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }
}
