import { BillingRule } from './billing-rules';

export interface BillingRuleShiftMinimumRate extends BillingRule {
  calculatorName: 'ShiftMinimumRate';
  params: {
    pricePerUnit: number;
    fillUpTo: number;
    activityCategoryOptions: BillingRuleShiftMinimumRateActivityCategoryOption[];
  };
}

export interface BillingRuleShiftMinimumRateActivityCategoryOption {
  activityCategories: string[];
  roundUpAfter: number;
  minimumAmount: number;
}

export function isShiftMinimumRate(
  obj: BillingRule,
): obj is BillingRuleShiftMinimumRate {
  return obj.calculatorName === 'ShiftMinimumRate';
}
