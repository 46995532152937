import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class FirstAppLaunchService {
  private readonly HAS_LAUNCHED_KEY = 'hasLaunchedAppBefore';

  constructor(private storage: Storage) {}

  public async isFirstLaunch(): Promise<boolean> {
    const hasLaunched = await this.storage.get(this.HAS_LAUNCHED_KEY);
    if (hasLaunched === null) {
      return true;
    }
    return false;
  }

  public async setHasLaunched(): Promise<void> {
    await this.storage.set(this.HAS_LAUNCHED_KEY, true);
  }
}
