import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { BillingRuleActivityBonusByHourShiftAndActivityCategory } from '../../interfaces/activity-bonus-by-hour-shift-and-activity-category';
import { ActivityCategoriesComponent } from '../partials/activity-categories/activity-categories.component';
import { ShiftCategoriesComponent } from '../partials/shift-categories/shift-categories.component';
import {
  PricePerUnitComponent,
  UpdatePricePerUnit,
} from '../partials/price-per-unit/price-per-unit.component';
import { RoundUpAfterComponent } from '../partials/round-up-after/round-up-after.component';

@Component({
  selector: 'wilson-activity-bonus-by-hour-shift-and-activity-category',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzTagModule,
    NzInputModule,
    ActivityCategoriesComponent,
    ShiftCategoriesComponent,
    PricePerUnitComponent,
    RoundUpAfterComponent,
  ],
  templateUrl:
    './activity-bonus-by-hour-shift-and-activity-category.component.html',
  styleUrl:
    './activity-bonus-by-hour-shift-and-activity-category.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBonusByHourShiftAndActivityCategoryComponent {
  @Input() rule: BillingRuleActivityBonusByHourShiftAndActivityCategory;
  @Input() index: number;
  @Output() updateRule = new EventEmitter<{
    rule: BillingRuleActivityBonusByHourShiftAndActivityCategory;
    index: number;
  }>();

  setPricePerUnit(update: UpdatePricePerUnit) {
    this.rule.params.pricePerUnit = update.pricePerUnit;
    this.updateRule.emit({
      rule: this.rule,
      index: this.index,
    });
  }
}
