<wilson-filter-label
  [label]="labelVisible && 'page.service.profession' | translate"
>
  <div>
    <nz-select
      nzMode="multiple"
      [nzPlaceHolder]="placeholder || ('page.service.profession' | translate)"
      [nzMaxTagCount]="1"
      nzAllowClear
      [nzShowArrow]="true"
      (ngModelChange)="saveSelected($event)"
      [ngModel]="selected$ | async"
      data-test-id="profession-filter:select"
    >
      <nz-option
        *ngFor="let profession of options$ | async"
        [nzLabel]="profession.name | translate"
        [nzValue]="profession.id"
        nzCustomContent
        [attr.data-test-id]="'profession-filter:select:option:' + profession.id"
      >
        <span
          nz-tooltip
          [nzTooltipTitle]="profession.tooltip"
          [nzTooltipColor]="'#fff'"
        >
          {{ profession.name | translate }}
        </span>
      </nz-option>
    </nz-select>
  </div>
</wilson-filter-label>
