import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QualificationCategoryV2 } from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';

@Pipe({
  name: 'qualificationV2CategoryNameSync',
  standalone: true,
})
export class QualificationV2CategoryNameSyncPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  transform(
    category: QualificationCategoryV2,
    emptyStringOnError = false,
  ): string {
    if (!category || !category.id) {
      return this.handleError(emptyStringOnError);
    }

    let categoryName = '';
    try {
      categoryName = this.transformMasterDataTranslationService.transform([
        category,
      ])[0].translatedName;
    } catch {
      categoryName = this.handleError(emptyStringOnError);
    }

    return categoryName;
  }

  private handleError(emptyStringOnError: boolean): string {
    return emptyStringOnError
      ? ''
      : this.translateService.instant('general.n/a');
  }
}
