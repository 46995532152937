import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AccountAgGridModule } from '@wilson/account/ag-grid';
import { AgGridRenderersModule } from '@wilson/ag-grid/renderers';
import { AgGridServiceModule } from '@wilson/ag-grid/service';
import { AuthorizationModule } from '@wilson/authorization';
import { ClientsServicesModule } from '@wilson/clients/services';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { FilterModule } from '@wilson/filter';
import { FormsModule as WilsonFormsModule } from '@wilson/forms';
import { PageFrameModule } from '@wilson/page-frame';
import { PipesModule } from '@wilson/pipes';
import { ProfessionCoreModule } from '@wilson/profession/core';
import {
  EmptyStateModule,
  LoadingStateModule,
} from '@wilson/shared-components';
import { TooltipModule } from '@wilson/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { AgreementsFormComponent } from './agreements-form/agreements-form.component';
import { AgreementsGridComponent } from './agreements-grid/agreements-grid.component';
import { AgreementsModalComponent } from './agreements-modal/agreements-modal.component';
import { ApplyToOtherAgreementsModalComponent } from './apply-to-other-agreements-modal/apply-to-other-agreements-modal.component';
import { ChangeExternalUserNoteModalComponent } from './change-note-modal/change-note-modal.component';
import { ClientAgreementsService } from './client-agreements.service';
import { ClientsCoreRoutingModule } from './clients-core-routing.module';
import { ClientsDetailComponent } from './clients-detail/clients-detail.component';
import { ClientsFormComponent } from './clients-form/clients-form.component';
import { ClientsOverviewComponent } from './clients-overview/clients-overview.component';
import { CopyAgreementsModalComponent } from './copy-agreements-modal/copy-agreements-modal.component';
import { ExternalCompanyUsersListService } from './external-company-users/external-company-users-service/external-company-users.service';
import { ExternalCompanyUsersComponent } from './external-company-users/external-company-users.component';
import { SetApprovalRendererComponent } from './external-company-users/renderers/set-approval-renderer.component';
import { SetProfessionsRendererComponent } from './external-company-users/renderers/set-professions-renderer.component';
import { UserNoteRendererRendererComponent } from './external-company-users/renderers/user-note-renderer.component';
import { NewClientsComponent } from './new-clients/new-clients.component';
import { ProviderShareUserListService } from './provider-share-users-list/provider-share-user-list-service/provider-share-user-list-service';
import { ProviderShareUsersListComponent } from './provider-share-users-list/provider-share-users-list.component';
import { SetShareRendererComponent } from './provider-share-users-list/set-share-renderer/set-share-renderer.component';
import { UserApprovalStatusRendererComponent } from './provider-share-users-list/user-approval-status-renderer/user-approval-status.renderer.component';
import { UserApprovedAtRendererComponent } from './provider-share-users-list/user-approved-at-renderer/user-approved-at-renderer.component';
import { UserSharedAtRendererComponent } from './provider-share-users-list/user-shared-at-renderer/user-shared-at-renderer.component';
import { ViewAssignedProfessionsRendererComponent } from './provider-share-users-list/view-profession-renderer/view-profession-renderer.component';
import { PartnerRendererComponent } from './renderers/partner-renderer/partner-renderer.component';
import { DisplaySharedUserApprovalComponent } from './shared-user-approval/display-shared-user-approval/display-shared-user-approval.component';
import { SetSharedUserApprovalComponent } from './shared-user-approval/set-shared-user-approval/set-shared-user-approval.component';
import { SetSharedUserProfessionsComponent } from './shared-user-professions/set-shared-user-professions/set-shared-user-professions.component';
import { ViewSharedUserProfessionsComponent } from './shared-user-professions/view-shared-user-professions/view-shared-user-professions.component';
import { UserNoteComponent } from './user-note/user-note.component';
import { GenericRuleComponent } from './rules/components/generic-rule/generic-rule.component';
import { ClientAddressComponent } from './clients-form/client-address/client-address.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [
    AgreementsFormComponent,
    AgreementsGridComponent,
    AgreementsModalComponent,
    ClientsDetailComponent,
    ClientsFormComponent,
    ClientsOverviewComponent,
    NewClientsComponent,
    ViewAssignedProfessionsRendererComponent,
    SetShareRendererComponent,
    UserSharedAtRendererComponent,
    UserApprovalStatusRendererComponent,
    UserApprovedAtRendererComponent,
    PartnerRendererComponent,
    UserNoteComponent,
    UserNoteRendererRendererComponent,
    ChangeExternalUserNoteModalComponent,
    ExternalCompanyUsersComponent,
    ProviderShareUsersListComponent,
    SetApprovalRendererComponent,
    SetSharedUserApprovalComponent,
    DisplaySharedUserApprovalComponent,
    SetProfessionsRendererComponent,
    SetSharedUserProfessionsComponent,
    ViewSharedUserProfessionsComponent,
    ApplyToOtherAgreementsModalComponent,
    CopyAgreementsModalComponent,
  ],
  providers: [
    ProviderShareUserListService,
    ExternalCompanyUsersListService,
    ClientAgreementsService,
  ],
  imports: [
    AccountAgGridModule,
    AgGridModule,
    AgGridRenderersModule,
    AgGridServiceModule,
    WilsonFormsModule,
    ReactiveFormsModule,
    FeatureFlagsModule,
    ClientsCoreRoutingModule,
    ClientsServicesModule,
    CommonModule,
    FeatureFlagsModule,
    FilterModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzModalModule,
    NzSelectModule,
    NzToolTipModule,
    PageFrameModule,
    PipesModule,
    TranslateModule,
    TooltipModule,
    AuthorizationModule,
    NzSwitchModule,
    DateFnsModule,
    FormsModule,
    FontAwesomeModule,
    ProfessionCoreModule,
    EmptyStateModule,
    LoadingStateModule,
    NzTabsModule,
    GenericRuleComponent,
    ClientAddressComponent,
    NzUploadModule,
  ],
})
export class ClientsCoreModule {}
