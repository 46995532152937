<ng-container *ngIf="rule">
  <wilson-time-bonus-calculation-method
    [timeBonusCalculationMethod]="rule.params.timeBonusCalculationMethod"
    customDescription="agreement.rules.TimeFrameBonus.time_bonus_calculation_method.description"
    customLabel="agreement.rules.TimeFrameBonus.time_bonus_calculation_method.label"
  ></wilson-time-bonus-calculation-method>
  <ng-container *ngIf="rule.params.bonuses?.length">
    <div class="bonuses">
      <wilson-rule-label
        customLabel="agreement.rules.TimeFrameBonus.bonuses.label"
      ></wilson-rule-label>
      <wilson-rule-description
        customDescription="agreement.rules.TimeFrameBonus.bonuses.description"
      ></wilson-rule-description>
      <div class="bonuses__entries">
        <ng-container *ngFor="let entry of rule.params.bonuses; let i = index">
          <div class="bonuses__entries__option">
            <wilson-rule-name
              [index]="i"
              [ruleName]="entry.ruleName"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.rule_name.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.rule_name.description"
              (updateRuleName)="setRuleName($event)"
            ></wilson-rule-name>
            <wilson-iso-weekdays
              [isoWeekdays]="entry.isoWeekdays"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.iso_weekdays.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.iso_weekdays.description"
            ></wilson-iso-weekdays>
            <wilson-on-holiday-region
              [onHolidaysRegion]="entry.onHolidaysRegion"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.on_holidays_region.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.on_holidays_region.description"
            ></wilson-on-holiday-region>
            <wilson-time-frame
              [timeFrame]="entry.timeFrame"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.time_frame.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.time_frame.description"
            ></wilson-time-frame>
            <wilson-round-up-after
              [roundUpAfter]="entry.roundUpAfter"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.round_up_after.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.round_up_after.description"
            ></wilson-round-up-after>
            <wilson-price-per-unit
              [index]="i"
              [pricePerUnit]="entry.pricePerUnit"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.price_per_unit.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.price_per_unit.description"
              (updatePricePerUnit)="setPricePerUnit($event)"
            ></wilson-price-per-unit>
            <wilson-activity-categories
              [activityCategories]="entry.notConsideredActivityCategories"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.not_considered_activity_categories.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.not_considered_activity_categories.description"
            ></wilson-activity-categories>
            <wilson-priority
              [priority]="entry.priority"
              customLabel="agreement.rules.TimeFrameBonus.bonuses.priority.label"
              customDescription="agreement.rules.TimeFrameBonus.bonuses.priority.description"
            ></wilson-priority>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
