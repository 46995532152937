import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Client } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { map, Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-agreement-filter',
  templateUrl: './agreement-filter.component.html',
  styleUrls: ['./agreement-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() placeholder!: string;
  @Input() showLabel = false;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly clientsService: ClientsV2Service,
  ) {
    super('agreements');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.clientsService
      .getMany({ relations: ['agreements'], limit: 0 })
      .pipe(
        map((clients) => {
          const result = [];
          clients.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((client: Client) => {
              if (client.agreements && client.agreements.length) {
                result.push({
                  title: client.name,
                  value: client.id,
                  key: client.id,
                  children:
                    client.agreements &&
                    client.agreements
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((agreement) => {
                        return {
                          title: agreement.name,
                          value: agreement.id,
                          key: agreement.id,
                          isLeaf: true,
                        };
                      }),
                });
              }
            });
          return result;
        }),
      );
  }

  public saveSelectedAgreement(treeNodes: NzTreeNodeOptions[]): void {
    const result = [];
    treeNodes.forEach((node) => {
      node.children.forEach((c) => {
        if (c.checked) {
          result.push(c.key);
        }
      });
    });
    this.saveSelected(result);
  }
}
