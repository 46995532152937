import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QualificationV2 } from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';

@Pipe({
  name: 'qualificationV2NameSync',
  standalone: true,
})
export class QualificationNameSyncPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  transform(
    qualification: Pick<
      QualificationV2,
      'nameDe' | 'nameEn' | 'nameFr' | 'namePl'
    >,
    emptyStringOnError = false,
  ): string {
    if (
      !qualification ||
      (!qualification.nameDe &&
        !qualification.nameEn &&
        !qualification.nameFr &&
        !qualification.namePl)
    ) {
      return this.handleError(emptyStringOnError);
    }

    let qualificationName = '';
    try {
      qualificationName = this.transformMasterDataTranslationService.transform([
        qualification,
      ])[0].translatedName;
    } catch {
      qualificationName = this.handleError(emptyStringOnError);
    }

    return qualificationName;
  }

  private handleError(emptyStringOnError: boolean): string {
    {
      if (emptyStringOnError) {
        return '';
      } else {
        return this.translateService.instant('general.n/a');
      }
    }
  }
}
