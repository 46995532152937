import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ControlsOf } from '@ngneat/reactive-forms';
import { PipesModule } from '@wilson/pipes';
import { ClientAddressForm } from '@wilson/clients/interfaces';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthorizationModule } from '@wilson/authorization';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-client-address',
  templateUrl: './client-address.component.html',
  styleUrls: ['./client-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    PipesModule,
    NzAlertModule,
    NzToolTipModule,
    AuthorizationModule,
  ],
  standalone: true,
})
export class ClientAddressComponent {
  @Input() form!: FormGroup<ControlsOf<ClientAddressForm>>;
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
}
