import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsencesService } from '@wilson/absences';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-absences-filter',
  templateUrl: './absences-filter.component.html',
  styleUrls: ['./absences-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsencesFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() placeholder!: string;

  constructor(
    private readonly absencesService: AbsencesService,
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
  ) {
    super('absences');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.absencesService
      .getAbsenceCategories()
      .pipe(
        map((absenceCategories) =>
          absenceCategories.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      ) as Observable<FilterOptions[]>;
  }
}
