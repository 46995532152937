import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { PartnershipGateway } from '@wilson/api/gateway';
import { BankDetailsState } from '@wilson/bank-details';
import { Client } from '@wilson/clients/interfaces';
import { ClientsV2Service, UpdateClientDto } from '@wilson/clients/services';
import {
  BankDetails,
  FeatureName,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { Partner } from '@wilson/share/interfaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  Observable,
  ReplaySubject,
  catchError,
  firstValueFrom,
  map,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ClientsFormService } from '../clients-form.service';
import { AnyAbility } from '@casl/ability';
import { AblePurePipe } from '@casl/angular';

@Component({
  selector: 'wilson-clients-form',
  templateUrl: './clients-form.component.html',
  styleUrls: ['./clients-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsFormComponent implements OnInit, OnDestroy {
  public partners$: Observable<Partner[]>;
  clientId: string | null;
  selectedPartner: Partner[] = [];
  freePartner: Partner[] = [];
  private destroyed$ = new ReplaySubject<void>();
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;
  faTrash = faTrash;
  bankDetails$: Observable<BankDetails[]> = of([]);
  invoiceLogo: string | ArrayBuffer | null = null;

  @Input() public client: Client | null = null;

  constructor(
    public readonly clientsFormService: ClientsFormService,
    private readonly partnershipGateway: PartnershipGateway,
    private readonly route: ActivatedRoute,
    private readonly ablePurePipe: AblePurePipe<AnyAbility>,
    protected readonly clientsV2Service: ClientsV2Service,
    protected readonly store: Store,
    protected readonly cdr: ChangeDetectorRef,
    private readonly nzMessageService: NzMessageService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.ablePurePipe
      .transform(RoleAction.Update, RolePermissionSubject.Client)
      .pipe(
        take(1),
        tap((canUpdate) => {
          if (!canUpdate) {
            this.clientsFormService.form.disable();
          } else {
            this.clientsFormService.form.enable();
          }
        }),
      )
      .subscribe();

    this.bankDetails$ = this.store.select(BankDetailsState.bankDetails);

    this.partners$ = this.route.paramMap.pipe(
      map((params) => params.get('clientId')),
      switchMap((clientId) => {
        this.clientId = clientId;
        this.selectedPartner = [];
        this.freePartner = [];
        return this.partnershipGateway
          .getAll({ relations: ['clientPartnerships'] })
          .pipe(
            map((partners: Partner[]) => this.filterPartners(partners)),
            catchError(() => {
              return of([]);
            }),
            startWith([]),
          );
      }),
    );

    this.clientsFormService.form.controls['canEditActivity']?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((canEditActivity) => {
        if (canEditActivity) {
          this.clientsFormService.form.controls['canSplitActivity']?.setDisable(
            false,
          );
        } else {
          this.clientsFormService.form.controls['canSplitActivity']?.setDisable(
            true,
          );
          this.clientsFormService.form.controls['canSplitActivity']?.setValue(
            false,
          );
        }
      });
  }

  beforeUpload = (file: File): Observable<boolean> =>
    new Observable(() => {
      this.setInvoiceLogo(file);
    });

  async setInvoiceLogo(file: File): Promise<void> {
    try {
      await this.clientsV2Service.addInvoiceLogo(file, this.clientId as string);

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        const base64String = e.target?.result as string;
        this.clientsFormService.form.patchValue({
          invoiceLogo: base64String,
        });
        this.cdr.markForCheck();
      };
      reader.readAsDataURL(file);
    } catch {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
  }

  async removeInvoiceLogo(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    try {
      await firstValueFrom(
        this.clientsV2Service.update({
          id: this.clientId as string,
          invoiceLogo: null,
        } as UpdateClientDto),
      );

      this.clientsFormService.form.patchValue({
        invoiceLogo: null,
      });
      this.cdr.markForCheck();
    } catch {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
  }

  filterPartners(partners: Partner[]): Partner[] {
    partners.forEach((partner) => {
      if (
        partner.clientPartnerships &&
        partner.clientPartnerships[0]?.clientId === this.clientId
      ) {
        this.selectedPartner.push(partner);
      } else if (
        !partner.clientPartnerships ||
        !partner.clientPartnerships.length
      ) {
        this.freePartner.push(partner);
      }
    });
    const filteredPartners = [...this.selectedPartner, ...this.freePartner];
    return filteredPartners;
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }
}
