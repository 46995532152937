import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedUserGateway, UserSharedByMe } from '@wilson/clients/services';
import { ICellRendererParams } from 'ag-grid-community';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class ProviderShareUserListService {
  private _partnerShipIdSubject = new BehaviorSubject<string>('');
  private _updateSuccessSubject = new ReplaySubject<{
    // eslint-disable-next-line
    result: any;
    cellRendererParams: ICellRendererParams;
    success: boolean;
  }>(1);

  get updateSuccess$(): Observable<{
    result: UserSharedByMe;
    cellRendererParams: ICellRendererParams;
    success: boolean;
  }> {
    return this._updateSuccessSubject.asObservable();
  }

  getPartnerShipId$(): Observable<string> {
    return this._partnerShipIdSubject.asObservable();
  }

  setPartnerShipId(partnerShipId: string): void {
    this._partnerShipIdSubject.next(partnerShipId);
  }

  constructor(
    private readonly sharedUserGateway: SharedUserGateway,
    private readonly nzModelService: NzModalService,
    private readonly translateService: TranslateService,
  ) {}

  async createSharedUser(
    user: UserSharedByMe,
    cellRendererParams: ICellRendererParams,
  ) {
    try {
      const result = await this.sharedUserGateway.add({
        userId: user.id as string,
        partnershipId: this._partnerShipIdSubject.getValue(),
      });
      this._updateSuccessSubject.next({
        result: {
          ...user,
          sharedUsers: [result],
        },
        cellRendererParams,
        success: true,
      });
    } catch (error) {
      this._updateSuccessSubject.next({
        result: user,
        cellRendererParams,
        success: false,
      });
    }
  }

  async deleteSharedUser(
    user: UserSharedByMe,
    cellRendererParams: ICellRendererParams,
  ) {
    this.openDeleteSharedUserModel(user, cellRendererParams);
  }

  private async openDeleteSharedUserModel(
    user: UserSharedByMe,
    cellRendererParams: ICellRendererParams,
  ) {
    const modalRef: NzModalRef = this.nzModelService.create({
      nzTitle: this.translateService.instant(
        'client.shared_by_me.share_toggle.delete_modal.header',
      ),
      nzContent: this.translateService.instant(
        'client.shared_by_me.share_toggle.delete_modal.body',
      ),
      nzClosable: false,
      nzOnCancel: () => {
        this._updateSuccessSubject.next({
          result: user,
          cellRendererParams,
          success: true,
        });
        modalRef.destroy();
      },
      nzFooter: [
        {
          label: this.translateService.instant('general.cancel'),
          type: 'default',
          onClick: () => {
            this._updateSuccessSubject.next({
              result: user,
              cellRendererParams,
              success: true,
            });
            modalRef.destroy();
          },
        },
        {
          label: this.translateService.instant(
            'client.shared_by_me.share_toggle.delete_modal.action.confirm',
          ),
          type: 'primary',
          onClick: async () => {
            await this.trySharedUserDeletion(user, cellRendererParams);
            modalRef.destroy();
          },
        },
      ],
    });
  }

  private async trySharedUserDeletion(
    user: UserSharedByMe,
    cellRendererParams: ICellRendererParams,
  ) {
    try {
      await this.sharedUserGateway.remove(user.sharedUsers[0].id as string);
      this._updateSuccessSubject.next({
        result: {
          ...user,
          sharedUsers: [],
        },
        cellRendererParams,
        success: true,
      });
    } catch (error) {
      this._updateSuccessSubject.next({
        result: user,
        cellRendererParams,
        success: false,
      });
    }
  }
}
