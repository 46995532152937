import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { QualificationV2, UserQualificationV2 } from '@wilson/interfaces';
import { of } from 'rxjs';
import { QualificationsV2State } from '../states/qualifications-v2/qualifications-v2.state';

@Pipe({
  name: 'qualificationById',
  standalone: true,
})
export class QualificationByIdPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly store: Store,
  ) {}

  transform(
    payload:
      | Pick<UserQualificationV2, 'qualificationId'>
      | Pick<QualificationV2, 'id'>
      | string,
    emptyStringOnError = false,
  ): unknown {
    let qualificationId: string;

    if (!payload) {
      return of(this.handleError(emptyStringOnError));
    } else if (typeof payload === 'string') {
      qualificationId = payload;
    } else if (typeof payload === 'object' && 'qualificationId' in payload) {
      qualificationId = payload.qualificationId;
    } else if (typeof payload === 'object' && 'id' in payload) {
      qualificationId = payload.id;
    } else {
      return of(this.handleError(emptyStringOnError));
    }

    return this.store.select(
      QualificationsV2State.qualificationById(qualificationId),
    );
  }

  private handleError(emptyStringOnError: boolean): string {
    return emptyStringOnError
      ? ''
      : this.translateService.instant('general.n/a');
  }
}
