<wilson-rule-label [customLabel]="customLabel"></wilson-rule-label>
<wilson-rule-description
  [customDescription]="customDescription"
></wilson-rule-description>
<nz-input-group [nzAddOnAfter]="currencySymbol">
  <input
    nz-input
    class="price_per_hour__input"
    [value]="pricePerHour"
    (change)="setPricePerHour($event)"
    type="number"
  />
</nz-input-group>
