import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridService } from '@wilson/ag-grid/service';
import { Agreement, Client } from '@wilson/clients/interfaces';
import { ClientsV2Service } from '@wilson/clients/services';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  FeatureName,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { ComponentCanDeactivate } from '@wilson/shared-components';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ClientsFormService } from '../clients-form.service';

@Component({
  selector: 'wilson-clients-detail',
  templateUrl: './clients-detail.component.html',
  styleUrls: ['./clients-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ClientsFormService],
})
export class ClientsDetailComponent implements OnInit, ComponentCanDeactivate {
  public searchString: string;
  public saving = false;
  client$: Observable<Client>;
  private userWorkApprovalFeatureFlagEnabled: boolean;
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    public readonly clientsFormService: ClientsFormService,
    private readonly route: ActivatedRoute,
    public readonly gridService: GridService<Agreement>,
    public readonly clientService: ClientsV2Service,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userWorkApprovalFeatureFlagEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-user-work-approval-and-sharing-of-qualifications-wilp-4769',
      ),
    );
    if (this.userWorkApprovalFeatureFlagEnabled) {
      this.clientsFormService.initialize(
        this.route.snapshot.params['clientId'],
        {
          relations: ['clientPartnership', 'address'],
        },
      );
    } else {
      this.clientsFormService.initialize(
        this.route.snapshot.params['clientId'],
      );
    }

    this.clientsFormService.form.addControl('id', new FormControl());

    if (this.userWorkApprovalFeatureFlagEnabled) {
      this.client$ = this.clientService.get(
        this.route.snapshot.params['clientId'],
        {
          relations: ['clientPartnership', 'address'],
        },
      );
    }
  }

  deleteClient(): void {
    this.clientsFormService.confirmDeleteClient(
      this.clientsFormService.form.value,
    );
  }

  async save(): Promise<void> {
    this.saving = true;
    await this.clientsFormService.submitForm();
    if (this.userWorkApprovalFeatureFlagEnabled) {
      this.clientsFormService.initialize(
        this.route.snapshot.params['clientId'],
        {
          relations: ['clientPartnership', 'address'],
        },
      );
    } else {
      this.clientsFormService.initialize(
        this.route.snapshot.params['clientId'],
      );
    }

    this.clientsFormService.form.markAsPristine();
    this.saving = false;
  }

  public canDeactivate(): Observable<boolean> {
    return of(this.clientsFormService.form.dirty);
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event: {
    preventDefault: () => void;
    returnValue: boolean;
  }): void {
    if (this.clientsFormService.form.dirty) {
      event.preventDefault();
      event.returnValue = false;
    }
  }
}
