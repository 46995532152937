import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QualificationCategoryV2Service } from '@wilson/api/gateway';
import { QualificationCategoryV2 } from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { map, Observable, tap } from 'rxjs';

@Component({
  selector: 'wilson-qualification-category-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    TranslateModule,
  ],
  templateUrl: './qualification-category-select.component.html',
  styleUrl: './qualification-category-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualificationCategorySelectComponent),
      multi: true,
    },
  ],
})
export class QualificationCategorySelectComponent
  implements OnInit, ControlValueAccessor
{
  protected categoryOptions$!: Observable<
    (QualificationCategoryV2 & {
      translatedName: string;
    })[]
  >;
  protected isDisabled = false;
  protected selectedCategoryIds: string[] = [];
  protected loading = false;

  @Input()
  hideLabel = false;
  @Input()
  label!: string;
  @Input()
  placeholder!: string;
  @Input()
  isRequired!: boolean;

  @Input()
  showMultiple = false;

  @Output()
  value = new EventEmitter();

  constructor(
    private readonly qualificationCategoryService: QualificationCategoryV2Service,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.categoryOptions$ = this.qualificationCategoryService
      .getCategories()
      .pipe(
        map((categories) =>
          this.transformMasterDataTranslationService.transform(categories),
        ),
        tap(() => {
          this.loading = false;
          this.cdr.detectChanges();
        }),
      );
  }

  protected updateSelectedCategory(ids: string[]): void {
    this.selectedCategoryIds = ids;
    this.propagateChange(ids);
    this.value.emit();
  }

  writeValue(value: string[]): void {
    this.selectedCategoryIds = value || [];
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line
  propagateChange: (value: string[]) => void = () => {};

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.detectChanges();
  }
}
