@let hasSharedUserUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.SharedUser | async);

<ng-template #currentApprovalStatusLabel>
  <wilson-display-shared-user-approval
    [status]="approvalStatus"
  ></wilson-display-shared-user-approval>
</ng-template>

<nz-select
  [(ngModel)]="approvalStatus"
  (ngModelChange)="approvalChanged($event)"
  [nzCustomTemplate]="currentApprovalStatusLabel"
  [nzDisabled]="!hasSharedUserUpdatePermission"
  nz-tooltip
  [nzTooltipTitle]="
    !hasSharedUserUpdatePermission
      ? ('general.forbidden_action' | translate)
      : ''
  "
>
  <nz-option
    *ngFor="let approvalStatusEnum of sharedUserApprovalStatuses"
    [nzValue]="approvalStatusEnum"
    nzCustomContent
  >
    <wilson-display-shared-user-approval
      [status]="approvalStatusEnum"
    ></wilson-display-shared-user-approval>
  </nz-option>
</nz-select>
