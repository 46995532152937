<div>
  <nz-select
    nzMode="multiple"
    [nzMaxTagCount]="1"
    nzAllowClear
    [nzShowArrow]="true"
    (ngModelChange)="saveSelected($event)"
    [ngModel]="selected$ | async"
    [nzPlaceHolder]="'page.shift.project' | translate"
    data-test-id="projects-filter:select"
  >
    <nz-option
      *ngIf="!hideNoProjectOption"
      [nzLabel]="'page.service.no_project' | translate"
      [nzValue]="'no-project'"
      data-test-id="'projects-filter:select:option:no-project'"
    >
      <span nz-tooltip [nzTooltipColor]="'#fff'">
        {{ 'page.service.no_project' | translate }}
      </span>
    </nz-option>
    <nz-option
      *ngFor="let project of options$ | async"
      [nzLabel]="project.projectId"
      [nzValue]="project.id"
      nzCustomContent
      [attr.data-test-id]="'projects-filter:select:option:' + project.id"
    >
      <div class="category-container">
        <div class="dot" [style.background-color]="project.colorCode"></div>
        <div class="label-name">
          {{ project | projectName }}
        </div>
      </div>
    </nz-option>
  </nz-select>
</div>
<wilson-filter-label
  *ngIf="showLabel"
  [label]="'page.shift.filter_labels' | translate"
>
</wilson-filter-label>
