import { PublicationStatus, ShiftState } from '@wilson/interfaces';

export function canShiftBeTimeAdjusted({
  shiftState,
  assignedUserId,
  shiftPublicationStatus,
}: {
  shiftState: ShiftState;
  assignedUserId: string;
  shiftPublicationStatus: PublicationStatus;
}) {
  return (
    shiftState === ShiftState.NotSubmitted &&
    !!assignedUserId &&
    shiftPublicationStatus === PublicationStatus.Published
  );
}
