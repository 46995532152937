import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup,
} from '@angular/forms';

export function conditionalAddressValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const addressGroup = control as FormGroup;
    const street = addressGroup.get('street')?.value;
    const city = addressGroup.get('city')?.value;
    const postCode = addressGroup.get('postCode')?.value;
    const country = addressGroup.get('country')?.value;
    const state = addressGroup.get('state')?.value;

    const anyFieldFilled = street || city || postCode || country || state;
    const allFieldsFilled = street && city && postCode && country && state;

    if (anyFieldFilled && !allFieldsFilled) {
      return { addressIncomplete: true };
    }
    return null;
  };
}
