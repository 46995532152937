import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RequestForProposalGateway } from '@wilson/api/gateway';
import { ClientProposalResponse, RequestForProposal } from '@wilson/interfaces';
import { map, Observable } from 'rxjs';
import { BaseSelectFilterDirective } from '../../base-select-filter.directive';
import { FilterService } from '../../filter.service';
import { FilterOptions } from '../../options.interface';
import { MultiSelectParserService } from '../../services/multi-select-parser.service';

@Component({
  selector: 'wilson-accepted-jobs-filter',
  templateUrl: './accepted-jobs-filter.component.html',
  styleUrls: ['./accepted-jobs-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptedJobsFilterComponent extends BaseSelectFilterDirective<
  FilterOptions,
  string[]
> {
  @Input() showLabel = true;

  constructor(
    protected readonly filterService: FilterService,
    protected readonly parserService: MultiSelectParserService,
    private readonly requestForProposalGateway: RequestForProposalGateway,
  ) {
    super('acceptedJobs');
  }

  protected initializeOptions(): Observable<FilterOptions[]> {
    return this.requestForProposalGateway
      .getRequestsForProposals({
        relations: ['job', 'job.organizationalUnit'],
        limit: 0,
      })
      .pipe(
        map((requestsForProposals: RequestForProposal[]) =>
          requestsForProposals
            .filter(
              (rfp) =>
                rfp.clientProposalResponse === ClientProposalResponse.Accepted,
            )
            .map((rfp) => ({ id: rfp.jobId, name: rfp.job?.name })),
        ),
      );
  }
}
