import { UserQualificationV2Attachment } from '@wilson/interfaces';

export class SetUserQualificationsV2AttachmentsLoading {
  static readonly type = '[UserQualificationsV2Attachments] set loading state';
  constructor(public readonly isLoading: boolean) {}
}

export class ResetUserQualificationsV2Attachments {
  static readonly type = '[UserQualificationsV2Attachments] Reset state';
}

export class SetUserQualificationsV2Attachments {
  static readonly type =
    '[UserQualificationsV2Attachments] Set state to provided attachments';
  constructor(
    public readonly setAttachments: UserQualificationV2Attachment[],
  ) {}
}

export class UpsertUserQualificationsV2Attachments {
  static readonly type =
    '[UserQualificationsV2Attachments] Upsert provided attachments';
  constructor(
    public readonly upsertAttachments: UserQualificationV2Attachment[],
  ) {}
}
